<script setup></script>

<template>
    <v-container style="max-width: 1100px;min-height: 90%;" class="mx-auto">
        <v-breadcrumbs :items="[
            { title: 'Início', to: $route('app-site.home') },
            'Pedidos',
        ]" class="mb-5"></v-breadcrumbs>

        <h1 class="text-h4">Pedidos realizados</h1>

        <x-pagination source="orders" #="{ items }" empty-text="Sem mais pedidos para mostrar">
            <v-banner v-if="items.length" v-for="(xItem, xItemIndex) in items" :key="xItemIndex" flat border
                rounded="xl" class="my-5 py-5" lines="one">
                <p class="ms-5">
                    <b>Total:</b>
                    {{ $locale.formatCurrency(xItem.invoiceTotalAmount) }}
                </p>
                <p class="ms-5">
                    <b>Data:</b> {{ $locale.formatDatetime(xItem.createdAt) }}
                </p>

                <template #actions>
                    <v-btn v-if="$vuetify.display.mobile" :to="$route('app-site.user.menu.orders.show', [
                        xItem.uuid,
                    ])
                        " color="primary" icon="mdi-open-in-new"></v-btn>
                    <v-btn v-else :to="$route('app-site.user.menu.orders.show', [
                        xItem.uuid,
                    ])
                        " color="primary" text="Detalhes do pedido" class="me-5"></v-btn>
                </template>
            </v-banner>
        </x-pagination>
    </v-container>
</template>
