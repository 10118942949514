
        import Enum from '../Helpers/Enum';

        export default class WalletTransactionType extends Enum {
                            static get DebitFromTransfer() {
                    return new class WalletTransactionType {
                        id = 'DebitFromTransfer';
                        value = 'debit_transfer';

                                            };
                }
                            static get ProfitFromOrder() {
                    return new class WalletTransactionType {
                        id = 'ProfitFromOrder';
                        value = 'profit_order';

                                            };
                }
                            static get DebitFromOrderRefund() {
                    return new class WalletTransactionType {
                        id = 'DebitFromOrderRefund';
                        value = 'debit_order_refund';

                                            };
                }
                    };
    