import { router, usePage } from "@inertiajs/vue3";
import { computed } from "vue";


export default {
    useLink: function (props) {
        const target = computed(() => props.to.value);

        const getUri = () => typeof target.value === 'string' ? target.value : target.value.action;
        const getMethod = () => typeof target.value === 'string' ? 'get' : target.value.method;
        const getData = () => typeof target.value === 'string' ? {} : target.value.data;
        const getOptions = () => typeof target.value === 'string' ? {} : target.value.options;

        const targetUrl = computed(() => {

            if (typeof props.to.value === 'string') {
                return props.to.value;
            }

            return props.to.value?.action;
        });

        return {
            route: computed(() => ({ href: getUri() })),
            isActive: computed(() => {

                let x = targetUrl.value.replace(location.origin, '');

                if (!x) {
                    x = '/';
                }

                return x === usePage().url;
            }),
            navigate: function (event) {

                const method = getMethod();
                const uri = getUri();

                if (event.shiftKey || event.metaKey || event.ctrlKey || uri.startsWith('#')) {
                    return;
                };

                event.preventDefault();

                // console.log({
                //     method,
                //     uri,
                //     data: getData(),
                //     options: getOptions()
                // });

                return ['get', 'GET'].includes(method) ? router.visit(uri) : router[method](uri, getData(), getOptions());
            }
        };
    },
};

// export default {
//     useLink: function (props) {
//         const getUri = () => typeof props.to === 'string' ? props.to : props.to.action;
//         const getMethod = () => typeof props.to === 'string' ? 'get' : props.to.method;
//         const getData = () => typeof props.to === 'string' ? {} : props.to.data;
//         const getOptions = () => typeof props.to === 'string' ? {} : props.to.options;

//         return {
//             route: computed(() => ({ href: getUri() })),
//             isExactActive: computed(() => usePage().url === getUri()),
//             isActive: computed(() => usePage().url === getUri()),
//             // isActive: computed(() => usePage().url.startsWith(getUri())),
//             navigate: function (event) {

//                 const method = getMethod();
//                 const uri = getUri();

//                 if (event.shiftKey || event.metaKey || event.ctrlKey || uri.startsWith('#')) {
//                     return;
//                 };

//                 event.preventDefault();

//                 // console.log({
//                 //     method,
//                 //     uri,
//                 //     data: getData(),
//                 //     options: getOptions()
//                 // });

//                 return ['get', 'GET'].includes(method) ? router.visit(uri) : router[method](uri, getData(), getOptions());
//             }
//         };
//     },
// };

// export default {
//     useLink: function (props) {

//         const href = props.to.value;
//         const currentUrl = computed(() => usePage().url);

//         return {
//             route: computed(() => ({ href })),
//             isActive: computed(() => currentUrl.value.startsWith(href)),
//             // isExactActive: computed(() => href === currentUrl.value),
//             navigate(e) {
//                 console.log(props.to.value);
//                 if (e.shiftKey || e.metaKey || e.ctrlKey || href === '#') {
//                     return;
//                 }

//                 e.preventDefault();

//                 router.visit(href);
//             },
//         }
//     },
// }
