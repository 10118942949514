<script setup>
    import { ref } from 'vue';

    defineProps({
        message: String,
    });

    const emits = defineEmits(['confirm', 'cancel']);

    const opened = ref(false);

    const confirm = () => {
        opened.value = false;
        emits('confirm', true);
    };

    const cancel = () => {
        opened.value = false;
        emits('cancel', true);
    };

</script>

<template>
    <v-dialog v-model="opened" width="auto" activator="parent">
        <template #activator="{ props }">
            <slot v-if="$attrs?.activator !== 'parent'" name="activator" :="{ props }">
                <v-btn :="props">Continuar</v-btn>
            </slot>
        </template>

        <template #default>
            <slot :="{ confirm, cancel }">
                <v-card>
                    <slot name="message">
                        <v-card-text v-html="$props?.message || 'Quer mesmo continuar?'">
                        </v-card-text>
                    </slot>
                    <v-divider></v-divider>
                    <v-card-actions class="justify-space-around">
                        <slot name="no" :="{ cancel }">
                            <v-btn color="error" @click="cancel">Não</v-btn>
                        </slot>
                        <slot name="yes" :="{ confirm }">
                            <v-btn color="success" @click="confirm">Sim</v-btn>
                        </slot>
                    </v-card-actions>
                </v-card>
            </slot>
        </template>
    </v-dialog>
</template>
