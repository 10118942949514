
import { router, usePage } from "@inertiajs/vue3";
import { ref, shallowRef, toValue } from "vue";

const useLoading = function (callback, onError) {
    const loading = shallowRef(false);

    const finishCallback = () => {
        if (loading.value === true) {
            return;
        }

        loading.value = false;
    };

    return {
        action: function () {

            loading.value = true;

            try {
                callback(finishCallback);
            }
            catch (error) {
                if (onError) {
                    onError(error, finishCallback);
                }
                else {
                    console.error(error);
                }

                finishCallback();
            }

        },
        loading
    };
};

const useInertiaPaginator = function ({
    propName,
    pageName,
}) {

    if (!propName) {
        throw 'useInertiaPaginator: prop name is required';
    }

    const nextPage = shallowRef(2);

    const items = ref(toValue(usePage().props?.[propName]?.data || []));

    const perPage = shallowRef(toValue(items.value.length));

    const end = shallowRef(toValue(function () {

        const meta = usePage().props?.[propName]?.meta || usePage().props?.[propName] || {};

        if (meta && meta.last_page === meta.current_page) {
            return true;
        }

        return false;
    }));

    const loadMore = function (callback) {

        if (end.value === true) {
            callback(true, []);
            return;
        }

        router.reload({
            only: [propName],
            headers: {
                ['x-pagination-' + (pageName || 'page')]: nextPage.value
            },
            onSuccess: function ({ props }) {

                const currentPaginator = props?.[propName];

                if (currentPaginator.data.length < perPage.value) {
                    end.value = true;
                }

                items.value.push(...currentPaginator.data);

                nextPage.value = nextPage.value + 1;

                callback(true, currentPaginator.data, ...arguments);
            },
            onError: function () {
                callback(false, ...arguments);
            },
        });
    };

    return {
        items,
        loadMore
    }
};

const useInfinitePaginator = function ({ propName, pageName }) {

    const paginator = useInertiaPaginator({ propName, pageName });

    const loadMore = async function ({ done }) {

        paginator.loadMore(function (success, newItems) {
            if (success === false) {
                done('error');
            }
            else {
                done(newItems.length === 0 ? 'empty' : 'ok');
            }
        });
    };

    return {
        items: paginator.items.value,
        loadMore
    };
}

export {
    useLoading,
    useInertiaPaginator,
    useInfinitePaginator,
};
