<script setup>

import { ref } from 'vue';

const showPassword = ref(false);

</script>

<template>
    <v-text-field @click:append-inner="showPassword = !showPassword" :type="showPassword ? 'text' : 'password'"
        :append-inner-icon="'mdi-eye'.concat(showPassword ? '-off' : '')" prepend-inner-icon="mdi-key">
        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
            <slot :name="slot" :="scope"></slot>
        </template>
    </v-text-field>
</template>
