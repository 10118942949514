<script setup>

    import { inject } from 'vue';
    import { useInfinitePaginator } from '../../../../../../../../resources/vue/Composables';
    import { router } from '@inertiajs/vue3';

    const props = defineProps({
        items: Object,
        listTag: String,
    });

    const route = inject('route');
    const notify = inject('notify');

    const { loadMore, items } = useInfinitePaginator({
        propName: 'items',
    });

    const removeItem = function ({ itemType, itemId }) {
        router.post(route('app-site.user.lists.item.toggle', [props.listTag, itemType, itemId]), {}, {
            onSuccess: function ({ props }) {
                notify().info('Sucesso! evento removido dos favoritos');
            },
        });
    };



</script>

<template>
    <v-container style="max-width: 1100px;min-height: 90%;" class="mx-auto">
        <v-breadcrumbs :items="[{ title: 'Início', to: $route('app-site.home') }, 'Favoritos',]"
            class="mb-5"></v-breadcrumbs>

        <h1 class="text-h4 mb-5">Items favoritados:</h1>

        <v-infinite-scroll @load="loadMore" empty-text="Fim da lista">
            <v-card v-for="(xItem, xItemIndex) in items" :key="xItemIndex" flat border class="mb-10">

                <template #prepend>
                    <v-img :src="xItem?.image || $imgPlaceholder" :aspect-ratio="1 / 1" min-width="100"
                        class="rounded"></v-img>
                </template>

                <template #item>
                    <div class="px-5">
                        <v-card-title class="text-h4">
                            {{ xItem.title }}
                        </v-card-title>
                        <v-card-subtitle>
                            {{ xItem.typeLabel }}
                        </v-card-subtitle>
                    </div>
                </template>

                <template #append>
                    <v-btn :to="xItem.link" color="primary" text="Ir para" prepend-icon="mdi-open-in-new" class="mx-1"
                        size="small"></v-btn>
                    <v-btn prepend-icon="mdi-heart-off" size="small" class="mx-1" text="remover"
                        v-tooltip="'Remover dos favoritos'" color="pink-accent-4"
                        @click="() => removeItem(xItem)"></v-btn>
                </template>
            </v-card>

        </v-infinite-scroll>
    </v-container>
</template>
