
        import Enum from '../Helpers/Enum';

        export default class WalletTransferStatus extends Enum {
                            static get Requested() {
                    return new class WalletTransferStatus {
                        id = 'Requested';
                        value = 'requested';

                                                                        description = 'Repasse solicitado';
                                                                        numeric = 1;
                                                                        name = 'solicitado';
                                                                        mdIcon = 'mdi-clock';
                                                                        mdColor = 'warning';
                                            };
                }
                            static get Accepted() {
                    return new class WalletTransferStatus {
                        id = 'Accepted';
                        value = 'accepted';

                                                                        description = 'Repasse aprovado, inicio da transfer\u00eancia pendente';
                                                                        numeric = 2;
                                                                        name = 'aceito';
                                                                        mdIcon = 'mdi-check';
                                                                        mdColor = 'info';
                                            };
                }
                            static get Processing() {
                    return new class WalletTransferStatus {
                        id = 'Processing';
                        value = 'processing';

                                                                        description = 'A transfer\u00eancia foi iniciada e est\u00e1 processamento';
                                                                        numeric = 3;
                                                                        name = 'em processamento';
                                                                        mdIcon = 'mdi-cash-fast';
                                                                        mdColor = 'info';
                                            };
                }
                            static get Processed() {
                    return new class WalletTransferStatus {
                        id = 'Processed';
                        value = 'processed';

                                                                        description = 'Transfer\u00eancia finalizada com sucesso';
                                                                        numeric = 4;
                                                                        name = 'processado';
                                                                        mdIcon = 'mdi-cash-check';
                                                                        mdColor = 'success';
                                            };
                }
                            static get Complete() {
                    return new class WalletTransferStatus {
                        id = 'Complete';
                        value = 'complete';

                                                                        description = 'Repasse conclu\u00eddo';
                                                                        numeric = 5;
                                                                        name = 'conclu\u00eddo';
                                                                        mdIcon = 'mdi-check-all';
                                                                        mdColor = 'success';
                                            };
                }
                    };
    