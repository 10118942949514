<script setup>
    import { router, usePage } from '@inertiajs/vue3';
    import { computed, ref } from 'vue';

    const props = defineProps({
        method: {
            type: String,
            required: false,
            default: 'post',
        },
        action: {
            type: String,
            required: false,
        },
        form: {
            type: Object,
            required: false,
            default: {},
        },
        options: {
            type: Object,
            required: false,
            default: {},
        },
        debug: {
            type: [Object, Boolean],
            default: false,
        },
        requireChange: {
            type: Boolean,
        }
    });

    const vuetifyForm = ref();

    const computedForm = computed(function () {
        if (props.form.submit && typeof props.form.submit === 'function') {
            return props.form;
        }

        return router.form(props.form);
    });

    const handleSubmit = async function (submitEvent) {
        const { valid, errors } = await submitEvent;

        if (!valid) {
            return;
        }

        computedForm.value.submit(
            props.method,
            props?.action || usePage().url,
            {
                preserveState: ({ props }) => Object.keys(props?.errors || []).length > 0,
                ...(props?.options || {}),
            }
        );
    };

    const activatorProps = computed(function () {
        return {
            type: 'submit',
            loading: computedForm.value.processing,
            disabled: computedForm.value.processing || (props.requireChange ? !computedForm.value.isDirty : false),
            text: 'Submit',
        };
    });
</script>

<template>
    <v-form ref="vuetifyForm" @submit.prevent="handleSubmit" :disabled="computedForm.processing">
        <x-debug v-if="$props?.debug" :data="computedForm.data()" class="mb-5"
            :="typeof $props.debug === 'boolean' ? {} : $props.debug"></x-debug>
        <slot :="{ activatorProps }"> </slot>
    </v-form>
</template>
