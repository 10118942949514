
        import Enum from '../Helpers/Enum';

        export default class Country extends Enum {
                            static get AF() {
                    return new class Country {
                        id = 'AF';
                        value = 'af';

                                            };
                }
                            static get AL() {
                    return new class Country {
                        id = 'AL';
                        value = 'al';

                                            };
                }
                            static get DZ() {
                    return new class Country {
                        id = 'DZ';
                        value = 'dz';

                                            };
                }
                            static get AS() {
                    return new class Country {
                        id = 'AS';
                        value = 'as';

                                            };
                }
                            static get AD() {
                    return new class Country {
                        id = 'AD';
                        value = 'ad';

                                            };
                }
                            static get AO() {
                    return new class Country {
                        id = 'AO';
                        value = 'ao';

                                            };
                }
                            static get AI() {
                    return new class Country {
                        id = 'AI';
                        value = 'ai';

                                            };
                }
                            static get AQ() {
                    return new class Country {
                        id = 'AQ';
                        value = 'aq';

                                            };
                }
                            static get AG() {
                    return new class Country {
                        id = 'AG';
                        value = 'ag';

                                            };
                }
                            static get AR() {
                    return new class Country {
                        id = 'AR';
                        value = 'ar';

                                            };
                }
                            static get AM() {
                    return new class Country {
                        id = 'AM';
                        value = 'am';

                                            };
                }
                            static get AW() {
                    return new class Country {
                        id = 'AW';
                        value = 'aw';

                                            };
                }
                            static get AU() {
                    return new class Country {
                        id = 'AU';
                        value = 'au';

                                            };
                }
                            static get AT() {
                    return new class Country {
                        id = 'AT';
                        value = 'at';

                                            };
                }
                            static get AZ() {
                    return new class Country {
                        id = 'AZ';
                        value = 'az';

                                            };
                }
                            static get BS() {
                    return new class Country {
                        id = 'BS';
                        value = 'bs';

                                            };
                }
                            static get BH() {
                    return new class Country {
                        id = 'BH';
                        value = 'bh';

                                            };
                }
                            static get BD() {
                    return new class Country {
                        id = 'BD';
                        value = 'bd';

                                            };
                }
                            static get BB() {
                    return new class Country {
                        id = 'BB';
                        value = 'bb';

                                            };
                }
                            static get BY() {
                    return new class Country {
                        id = 'BY';
                        value = 'by';

                                            };
                }
                            static get BE() {
                    return new class Country {
                        id = 'BE';
                        value = 'be';

                                            };
                }
                            static get BZ() {
                    return new class Country {
                        id = 'BZ';
                        value = 'bz';

                                            };
                }
                            static get BJ() {
                    return new class Country {
                        id = 'BJ';
                        value = 'bj';

                                            };
                }
                            static get BM() {
                    return new class Country {
                        id = 'BM';
                        value = 'bm';

                                            };
                }
                            static get BT() {
                    return new class Country {
                        id = 'BT';
                        value = 'bt';

                                            };
                }
                            static get BO() {
                    return new class Country {
                        id = 'BO';
                        value = 'bo';

                                            };
                }
                            static get BQ() {
                    return new class Country {
                        id = 'BQ';
                        value = 'bq';

                                            };
                }
                            static get BA() {
                    return new class Country {
                        id = 'BA';
                        value = 'ba';

                                            };
                }
                            static get BW() {
                    return new class Country {
                        id = 'BW';
                        value = 'bw';

                                            };
                }
                            static get BV() {
                    return new class Country {
                        id = 'BV';
                        value = 'bv';

                                            };
                }
                            static get BR() {
                    return new class Country {
                        id = 'BR';
                        value = 'br';

                                                                        name = 'enums.country.br';
                                            };
                }
                            static get IO() {
                    return new class Country {
                        id = 'IO';
                        value = 'io';

                                            };
                }
                            static get BN() {
                    return new class Country {
                        id = 'BN';
                        value = 'bn';

                                            };
                }
                            static get BG() {
                    return new class Country {
                        id = 'BG';
                        value = 'bg';

                                            };
                }
                            static get BF() {
                    return new class Country {
                        id = 'BF';
                        value = 'bf';

                                            };
                }
                            static get BI() {
                    return new class Country {
                        id = 'BI';
                        value = 'bi';

                                            };
                }
                            static get CV() {
                    return new class Country {
                        id = 'CV';
                        value = 'cv';

                                            };
                }
                            static get KH() {
                    return new class Country {
                        id = 'KH';
                        value = 'kh';

                                            };
                }
                            static get CM() {
                    return new class Country {
                        id = 'CM';
                        value = 'cm';

                                            };
                }
                            static get CA() {
                    return new class Country {
                        id = 'CA';
                        value = 'ca';

                                            };
                }
                            static get KY() {
                    return new class Country {
                        id = 'KY';
                        value = 'ky';

                                            };
                }
                            static get CF() {
                    return new class Country {
                        id = 'CF';
                        value = 'cf';

                                            };
                }
                            static get TD() {
                    return new class Country {
                        id = 'TD';
                        value = 'td';

                                            };
                }
                            static get CL() {
                    return new class Country {
                        id = 'CL';
                        value = 'cl';

                                            };
                }
                            static get CN() {
                    return new class Country {
                        id = 'CN';
                        value = 'cn';

                                            };
                }
                            static get CX() {
                    return new class Country {
                        id = 'CX';
                        value = 'cx';

                                            };
                }
                            static get CC() {
                    return new class Country {
                        id = 'CC';
                        value = 'cc';

                                            };
                }
                            static get CO() {
                    return new class Country {
                        id = 'CO';
                        value = 'co';

                                            };
                }
                            static get KM() {
                    return new class Country {
                        id = 'KM';
                        value = 'km';

                                            };
                }
                            static get CD() {
                    return new class Country {
                        id = 'CD';
                        value = 'cd';

                                            };
                }
                            static get CG() {
                    return new class Country {
                        id = 'CG';
                        value = 'cg';

                                            };
                }
                            static get CK() {
                    return new class Country {
                        id = 'CK';
                        value = 'ck';

                                            };
                }
                            static get CR() {
                    return new class Country {
                        id = 'CR';
                        value = 'cr';

                                            };
                }
                            static get HR() {
                    return new class Country {
                        id = 'HR';
                        value = 'hr';

                                            };
                }
                            static get CU() {
                    return new class Country {
                        id = 'CU';
                        value = 'cu';

                                            };
                }
                            static get CW() {
                    return new class Country {
                        id = 'CW';
                        value = 'cw';

                                            };
                }
                            static get CY() {
                    return new class Country {
                        id = 'CY';
                        value = 'cy';

                                            };
                }
                            static get CZ() {
                    return new class Country {
                        id = 'CZ';
                        value = 'cz';

                                            };
                }
                            static get CI() {
                    return new class Country {
                        id = 'CI';
                        value = 'ci';

                                            };
                }
                            static get DK() {
                    return new class Country {
                        id = 'DK';
                        value = 'dk';

                                            };
                }
                            static get DJ() {
                    return new class Country {
                        id = 'DJ';
                        value = 'dj';

                                            };
                }
                            static get DM() {
                    return new class Country {
                        id = 'DM';
                        value = 'dm';

                                            };
                }
                            static get DO() {
                    return new class Country {
                        id = 'DO';
                        value = 'do';

                                            };
                }
                            static get EC() {
                    return new class Country {
                        id = 'EC';
                        value = 'ec';

                                            };
                }
                            static get EG() {
                    return new class Country {
                        id = 'EG';
                        value = 'eg';

                                            };
                }
                            static get SV() {
                    return new class Country {
                        id = 'SV';
                        value = 'sv';

                                            };
                }
                            static get GQ() {
                    return new class Country {
                        id = 'GQ';
                        value = 'gq';

                                            };
                }
                            static get ER() {
                    return new class Country {
                        id = 'ER';
                        value = 'er';

                                            };
                }
                            static get EE() {
                    return new class Country {
                        id = 'EE';
                        value = 'ee';

                                            };
                }
                            static get SZ() {
                    return new class Country {
                        id = 'SZ';
                        value = 'sz';

                                            };
                }
                            static get ET() {
                    return new class Country {
                        id = 'ET';
                        value = 'et';

                                            };
                }
                            static get FK() {
                    return new class Country {
                        id = 'FK';
                        value = 'fk';

                                            };
                }
                            static get FO() {
                    return new class Country {
                        id = 'FO';
                        value = 'fo';

                                            };
                }
                            static get FJ() {
                    return new class Country {
                        id = 'FJ';
                        value = 'fj';

                                            };
                }
                            static get FI() {
                    return new class Country {
                        id = 'FI';
                        value = 'fi';

                                            };
                }
                            static get FR() {
                    return new class Country {
                        id = 'FR';
                        value = 'fr';

                                            };
                }
                            static get GF() {
                    return new class Country {
                        id = 'GF';
                        value = 'gf';

                                            };
                }
                            static get PF() {
                    return new class Country {
                        id = 'PF';
                        value = 'pf';

                                            };
                }
                            static get TF() {
                    return new class Country {
                        id = 'TF';
                        value = 'tf';

                                            };
                }
                            static get GA() {
                    return new class Country {
                        id = 'GA';
                        value = 'ga';

                                            };
                }
                            static get GM() {
                    return new class Country {
                        id = 'GM';
                        value = 'gm';

                                            };
                }
                            static get GE() {
                    return new class Country {
                        id = 'GE';
                        value = 'ge';

                                            };
                }
                            static get DE() {
                    return new class Country {
                        id = 'DE';
                        value = 'de';

                                            };
                }
                            static get GH() {
                    return new class Country {
                        id = 'GH';
                        value = 'gh';

                                            };
                }
                            static get GI() {
                    return new class Country {
                        id = 'GI';
                        value = 'gi';

                                            };
                }
                            static get GR() {
                    return new class Country {
                        id = 'GR';
                        value = 'gr';

                                            };
                }
                            static get GL() {
                    return new class Country {
                        id = 'GL';
                        value = 'gl';

                                            };
                }
                            static get GD() {
                    return new class Country {
                        id = 'GD';
                        value = 'gd';

                                            };
                }
                            static get GP() {
                    return new class Country {
                        id = 'GP';
                        value = 'gp';

                                            };
                }
                            static get GU() {
                    return new class Country {
                        id = 'GU';
                        value = 'gu';

                                            };
                }
                            static get GT() {
                    return new class Country {
                        id = 'GT';
                        value = 'gt';

                                            };
                }
                            static get GG() {
                    return new class Country {
                        id = 'GG';
                        value = 'gg';

                                            };
                }
                            static get GN() {
                    return new class Country {
                        id = 'GN';
                        value = 'gn';

                                            };
                }
                            static get GW() {
                    return new class Country {
                        id = 'GW';
                        value = 'gw';

                                            };
                }
                            static get GY() {
                    return new class Country {
                        id = 'GY';
                        value = 'gy';

                                            };
                }
                            static get HT() {
                    return new class Country {
                        id = 'HT';
                        value = 'ht';

                                            };
                }
                            static get HM() {
                    return new class Country {
                        id = 'HM';
                        value = 'hm';

                                            };
                }
                            static get VA() {
                    return new class Country {
                        id = 'VA';
                        value = 'va';

                                            };
                }
                            static get HN() {
                    return new class Country {
                        id = 'HN';
                        value = 'hn';

                                            };
                }
                            static get HK() {
                    return new class Country {
                        id = 'HK';
                        value = 'hk';

                                            };
                }
                            static get HU() {
                    return new class Country {
                        id = 'HU';
                        value = 'hu';

                                            };
                }
                            static get IS() {
                    return new class Country {
                        id = 'IS';
                        value = 'is';

                                            };
                }
                            static get IN() {
                    return new class Country {
                        id = 'IN';
                        value = 'in';

                                            };
                }
                            static get ID() {
                    return new class Country {
                        id = 'ID';
                        value = 'id';

                                            };
                }
                            static get IR() {
                    return new class Country {
                        id = 'IR';
                        value = 'ir';

                                            };
                }
                            static get IQ() {
                    return new class Country {
                        id = 'IQ';
                        value = 'iq';

                                            };
                }
                            static get IE() {
                    return new class Country {
                        id = 'IE';
                        value = 'ie';

                                            };
                }
                            static get IM() {
                    return new class Country {
                        id = 'IM';
                        value = 'im';

                                            };
                }
                            static get IL() {
                    return new class Country {
                        id = 'IL';
                        value = 'il';

                                            };
                }
                            static get IT() {
                    return new class Country {
                        id = 'IT';
                        value = 'it';

                                            };
                }
                            static get JM() {
                    return new class Country {
                        id = 'JM';
                        value = 'jm';

                                            };
                }
                            static get JP() {
                    return new class Country {
                        id = 'JP';
                        value = 'jp';

                                            };
                }
                            static get JE() {
                    return new class Country {
                        id = 'JE';
                        value = 'je';

                                            };
                }
                            static get JO() {
                    return new class Country {
                        id = 'JO';
                        value = 'jo';

                                            };
                }
                            static get KZ() {
                    return new class Country {
                        id = 'KZ';
                        value = 'kz';

                                            };
                }
                            static get KE() {
                    return new class Country {
                        id = 'KE';
                        value = 'ke';

                                            };
                }
                            static get KI() {
                    return new class Country {
                        id = 'KI';
                        value = 'ki';

                                            };
                }
                            static get KP() {
                    return new class Country {
                        id = 'KP';
                        value = 'kp';

                                            };
                }
                            static get KR() {
                    return new class Country {
                        id = 'KR';
                        value = 'kr';

                                            };
                }
                            static get KW() {
                    return new class Country {
                        id = 'KW';
                        value = 'kw';

                                            };
                }
                            static get KG() {
                    return new class Country {
                        id = 'KG';
                        value = 'kg';

                                            };
                }
                            static get LA() {
                    return new class Country {
                        id = 'LA';
                        value = 'la';

                                            };
                }
                            static get LV() {
                    return new class Country {
                        id = 'LV';
                        value = 'lv';

                                            };
                }
                            static get LB() {
                    return new class Country {
                        id = 'LB';
                        value = 'lb';

                                            };
                }
                            static get LS() {
                    return new class Country {
                        id = 'LS';
                        value = 'ls';

                                            };
                }
                            static get LR() {
                    return new class Country {
                        id = 'LR';
                        value = 'lr';

                                            };
                }
                            static get LY() {
                    return new class Country {
                        id = 'LY';
                        value = 'ly';

                                            };
                }
                            static get LI() {
                    return new class Country {
                        id = 'LI';
                        value = 'li';

                                            };
                }
                            static get LT() {
                    return new class Country {
                        id = 'LT';
                        value = 'lt';

                                            };
                }
                            static get LU() {
                    return new class Country {
                        id = 'LU';
                        value = 'lu';

                                            };
                }
                            static get MO() {
                    return new class Country {
                        id = 'MO';
                        value = 'mo';

                                            };
                }
                            static get MG() {
                    return new class Country {
                        id = 'MG';
                        value = 'mg';

                                            };
                }
                            static get MW() {
                    return new class Country {
                        id = 'MW';
                        value = 'mw';

                                            };
                }
                            static get MY() {
                    return new class Country {
                        id = 'MY';
                        value = 'my';

                                            };
                }
                            static get MV() {
                    return new class Country {
                        id = 'MV';
                        value = 'mv';

                                            };
                }
                            static get ML() {
                    return new class Country {
                        id = 'ML';
                        value = 'ml';

                                            };
                }
                            static get MT() {
                    return new class Country {
                        id = 'MT';
                        value = 'mt';

                                            };
                }
                            static get MH() {
                    return new class Country {
                        id = 'MH';
                        value = 'mh';

                                            };
                }
                            static get MQ() {
                    return new class Country {
                        id = 'MQ';
                        value = 'mq';

                                            };
                }
                            static get MR() {
                    return new class Country {
                        id = 'MR';
                        value = 'mr';

                                            };
                }
                            static get MU() {
                    return new class Country {
                        id = 'MU';
                        value = 'mu';

                                            };
                }
                            static get YT() {
                    return new class Country {
                        id = 'YT';
                        value = 'yt';

                                            };
                }
                            static get MX() {
                    return new class Country {
                        id = 'MX';
                        value = 'mx';

                                            };
                }
                            static get FM() {
                    return new class Country {
                        id = 'FM';
                        value = 'fm';

                                            };
                }
                            static get MD() {
                    return new class Country {
                        id = 'MD';
                        value = 'md';

                                            };
                }
                            static get MC() {
                    return new class Country {
                        id = 'MC';
                        value = 'mc';

                                            };
                }
                            static get MN() {
                    return new class Country {
                        id = 'MN';
                        value = 'mn';

                                            };
                }
                            static get ME() {
                    return new class Country {
                        id = 'ME';
                        value = 'me';

                                            };
                }
                            static get MS() {
                    return new class Country {
                        id = 'MS';
                        value = 'ms';

                                            };
                }
                            static get MA() {
                    return new class Country {
                        id = 'MA';
                        value = 'ma';

                                            };
                }
                            static get MZ() {
                    return new class Country {
                        id = 'MZ';
                        value = 'mz';

                                            };
                }
                            static get MM() {
                    return new class Country {
                        id = 'MM';
                        value = 'mm';

                                            };
                }
                            static get NA() {
                    return new class Country {
                        id = 'NA';
                        value = 'na';

                                            };
                }
                            static get NR() {
                    return new class Country {
                        id = 'NR';
                        value = 'nr';

                                            };
                }
                            static get NP() {
                    return new class Country {
                        id = 'NP';
                        value = 'np';

                                            };
                }
                            static get NL() {
                    return new class Country {
                        id = 'NL';
                        value = 'nl';

                                            };
                }
                            static get NC() {
                    return new class Country {
                        id = 'NC';
                        value = 'nc';

                                            };
                }
                            static get NZ() {
                    return new class Country {
                        id = 'NZ';
                        value = 'nz';

                                            };
                }
                            static get NI() {
                    return new class Country {
                        id = 'NI';
                        value = 'ni';

                                            };
                }
                            static get NE() {
                    return new class Country {
                        id = 'NE';
                        value = 'ne';

                                            };
                }
                            static get NG() {
                    return new class Country {
                        id = 'NG';
                        value = 'ng';

                                            };
                }
                            static get NU() {
                    return new class Country {
                        id = 'NU';
                        value = 'nu';

                                            };
                }
                            static get NF() {
                    return new class Country {
                        id = 'NF';
                        value = 'nf';

                                            };
                }
                            static get MK() {
                    return new class Country {
                        id = 'MK';
                        value = 'mk';

                                            };
                }
                            static get MP() {
                    return new class Country {
                        id = 'MP';
                        value = 'mp';

                                            };
                }
                            static get NO() {
                    return new class Country {
                        id = 'NO';
                        value = 'no';

                                            };
                }
                            static get OM() {
                    return new class Country {
                        id = 'OM';
                        value = 'om';

                                            };
                }
                            static get PK() {
                    return new class Country {
                        id = 'PK';
                        value = 'pk';

                                            };
                }
                            static get PW() {
                    return new class Country {
                        id = 'PW';
                        value = 'pw';

                                            };
                }
                            static get PS() {
                    return new class Country {
                        id = 'PS';
                        value = 'ps';

                                            };
                }
                            static get PA() {
                    return new class Country {
                        id = 'PA';
                        value = 'pa';

                                            };
                }
                            static get PG() {
                    return new class Country {
                        id = 'PG';
                        value = 'pg';

                                            };
                }
                            static get PY() {
                    return new class Country {
                        id = 'PY';
                        value = 'py';

                                            };
                }
                            static get PE() {
                    return new class Country {
                        id = 'PE';
                        value = 'pe';

                                            };
                }
                            static get PH() {
                    return new class Country {
                        id = 'PH';
                        value = 'ph';

                                            };
                }
                            static get PN() {
                    return new class Country {
                        id = 'PN';
                        value = 'pn';

                                            };
                }
                            static get PL() {
                    return new class Country {
                        id = 'PL';
                        value = 'pl';

                                            };
                }
                            static get PT() {
                    return new class Country {
                        id = 'PT';
                        value = 'pt';

                                            };
                }
                            static get PR() {
                    return new class Country {
                        id = 'PR';
                        value = 'pr';

                                            };
                }
                            static get QA() {
                    return new class Country {
                        id = 'QA';
                        value = 'qa';

                                            };
                }
                            static get RO() {
                    return new class Country {
                        id = 'RO';
                        value = 'ro';

                                            };
                }
                            static get RU() {
                    return new class Country {
                        id = 'RU';
                        value = 'ru';

                                            };
                }
                            static get RW() {
                    return new class Country {
                        id = 'RW';
                        value = 'rw';

                                            };
                }
                            static get RE() {
                    return new class Country {
                        id = 'RE';
                        value = 're';

                                            };
                }
                            static get BL() {
                    return new class Country {
                        id = 'BL';
                        value = 'bl';

                                            };
                }
                            static get SH() {
                    return new class Country {
                        id = 'SH';
                        value = 'sh';

                                            };
                }
                            static get KN() {
                    return new class Country {
                        id = 'KN';
                        value = 'kn';

                                            };
                }
                            static get LC() {
                    return new class Country {
                        id = 'LC';
                        value = 'lc';

                                            };
                }
                            static get MF() {
                    return new class Country {
                        id = 'MF';
                        value = 'mf';

                                            };
                }
                            static get PM() {
                    return new class Country {
                        id = 'PM';
                        value = 'pm';

                                            };
                }
                            static get VC() {
                    return new class Country {
                        id = 'VC';
                        value = 'vc';

                                            };
                }
                            static get WS() {
                    return new class Country {
                        id = 'WS';
                        value = 'ws';

                                            };
                }
                            static get SM() {
                    return new class Country {
                        id = 'SM';
                        value = 'sm';

                                            };
                }
                            static get ST() {
                    return new class Country {
                        id = 'ST';
                        value = 'st';

                                            };
                }
                            static get SA() {
                    return new class Country {
                        id = 'SA';
                        value = 'sa';

                                            };
                }
                            static get SN() {
                    return new class Country {
                        id = 'SN';
                        value = 'sn';

                                            };
                }
                            static get RS() {
                    return new class Country {
                        id = 'RS';
                        value = 'rs';

                                            };
                }
                            static get SC() {
                    return new class Country {
                        id = 'SC';
                        value = 'sc';

                                            };
                }
                            static get SL() {
                    return new class Country {
                        id = 'SL';
                        value = 'sl';

                                            };
                }
                            static get SG() {
                    return new class Country {
                        id = 'SG';
                        value = 'sg';

                                            };
                }
                            static get SX() {
                    return new class Country {
                        id = 'SX';
                        value = 'sx';

                                            };
                }
                            static get SK() {
                    return new class Country {
                        id = 'SK';
                        value = 'sk';

                                            };
                }
                            static get SI() {
                    return new class Country {
                        id = 'SI';
                        value = 'si';

                                            };
                }
                            static get SB() {
                    return new class Country {
                        id = 'SB';
                        value = 'sb';

                                            };
                }
                            static get SO() {
                    return new class Country {
                        id = 'SO';
                        value = 'so';

                                            };
                }
                            static get ZA() {
                    return new class Country {
                        id = 'ZA';
                        value = 'za';

                                            };
                }
                            static get GS() {
                    return new class Country {
                        id = 'GS';
                        value = 'gs';

                                            };
                }
                            static get SS() {
                    return new class Country {
                        id = 'SS';
                        value = 'ss';

                                            };
                }
                            static get ES() {
                    return new class Country {
                        id = 'ES';
                        value = 'es';

                                            };
                }
                            static get LK() {
                    return new class Country {
                        id = 'LK';
                        value = 'lk';

                                            };
                }
                            static get SD() {
                    return new class Country {
                        id = 'SD';
                        value = 'sd';

                                            };
                }
                            static get SR() {
                    return new class Country {
                        id = 'SR';
                        value = 'sr';

                                            };
                }
                            static get SJ() {
                    return new class Country {
                        id = 'SJ';
                        value = 'sj';

                                            };
                }
                            static get SE() {
                    return new class Country {
                        id = 'SE';
                        value = 'se';

                                            };
                }
                            static get CH() {
                    return new class Country {
                        id = 'CH';
                        value = 'ch';

                                            };
                }
                            static get SY() {
                    return new class Country {
                        id = 'SY';
                        value = 'sy';

                                            };
                }
                            static get TW() {
                    return new class Country {
                        id = 'TW';
                        value = 'tw';

                                            };
                }
                            static get TJ() {
                    return new class Country {
                        id = 'TJ';
                        value = 'tj';

                                            };
                }
                            static get TZ() {
                    return new class Country {
                        id = 'TZ';
                        value = 'tz';

                                            };
                }
                            static get TH() {
                    return new class Country {
                        id = 'TH';
                        value = 'th';

                                            };
                }
                            static get TL() {
                    return new class Country {
                        id = 'TL';
                        value = 'tl';

                                            };
                }
                            static get TG() {
                    return new class Country {
                        id = 'TG';
                        value = 'tg';

                                            };
                }
                            static get TK() {
                    return new class Country {
                        id = 'TK';
                        value = 'tk';

                                            };
                }
                            static get TO() {
                    return new class Country {
                        id = 'TO';
                        value = 'to';

                                            };
                }
                            static get TT() {
                    return new class Country {
                        id = 'TT';
                        value = 'tt';

                                            };
                }
                            static get TN() {
                    return new class Country {
                        id = 'TN';
                        value = 'tn';

                                            };
                }
                            static get TM() {
                    return new class Country {
                        id = 'TM';
                        value = 'tm';

                                            };
                }
                            static get TC() {
                    return new class Country {
                        id = 'TC';
                        value = 'tc';

                                            };
                }
                            static get TV() {
                    return new class Country {
                        id = 'TV';
                        value = 'tv';

                                            };
                }
                            static get TR() {
                    return new class Country {
                        id = 'TR';
                        value = 'tr';

                                            };
                }
                            static get UG() {
                    return new class Country {
                        id = 'UG';
                        value = 'ug';

                                            };
                }
                            static get UA() {
                    return new class Country {
                        id = 'UA';
                        value = 'ua';

                                            };
                }
                            static get AE() {
                    return new class Country {
                        id = 'AE';
                        value = 'ae';

                                            };
                }
                            static get GB() {
                    return new class Country {
                        id = 'GB';
                        value = 'gb';

                                            };
                }
                            static get UM() {
                    return new class Country {
                        id = 'UM';
                        value = 'um';

                                            };
                }
                            static get US() {
                    return new class Country {
                        id = 'US';
                        value = 'us';

                                                                        name = 'enums.country.us';
                                            };
                }
                            static get UY() {
                    return new class Country {
                        id = 'UY';
                        value = 'uy';

                                            };
                }
                            static get UZ() {
                    return new class Country {
                        id = 'UZ';
                        value = 'uz';

                                            };
                }
                            static get VU() {
                    return new class Country {
                        id = 'VU';
                        value = 'vu';

                                            };
                }
                            static get VE() {
                    return new class Country {
                        id = 'VE';
                        value = 've';

                                            };
                }
                            static get VN() {
                    return new class Country {
                        id = 'VN';
                        value = 'vn';

                                            };
                }
                            static get VG() {
                    return new class Country {
                        id = 'VG';
                        value = 'vg';

                                            };
                }
                            static get VI() {
                    return new class Country {
                        id = 'VI';
                        value = 'vi';

                                            };
                }
                            static get WF() {
                    return new class Country {
                        id = 'WF';
                        value = 'wf';

                                            };
                }
                            static get EH() {
                    return new class Country {
                        id = 'EH';
                        value = 'eh';

                                            };
                }
                            static get YE() {
                    return new class Country {
                        id = 'YE';
                        value = 'ye';

                                            };
                }
                            static get ZM() {
                    return new class Country {
                        id = 'ZM';
                        value = 'zm';

                                            };
                }
                            static get ZW() {
                    return new class Country {
                        id = 'ZW';
                        value = 'zw';

                                            };
                }
                            static get AX() {
                    return new class Country {
                        id = 'AX';
                        value = 'ax';

                                            };
                }
                    };
    