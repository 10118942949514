<script setup>

</script>

<template>

    <v-app-bar style="transition: background-color 0.1s;">


        <template #default>
            <slot v-if="$vuetify.display.mobile" name="mobile">
            </slot>

            <slot v-else name="default"></slot>

        </template>

    </v-app-bar>


</template>