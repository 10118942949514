
        import Enum from '../Helpers/Enum';

        export default class Timezone extends Enum {
                            static get UTC() {
                    return new class Timezone {
                        id = 'UTC';
                        value = 'utc';

                                            };
                }
                            static get AMERICA_ADAK() {
                    return new class Timezone {
                        id = 'AMERICA_ADAK';
                        value = 'America/Adak';

                                            };
                }
                            static get AMERICA_ANCHORAGE() {
                    return new class Timezone {
                        id = 'AMERICA_ANCHORAGE';
                        value = 'America/Anchorage';

                                            };
                }
                            static get AMERICA_ANGUILLA() {
                    return new class Timezone {
                        id = 'AMERICA_ANGUILLA';
                        value = 'America/Anguilla';

                                            };
                }
                            static get AMERICA_ANTIGUA() {
                    return new class Timezone {
                        id = 'AMERICA_ANTIGUA';
                        value = 'America/Antigua';

                                            };
                }
                            static get AMERICA_ARAGUAINA() {
                    return new class Timezone {
                        id = 'AMERICA_ARAGUAINA';
                        value = 'America/Araguaina';

                                            };
                }
                            static get AMERICA_ARGENTINA_BUENOS_AIRES() {
                    return new class Timezone {
                        id = 'AMERICA_ARGENTINA_BUENOS_AIRES';
                        value = 'America/Argentina/Buenos_Aires';

                                            };
                }
                            static get AMERICA_ARGENTINA_CATAMARCA() {
                    return new class Timezone {
                        id = 'AMERICA_ARGENTINA_CATAMARCA';
                        value = 'America/Argentina/Catamarca';

                                            };
                }
                            static get AMERICA_ARGENTINA_CORDOBA() {
                    return new class Timezone {
                        id = 'AMERICA_ARGENTINA_CORDOBA';
                        value = 'America/Argentina/Cordoba';

                                            };
                }
                            static get AMERICA_ARGENTINA_JUJUY() {
                    return new class Timezone {
                        id = 'AMERICA_ARGENTINA_JUJUY';
                        value = 'America/Argentina/Jujuy';

                                            };
                }
                            static get AMERICA_ARGENTINA_LA_RIOJA() {
                    return new class Timezone {
                        id = 'AMERICA_ARGENTINA_LA_RIOJA';
                        value = 'America/Argentina/La_Rioja';

                                            };
                }
                            static get AMERICA_ARGENTINA_MENDOZA() {
                    return new class Timezone {
                        id = 'AMERICA_ARGENTINA_MENDOZA';
                        value = 'America/Argentina/Mendoza';

                                            };
                }
                            static get AMERICA_ARGENTINA_RIO_GALLEGOS() {
                    return new class Timezone {
                        id = 'AMERICA_ARGENTINA_RIO_GALLEGOS';
                        value = 'America/Argentina/Rio_Gallegos';

                                            };
                }
                            static get AMERICA_ARGENTINA_SALTA() {
                    return new class Timezone {
                        id = 'AMERICA_ARGENTINA_SALTA';
                        value = 'America/Argentina/Salta';

                                            };
                }
                            static get AMERICA_ARGENTINA_SAN_JUAN() {
                    return new class Timezone {
                        id = 'AMERICA_ARGENTINA_SAN_JUAN';
                        value = 'America/Argentina/San_Juan';

                                            };
                }
                            static get AMERICA_ARGENTINA_SAN_LUIS() {
                    return new class Timezone {
                        id = 'AMERICA_ARGENTINA_SAN_LUIS';
                        value = 'America/Argentina/San_Luis';

                                            };
                }
                            static get AMERICA_ARGENTINA_TUCUMAN() {
                    return new class Timezone {
                        id = 'AMERICA_ARGENTINA_TUCUMAN';
                        value = 'America/Argentina/Tucuman';

                                            };
                }
                            static get AMERICA_ARGENTINA_USHUAIA() {
                    return new class Timezone {
                        id = 'AMERICA_ARGENTINA_USHUAIA';
                        value = 'America/Argentina/Ushuaia';

                                            };
                }
                            static get AMERICA_ARUBA() {
                    return new class Timezone {
                        id = 'AMERICA_ARUBA';
                        value = 'America/Aruba';

                                            };
                }
                            static get AMERICA_ASUNCION() {
                    return new class Timezone {
                        id = 'AMERICA_ASUNCION';
                        value = 'America/Asuncion';

                                            };
                }
                            static get AMERICA_ATIKOKAN() {
                    return new class Timezone {
                        id = 'AMERICA_ATIKOKAN';
                        value = 'America/Atikokan';

                                            };
                }
                            static get AMERICA_BAHIA() {
                    return new class Timezone {
                        id = 'AMERICA_BAHIA';
                        value = 'America/Bahia';

                                            };
                }
                            static get AMERICA_BAHIA_BANDERAS() {
                    return new class Timezone {
                        id = 'AMERICA_BAHIA_BANDERAS';
                        value = 'America/Bahia_Banderas';

                                            };
                }
                            static get AMERICA_BARBADOS() {
                    return new class Timezone {
                        id = 'AMERICA_BARBADOS';
                        value = 'America/Barbados';

                                            };
                }
                            static get AMERICA_BELEM() {
                    return new class Timezone {
                        id = 'AMERICA_BELEM';
                        value = 'America/Belem';

                                            };
                }
                            static get AMERICA_BELIZE() {
                    return new class Timezone {
                        id = 'AMERICA_BELIZE';
                        value = 'America/Belize';

                                            };
                }
                            static get AMERICA_BLANC_SABLON() {
                    return new class Timezone {
                        id = 'AMERICA_BLANC_SABLON';
                        value = 'America/Blanc-Sablon';

                                            };
                }
                            static get AMERICA_BOA_VISTA() {
                    return new class Timezone {
                        id = 'AMERICA_BOA_VISTA';
                        value = 'America/Boa_Vista';

                                            };
                }
                            static get AMERICA_BOGOTA() {
                    return new class Timezone {
                        id = 'AMERICA_BOGOTA';
                        value = 'America/Bogota';

                                            };
                }
                            static get AMERICA_BOISE() {
                    return new class Timezone {
                        id = 'AMERICA_BOISE';
                        value = 'America/Boise';

                                            };
                }
                            static get AMERICA_CAMBRIDGE_BAY() {
                    return new class Timezone {
                        id = 'AMERICA_CAMBRIDGE_BAY';
                        value = 'America/Cambridge_Bay';

                                            };
                }
                            static get AMERICA_CAMPO_GRANDE() {
                    return new class Timezone {
                        id = 'AMERICA_CAMPO_GRANDE';
                        value = 'America/Campo_Grande';

                                            };
                }
                            static get AMERICA_CANCUN() {
                    return new class Timezone {
                        id = 'AMERICA_CANCUN';
                        value = 'America/Cancun';

                                            };
                }
                            static get AMERICA_CARACAS() {
                    return new class Timezone {
                        id = 'AMERICA_CARACAS';
                        value = 'America/Caracas';

                                            };
                }
                            static get AMERICA_CAYENNE() {
                    return new class Timezone {
                        id = 'AMERICA_CAYENNE';
                        value = 'America/Cayenne';

                                            };
                }
                            static get AMERICA_CAYMAN() {
                    return new class Timezone {
                        id = 'AMERICA_CAYMAN';
                        value = 'America/Cayman';

                                            };
                }
                            static get AMERICA_CHICAGO() {
                    return new class Timezone {
                        id = 'AMERICA_CHICAGO';
                        value = 'America/Chicago';

                                            };
                }
                            static get AMERICA_CHIHUAHUA() {
                    return new class Timezone {
                        id = 'AMERICA_CHIHUAHUA';
                        value = 'America/Chihuahua';

                                            };
                }
                            static get AMERICA_CIUDAD_JUAREZ() {
                    return new class Timezone {
                        id = 'AMERICA_CIUDAD_JUAREZ';
                        value = 'America/Ciudad_Juarez';

                                            };
                }
                            static get AMERICA_COSTA_RICA() {
                    return new class Timezone {
                        id = 'AMERICA_COSTA_RICA';
                        value = 'America/Costa_Rica';

                                            };
                }
                            static get AMERICA_CRESTON() {
                    return new class Timezone {
                        id = 'AMERICA_CRESTON';
                        value = 'America/Creston';

                                            };
                }
                            static get AMERICA_CUIABA() {
                    return new class Timezone {
                        id = 'AMERICA_CUIABA';
                        value = 'America/Cuiaba';

                                            };
                }
                            static get AMERICA_CURACAO() {
                    return new class Timezone {
                        id = 'AMERICA_CURACAO';
                        value = 'America/Curacao';

                                            };
                }
                            static get AMERICA_DANMARKSHAVN() {
                    return new class Timezone {
                        id = 'AMERICA_DANMARKSHAVN';
                        value = 'America/Danmarkshavn';

                                            };
                }
                            static get AMERICA_DAWSON() {
                    return new class Timezone {
                        id = 'AMERICA_DAWSON';
                        value = 'America/Dawson';

                                            };
                }
                            static get AMERICA_DAWSON_CREEK() {
                    return new class Timezone {
                        id = 'AMERICA_DAWSON_CREEK';
                        value = 'America/Dawson_Creek';

                                            };
                }
                            static get AMERICA_DENVER() {
                    return new class Timezone {
                        id = 'AMERICA_DENVER';
                        value = 'America/Denver';

                                            };
                }
                            static get AMERICA_DETROIT() {
                    return new class Timezone {
                        id = 'AMERICA_DETROIT';
                        value = 'America/Detroit';

                                            };
                }
                            static get AMERICA_DOMINICA() {
                    return new class Timezone {
                        id = 'AMERICA_DOMINICA';
                        value = 'America/Dominica';

                                            };
                }
                            static get AMERICA_EDMONTON() {
                    return new class Timezone {
                        id = 'AMERICA_EDMONTON';
                        value = 'America/Edmonton';

                                            };
                }
                            static get AMERICA_EIRUNEPE() {
                    return new class Timezone {
                        id = 'AMERICA_EIRUNEPE';
                        value = 'America/Eirunepe';

                                            };
                }
                            static get AMERICA_EL_SALVADOR() {
                    return new class Timezone {
                        id = 'AMERICA_EL_SALVADOR';
                        value = 'America/El_Salvador';

                                            };
                }
                            static get AMERICA_FORT_NELSON() {
                    return new class Timezone {
                        id = 'AMERICA_FORT_NELSON';
                        value = 'America/Fort_Nelson';

                                            };
                }
                            static get AMERICA_FORTALEZA() {
                    return new class Timezone {
                        id = 'AMERICA_FORTALEZA';
                        value = 'America/Fortaleza';

                                            };
                }
                            static get AMERICA_GLACE_BAY() {
                    return new class Timezone {
                        id = 'AMERICA_GLACE_BAY';
                        value = 'America/Glace_Bay';

                                            };
                }
                            static get AMERICA_GOOSE_BAY() {
                    return new class Timezone {
                        id = 'AMERICA_GOOSE_BAY';
                        value = 'America/Goose_Bay';

                                            };
                }
                            static get AMERICA_GRAND_TURK() {
                    return new class Timezone {
                        id = 'AMERICA_GRAND_TURK';
                        value = 'America/Grand_Turk';

                                            };
                }
                            static get AMERICA_GRENADA() {
                    return new class Timezone {
                        id = 'AMERICA_GRENADA';
                        value = 'America/Grenada';

                                            };
                }
                            static get AMERICA_GUADELOUPE() {
                    return new class Timezone {
                        id = 'AMERICA_GUADELOUPE';
                        value = 'America/Guadeloupe';

                                            };
                }
                            static get AMERICA_GUATEMALA() {
                    return new class Timezone {
                        id = 'AMERICA_GUATEMALA';
                        value = 'America/Guatemala';

                                            };
                }
                            static get AMERICA_GUAYAQUIL() {
                    return new class Timezone {
                        id = 'AMERICA_GUAYAQUIL';
                        value = 'America/Guayaquil';

                                            };
                }
                            static get AMERICA_GUYANA() {
                    return new class Timezone {
                        id = 'AMERICA_GUYANA';
                        value = 'America/Guyana';

                                            };
                }
                            static get AMERICA_HALIFAX() {
                    return new class Timezone {
                        id = 'AMERICA_HALIFAX';
                        value = 'America/Halifax';

                                            };
                }
                            static get AMERICA_HAVANA() {
                    return new class Timezone {
                        id = 'AMERICA_HAVANA';
                        value = 'America/Havana';

                                            };
                }
                            static get AMERICA_HERMOSILLO() {
                    return new class Timezone {
                        id = 'AMERICA_HERMOSILLO';
                        value = 'America/Hermosillo';

                                            };
                }
                            static get AMERICA_INDIANA_INDIANAPOLIS() {
                    return new class Timezone {
                        id = 'AMERICA_INDIANA_INDIANAPOLIS';
                        value = 'America/Indiana/Indianapolis';

                                            };
                }
                            static get AMERICA_INDIANA_KNOX() {
                    return new class Timezone {
                        id = 'AMERICA_INDIANA_KNOX';
                        value = 'America/Indiana/Knox';

                                            };
                }
                            static get AMERICA_INDIANA_MARENGO() {
                    return new class Timezone {
                        id = 'AMERICA_INDIANA_MARENGO';
                        value = 'America/Indiana/Marengo';

                                            };
                }
                            static get AMERICA_INDIANA_PETERSBURG() {
                    return new class Timezone {
                        id = 'AMERICA_INDIANA_PETERSBURG';
                        value = 'America/Indiana/Petersburg';

                                            };
                }
                            static get AMERICA_INDIANA_TELL_CITY() {
                    return new class Timezone {
                        id = 'AMERICA_INDIANA_TELL_CITY';
                        value = 'America/Indiana/Tell_City';

                                            };
                }
                            static get AMERICA_INDIANA_VEVAY() {
                    return new class Timezone {
                        id = 'AMERICA_INDIANA_VEVAY';
                        value = 'America/Indiana/Vevay';

                                            };
                }
                            static get AMERICA_INDIANA_VINCENNES() {
                    return new class Timezone {
                        id = 'AMERICA_INDIANA_VINCENNES';
                        value = 'America/Indiana/Vincennes';

                                            };
                }
                            static get AMERICA_INDIANA_WINAMAC() {
                    return new class Timezone {
                        id = 'AMERICA_INDIANA_WINAMAC';
                        value = 'America/Indiana/Winamac';

                                            };
                }
                            static get AMERICA_INUVIK() {
                    return new class Timezone {
                        id = 'AMERICA_INUVIK';
                        value = 'America/Inuvik';

                                            };
                }
                            static get AMERICA_IQALUIT() {
                    return new class Timezone {
                        id = 'AMERICA_IQALUIT';
                        value = 'America/Iqaluit';

                                            };
                }
                            static get AMERICA_JAMAICA() {
                    return new class Timezone {
                        id = 'AMERICA_JAMAICA';
                        value = 'America/Jamaica';

                                            };
                }
                            static get AMERICA_JUNEAU() {
                    return new class Timezone {
                        id = 'AMERICA_JUNEAU';
                        value = 'America/Juneau';

                                            };
                }
                            static get AMERICA_KENTUCKY_LOUISVILLE() {
                    return new class Timezone {
                        id = 'AMERICA_KENTUCKY_LOUISVILLE';
                        value = 'America/Kentucky/Louisville';

                                            };
                }
                            static get AMERICA_KENTUCKY_MONTICELLO() {
                    return new class Timezone {
                        id = 'AMERICA_KENTUCKY_MONTICELLO';
                        value = 'America/Kentucky/Monticello';

                                            };
                }
                            static get AMERICA_KRALENDIJK() {
                    return new class Timezone {
                        id = 'AMERICA_KRALENDIJK';
                        value = 'America/Kralendijk';

                                            };
                }
                            static get AMERICA_LA_PAZ() {
                    return new class Timezone {
                        id = 'AMERICA_LA_PAZ';
                        value = 'America/La_Paz';

                                            };
                }
                            static get AMERICA_LIMA() {
                    return new class Timezone {
                        id = 'AMERICA_LIMA';
                        value = 'America/Lima';

                                            };
                }
                            static get AMERICA_LOS_ANGELES() {
                    return new class Timezone {
                        id = 'AMERICA_LOS_ANGELES';
                        value = 'America/Los_Angeles';

                                            };
                }
                            static get AMERICA_LOWER_PRINCES() {
                    return new class Timezone {
                        id = 'AMERICA_LOWER_PRINCES';
                        value = 'America/Lower_Princes';

                                            };
                }
                            static get AMERICA_MACEIO() {
                    return new class Timezone {
                        id = 'AMERICA_MACEIO';
                        value = 'America/Maceio';

                                            };
                }
                            static get AMERICA_MANAGUA() {
                    return new class Timezone {
                        id = 'AMERICA_MANAGUA';
                        value = 'America/Managua';

                                            };
                }
                            static get AMERICA_MANAUS() {
                    return new class Timezone {
                        id = 'AMERICA_MANAUS';
                        value = 'America/Manaus';

                                            };
                }
                            static get AMERICA_MARIGOT() {
                    return new class Timezone {
                        id = 'AMERICA_MARIGOT';
                        value = 'America/Marigot';

                                            };
                }
                            static get AMERICA_MARTINIQUE() {
                    return new class Timezone {
                        id = 'AMERICA_MARTINIQUE';
                        value = 'America/Martinique';

                                            };
                }
                            static get AMERICA_MATAMOROS() {
                    return new class Timezone {
                        id = 'AMERICA_MATAMOROS';
                        value = 'America/Matamoros';

                                            };
                }
                            static get AMERICA_MAZATLAN() {
                    return new class Timezone {
                        id = 'AMERICA_MAZATLAN';
                        value = 'America/Mazatlan';

                                            };
                }
                            static get AMERICA_MENOMINEE() {
                    return new class Timezone {
                        id = 'AMERICA_MENOMINEE';
                        value = 'America/Menominee';

                                            };
                }
                            static get AMERICA_MERIDA() {
                    return new class Timezone {
                        id = 'AMERICA_MERIDA';
                        value = 'America/Merida';

                                            };
                }
                            static get AMERICA_METLAKATLA() {
                    return new class Timezone {
                        id = 'AMERICA_METLAKATLA';
                        value = 'America/Metlakatla';

                                            };
                }
                            static get AMERICA_MEXICO_CITY() {
                    return new class Timezone {
                        id = 'AMERICA_MEXICO_CITY';
                        value = 'America/Mexico_City';

                                            };
                }
                            static get AMERICA_MIQUELON() {
                    return new class Timezone {
                        id = 'AMERICA_MIQUELON';
                        value = 'America/Miquelon';

                                            };
                }
                            static get AMERICA_MONCTON() {
                    return new class Timezone {
                        id = 'AMERICA_MONCTON';
                        value = 'America/Moncton';

                                            };
                }
                            static get AMERICA_MONTERREY() {
                    return new class Timezone {
                        id = 'AMERICA_MONTERREY';
                        value = 'America/Monterrey';

                                            };
                }
                            static get AMERICA_MONTEVIDEO() {
                    return new class Timezone {
                        id = 'AMERICA_MONTEVIDEO';
                        value = 'America/Montevideo';

                                            };
                }
                            static get AMERICA_MONTSERRAT() {
                    return new class Timezone {
                        id = 'AMERICA_MONTSERRAT';
                        value = 'America/Montserrat';

                                            };
                }
                            static get AMERICA_NASSAU() {
                    return new class Timezone {
                        id = 'AMERICA_NASSAU';
                        value = 'America/Nassau';

                                            };
                }
                            static get AMERICA_NEW_YORK() {
                    return new class Timezone {
                        id = 'AMERICA_NEW_YORK';
                        value = 'America/New_York';

                                            };
                }
                            static get AMERICA_NOME() {
                    return new class Timezone {
                        id = 'AMERICA_NOME';
                        value = 'America/Nome';

                                            };
                }
                            static get AMERICA_NORONHA() {
                    return new class Timezone {
                        id = 'AMERICA_NORONHA';
                        value = 'America/Noronha';

                                            };
                }
                            static get AMERICA_NORTH_DAKOTA_BEULAH() {
                    return new class Timezone {
                        id = 'AMERICA_NORTH_DAKOTA_BEULAH';
                        value = 'America/North_Dakota/Beulah';

                                            };
                }
                            static get AMERICA_NORTH_DAKOTA_CENTER() {
                    return new class Timezone {
                        id = 'AMERICA_NORTH_DAKOTA_CENTER';
                        value = 'America/North_Dakota/Center';

                                            };
                }
                            static get AMERICA_NORTH_DAKOTA_NEW_SALEM() {
                    return new class Timezone {
                        id = 'AMERICA_NORTH_DAKOTA_NEW_SALEM';
                        value = 'America/North_Dakota/New_Salem';

                                            };
                }
                            static get AMERICA_NUUK() {
                    return new class Timezone {
                        id = 'AMERICA_NUUK';
                        value = 'America/Nuuk';

                                            };
                }
                            static get AMERICA_OJINAGA() {
                    return new class Timezone {
                        id = 'AMERICA_OJINAGA';
                        value = 'America/Ojinaga';

                                            };
                }
                            static get AMERICA_PANAMA() {
                    return new class Timezone {
                        id = 'AMERICA_PANAMA';
                        value = 'America/Panama';

                                            };
                }
                            static get AMERICA_PARAMARIBO() {
                    return new class Timezone {
                        id = 'AMERICA_PARAMARIBO';
                        value = 'America/Paramaribo';

                                            };
                }
                            static get AMERICA_PHOENIX() {
                    return new class Timezone {
                        id = 'AMERICA_PHOENIX';
                        value = 'America/Phoenix';

                                            };
                }
                            static get AMERICA_PORT_AU_PRINCE() {
                    return new class Timezone {
                        id = 'AMERICA_PORT_AU_PRINCE';
                        value = 'America/Port-au-Prince';

                                            };
                }
                            static get AMERICA_PORT_OF_SPAIN() {
                    return new class Timezone {
                        id = 'AMERICA_PORT_OF_SPAIN';
                        value = 'America/Port_of_Spain';

                                            };
                }
                            static get AMERICA_PORTO_VELHO() {
                    return new class Timezone {
                        id = 'AMERICA_PORTO_VELHO';
                        value = 'America/Porto_Velho';

                                            };
                }
                            static get AMERICA_PUERTO_RICO() {
                    return new class Timezone {
                        id = 'AMERICA_PUERTO_RICO';
                        value = 'America/Puerto_Rico';

                                            };
                }
                            static get AMERICA_PUNTA_ARENAS() {
                    return new class Timezone {
                        id = 'AMERICA_PUNTA_ARENAS';
                        value = 'America/Punta_Arenas';

                                            };
                }
                            static get AMERICA_RANKIN_INLET() {
                    return new class Timezone {
                        id = 'AMERICA_RANKIN_INLET';
                        value = 'America/Rankin_Inlet';

                                            };
                }
                            static get AMERICA_RECIFE() {
                    return new class Timezone {
                        id = 'AMERICA_RECIFE';
                        value = 'America/Recife';

                                            };
                }
                            static get AMERICA_REGINA() {
                    return new class Timezone {
                        id = 'AMERICA_REGINA';
                        value = 'America/Regina';

                                            };
                }
                            static get AMERICA_RESOLUTE() {
                    return new class Timezone {
                        id = 'AMERICA_RESOLUTE';
                        value = 'America/Resolute';

                                            };
                }
                            static get AMERICA_RIO_BRANCO() {
                    return new class Timezone {
                        id = 'AMERICA_RIO_BRANCO';
                        value = 'America/Rio_Branco';

                                            };
                }
                            static get AMERICA_SANTAREM() {
                    return new class Timezone {
                        id = 'AMERICA_SANTAREM';
                        value = 'America/Santarem';

                                            };
                }
                            static get AMERICA_SANTIAGO() {
                    return new class Timezone {
                        id = 'AMERICA_SANTIAGO';
                        value = 'America/Santiago';

                                            };
                }
                            static get AMERICA_SANTO_DOMINGO() {
                    return new class Timezone {
                        id = 'AMERICA_SANTO_DOMINGO';
                        value = 'America/Santo_Domingo';

                                            };
                }
                            static get AMERICA_SAO_PAULO() {
                    return new class Timezone {
                        id = 'AMERICA_SAO_PAULO';
                        value = 'America/Sao_Paulo';

                                            };
                }
                            static get AMERICA_SCORESBYSUND() {
                    return new class Timezone {
                        id = 'AMERICA_SCORESBYSUND';
                        value = 'America/Scoresbysund';

                                            };
                }
                            static get AMERICA_SITKA() {
                    return new class Timezone {
                        id = 'AMERICA_SITKA';
                        value = 'America/Sitka';

                                            };
                }
                            static get AMERICA_ST_BARTHELEMY() {
                    return new class Timezone {
                        id = 'AMERICA_ST_BARTHELEMY';
                        value = 'America/St_Barthelemy';

                                            };
                }
                            static get AMERICA_ST_JOHNS() {
                    return new class Timezone {
                        id = 'AMERICA_ST_JOHNS';
                        value = 'America/St_Johns';

                                            };
                }
                            static get AMERICA_ST_KITTS() {
                    return new class Timezone {
                        id = 'AMERICA_ST_KITTS';
                        value = 'America/St_Kitts';

                                            };
                }
                            static get AMERICA_ST_LUCIA() {
                    return new class Timezone {
                        id = 'AMERICA_ST_LUCIA';
                        value = 'America/St_Lucia';

                                            };
                }
                            static get AMERICA_ST_THOMAS() {
                    return new class Timezone {
                        id = 'AMERICA_ST_THOMAS';
                        value = 'America/St_Thomas';

                                            };
                }
                            static get AMERICA_ST_VINCENT() {
                    return new class Timezone {
                        id = 'AMERICA_ST_VINCENT';
                        value = 'America/St_Vincent';

                                            };
                }
                            static get AMERICA_SWIFT_CURRENT() {
                    return new class Timezone {
                        id = 'AMERICA_SWIFT_CURRENT';
                        value = 'America/Swift_Current';

                                            };
                }
                            static get AMERICA_TEGUCIGALPA() {
                    return new class Timezone {
                        id = 'AMERICA_TEGUCIGALPA';
                        value = 'America/Tegucigalpa';

                                            };
                }
                            static get AMERICA_THULE() {
                    return new class Timezone {
                        id = 'AMERICA_THULE';
                        value = 'America/Thule';

                                            };
                }
                            static get AMERICA_TIJUANA() {
                    return new class Timezone {
                        id = 'AMERICA_TIJUANA';
                        value = 'America/Tijuana';

                                            };
                }
                            static get AMERICA_TORONTO() {
                    return new class Timezone {
                        id = 'AMERICA_TORONTO';
                        value = 'America/Toronto';

                                            };
                }
                            static get AMERICA_TORTOLA() {
                    return new class Timezone {
                        id = 'AMERICA_TORTOLA';
                        value = 'America/Tortola';

                                            };
                }
                            static get AMERICA_VANCOUVER() {
                    return new class Timezone {
                        id = 'AMERICA_VANCOUVER';
                        value = 'America/Vancouver';

                                            };
                }
                            static get AMERICA_WHITEHORSE() {
                    return new class Timezone {
                        id = 'AMERICA_WHITEHORSE';
                        value = 'America/Whitehorse';

                                            };
                }
                            static get AMERICA_WINNIPEG() {
                    return new class Timezone {
                        id = 'AMERICA_WINNIPEG';
                        value = 'America/Winnipeg';

                                            };
                }
                            static get AMERICA_YAKUTAT() {
                    return new class Timezone {
                        id = 'AMERICA_YAKUTAT';
                        value = 'America/Yakutat';

                                            };
                }
                            static get AFRICA_ABIDJAN() {
                    return new class Timezone {
                        id = 'AFRICA_ABIDJAN';
                        value = 'Africa/Abidjan';

                                            };
                }
                            static get AFRICA_ACCRA() {
                    return new class Timezone {
                        id = 'AFRICA_ACCRA';
                        value = 'Africa/Accra';

                                            };
                }
                            static get AFRICA_ADDIS_ABABA() {
                    return new class Timezone {
                        id = 'AFRICA_ADDIS_ABABA';
                        value = 'Africa/Addis_Ababa';

                                            };
                }
                            static get AFRICA_ALGIERS() {
                    return new class Timezone {
                        id = 'AFRICA_ALGIERS';
                        value = 'Africa/Algiers';

                                            };
                }
                            static get AFRICA_ASMARA() {
                    return new class Timezone {
                        id = 'AFRICA_ASMARA';
                        value = 'Africa/Asmara';

                                            };
                }
                            static get AFRICA_BAMAKO() {
                    return new class Timezone {
                        id = 'AFRICA_BAMAKO';
                        value = 'Africa/Bamako';

                                            };
                }
                            static get AFRICA_BANGUI() {
                    return new class Timezone {
                        id = 'AFRICA_BANGUI';
                        value = 'Africa/Bangui';

                                            };
                }
                            static get AFRICA_BANJUL() {
                    return new class Timezone {
                        id = 'AFRICA_BANJUL';
                        value = 'Africa/Banjul';

                                            };
                }
                            static get AFRICA_BISSAU() {
                    return new class Timezone {
                        id = 'AFRICA_BISSAU';
                        value = 'Africa/Bissau';

                                            };
                }
                            static get AFRICA_BLANTYRE() {
                    return new class Timezone {
                        id = 'AFRICA_BLANTYRE';
                        value = 'Africa/Blantyre';

                                            };
                }
                            static get AFRICA_BRAZZAVILLE() {
                    return new class Timezone {
                        id = 'AFRICA_BRAZZAVILLE';
                        value = 'Africa/Brazzaville';

                                            };
                }
                            static get AFRICA_BUJUMBURA() {
                    return new class Timezone {
                        id = 'AFRICA_BUJUMBURA';
                        value = 'Africa/Bujumbura';

                                            };
                }
                            static get AFRICA_CAIRO() {
                    return new class Timezone {
                        id = 'AFRICA_CAIRO';
                        value = 'Africa/Cairo';

                                            };
                }
                            static get AFRICA_CASABLANCA() {
                    return new class Timezone {
                        id = 'AFRICA_CASABLANCA';
                        value = 'Africa/Casablanca';

                                            };
                }
                            static get AFRICA_CEUTA() {
                    return new class Timezone {
                        id = 'AFRICA_CEUTA';
                        value = 'Africa/Ceuta';

                                            };
                }
                            static get AFRICA_CONAKRY() {
                    return new class Timezone {
                        id = 'AFRICA_CONAKRY';
                        value = 'Africa/Conakry';

                                            };
                }
                            static get AFRICA_DAKAR() {
                    return new class Timezone {
                        id = 'AFRICA_DAKAR';
                        value = 'Africa/Dakar';

                                            };
                }
                            static get AFRICA_DAR_ES_SALAAM() {
                    return new class Timezone {
                        id = 'AFRICA_DAR_ES_SALAAM';
                        value = 'Africa/Dar_es_Salaam';

                                            };
                }
                            static get AFRICA_DJIBOUTI() {
                    return new class Timezone {
                        id = 'AFRICA_DJIBOUTI';
                        value = 'Africa/Djibouti';

                                            };
                }
                            static get AFRICA_DOUALA() {
                    return new class Timezone {
                        id = 'AFRICA_DOUALA';
                        value = 'Africa/Douala';

                                            };
                }
                            static get AFRICA_EL_AAIUN() {
                    return new class Timezone {
                        id = 'AFRICA_EL_AAIUN';
                        value = 'Africa/El_Aaiun';

                                            };
                }
                            static get AFRICA_FREETOWN() {
                    return new class Timezone {
                        id = 'AFRICA_FREETOWN';
                        value = 'Africa/Freetown';

                                            };
                }
                            static get AFRICA_GABORONE() {
                    return new class Timezone {
                        id = 'AFRICA_GABORONE';
                        value = 'Africa/Gaborone';

                                            };
                }
                            static get AFRICA_HARARE() {
                    return new class Timezone {
                        id = 'AFRICA_HARARE';
                        value = 'Africa/Harare';

                                            };
                }
                            static get AFRICA_JOHANNESBURG() {
                    return new class Timezone {
                        id = 'AFRICA_JOHANNESBURG';
                        value = 'Africa/Johannesburg';

                                            };
                }
                            static get AFRICA_JUBA() {
                    return new class Timezone {
                        id = 'AFRICA_JUBA';
                        value = 'Africa/Juba';

                                            };
                }
                            static get AFRICA_KAMPALA() {
                    return new class Timezone {
                        id = 'AFRICA_KAMPALA';
                        value = 'Africa/Kampala';

                                            };
                }
                            static get AFRICA_KHARTOUM() {
                    return new class Timezone {
                        id = 'AFRICA_KHARTOUM';
                        value = 'Africa/Khartoum';

                                            };
                }
                            static get AFRICA_KIGALI() {
                    return new class Timezone {
                        id = 'AFRICA_KIGALI';
                        value = 'Africa/Kigali';

                                            };
                }
                            static get AFRICA_KINSHASA() {
                    return new class Timezone {
                        id = 'AFRICA_KINSHASA';
                        value = 'Africa/Kinshasa';

                                            };
                }
                            static get AFRICA_LAGOS() {
                    return new class Timezone {
                        id = 'AFRICA_LAGOS';
                        value = 'Africa/Lagos';

                                            };
                }
                            static get AFRICA_LIBREVILLE() {
                    return new class Timezone {
                        id = 'AFRICA_LIBREVILLE';
                        value = 'Africa/Libreville';

                                            };
                }
                            static get AFRICA_LOME() {
                    return new class Timezone {
                        id = 'AFRICA_LOME';
                        value = 'Africa/Lome';

                                            };
                }
                            static get AFRICA_LUANDA() {
                    return new class Timezone {
                        id = 'AFRICA_LUANDA';
                        value = 'Africa/Luanda';

                                            };
                }
                            static get AFRICA_LUBUMBASHI() {
                    return new class Timezone {
                        id = 'AFRICA_LUBUMBASHI';
                        value = 'Africa/Lubumbashi';

                                            };
                }
                            static get AFRICA_LUSAKA() {
                    return new class Timezone {
                        id = 'AFRICA_LUSAKA';
                        value = 'Africa/Lusaka';

                                            };
                }
                            static get AFRICA_MALABO() {
                    return new class Timezone {
                        id = 'AFRICA_MALABO';
                        value = 'Africa/Malabo';

                                            };
                }
                            static get AFRICA_MAPUTO() {
                    return new class Timezone {
                        id = 'AFRICA_MAPUTO';
                        value = 'Africa/Maputo';

                                            };
                }
                            static get AFRICA_MASERU() {
                    return new class Timezone {
                        id = 'AFRICA_MASERU';
                        value = 'Africa/Maseru';

                                            };
                }
                            static get AFRICA_MBABANE() {
                    return new class Timezone {
                        id = 'AFRICA_MBABANE';
                        value = 'Africa/Mbabane';

                                            };
                }
                            static get AFRICA_MOGADISHU() {
                    return new class Timezone {
                        id = 'AFRICA_MOGADISHU';
                        value = 'Africa/Mogadishu';

                                            };
                }
                            static get AFRICA_MONROVIA() {
                    return new class Timezone {
                        id = 'AFRICA_MONROVIA';
                        value = 'Africa/Monrovia';

                                            };
                }
                            static get AFRICA_NAIROBI() {
                    return new class Timezone {
                        id = 'AFRICA_NAIROBI';
                        value = 'Africa/Nairobi';

                                            };
                }
                            static get AFRICA_NDJAMENA() {
                    return new class Timezone {
                        id = 'AFRICA_NDJAMENA';
                        value = 'Africa/Ndjamena';

                                            };
                }
                            static get AFRICA_NIAMEY() {
                    return new class Timezone {
                        id = 'AFRICA_NIAMEY';
                        value = 'Africa/Niamey';

                                            };
                }
                            static get AFRICA_NOUAKCHOTT() {
                    return new class Timezone {
                        id = 'AFRICA_NOUAKCHOTT';
                        value = 'Africa/Nouakchott';

                                            };
                }
                            static get AFRICA_OUAGADOUGOU() {
                    return new class Timezone {
                        id = 'AFRICA_OUAGADOUGOU';
                        value = 'Africa/Ouagadougou';

                                            };
                }
                            static get AFRICA_PORTO_NOVO() {
                    return new class Timezone {
                        id = 'AFRICA_PORTO_NOVO';
                        value = 'Africa/Porto-Novo';

                                            };
                }
                            static get AFRICA_SAO_TOME() {
                    return new class Timezone {
                        id = 'AFRICA_SAO_TOME';
                        value = 'Africa/Sao_Tome';

                                            };
                }
                            static get AFRICA_TRIPOLI() {
                    return new class Timezone {
                        id = 'AFRICA_TRIPOLI';
                        value = 'Africa/Tripoli';

                                            };
                }
                            static get AFRICA_TUNIS() {
                    return new class Timezone {
                        id = 'AFRICA_TUNIS';
                        value = 'Africa/Tunis';

                                            };
                }
                            static get AFRICA_WINDHOEK() {
                    return new class Timezone {
                        id = 'AFRICA_WINDHOEK';
                        value = 'Africa/Windhoek';

                                            };
                }
                            static get ANTARCTICA_CASEY() {
                    return new class Timezone {
                        id = 'ANTARCTICA_CASEY';
                        value = 'Antarctica/Casey';

                                            };
                }
                            static get ANTARCTICA_DAVIS() {
                    return new class Timezone {
                        id = 'ANTARCTICA_DAVIS';
                        value = 'Antarctica/Davis';

                                            };
                }
                            static get ANTARCTICA_DUMONTDURVILLE() {
                    return new class Timezone {
                        id = 'ANTARCTICA_DUMONTDURVILLE';
                        value = 'Antarctica/DumontDUrville';

                                            };
                }
                            static get ANTARCTICA_MACQUARIE() {
                    return new class Timezone {
                        id = 'ANTARCTICA_MACQUARIE';
                        value = 'Antarctica/Macquarie';

                                            };
                }
                            static get ANTARCTICA_MAWSON() {
                    return new class Timezone {
                        id = 'ANTARCTICA_MAWSON';
                        value = 'Antarctica/Mawson';

                                            };
                }
                            static get ANTARCTICA_MCMURDO() {
                    return new class Timezone {
                        id = 'ANTARCTICA_MCMURDO';
                        value = 'Antarctica/McMurdo';

                                            };
                }
                            static get ANTARCTICA_PALMER() {
                    return new class Timezone {
                        id = 'ANTARCTICA_PALMER';
                        value = 'Antarctica/Palmer';

                                            };
                }
                            static get ANTARCTICA_ROTHERA() {
                    return new class Timezone {
                        id = 'ANTARCTICA_ROTHERA';
                        value = 'Antarctica/Rothera';

                                            };
                }
                            static get ANTARCTICA_SYOWA() {
                    return new class Timezone {
                        id = 'ANTARCTICA_SYOWA';
                        value = 'Antarctica/Syowa';

                                            };
                }
                            static get ANTARCTICA_TROLL() {
                    return new class Timezone {
                        id = 'ANTARCTICA_TROLL';
                        value = 'Antarctica/Troll';

                                            };
                }
                            static get ANTARCTICA_VOSTOK() {
                    return new class Timezone {
                        id = 'ANTARCTICA_VOSTOK';
                        value = 'Antarctica/Vostok';

                                            };
                }
                            static get ARCTIC_LONGYEARBYEN() {
                    return new class Timezone {
                        id = 'ARCTIC_LONGYEARBYEN';
                        value = 'Arctic/Longyearbyen';

                                            };
                }
                            static get ASIA_ADEN() {
                    return new class Timezone {
                        id = 'ASIA_ADEN';
                        value = 'Asia/Aden';

                                            };
                }
                            static get ASIA_ALMATY() {
                    return new class Timezone {
                        id = 'ASIA_ALMATY';
                        value = 'Asia/Almaty';

                                            };
                }
                            static get ASIA_AMMAN() {
                    return new class Timezone {
                        id = 'ASIA_AMMAN';
                        value = 'Asia/Amman';

                                            };
                }
                            static get ASIA_ANADYR() {
                    return new class Timezone {
                        id = 'ASIA_ANADYR';
                        value = 'Asia/Anadyr';

                                            };
                }
                            static get ASIA_AQTAU() {
                    return new class Timezone {
                        id = 'ASIA_AQTAU';
                        value = 'Asia/Aqtau';

                                            };
                }
                            static get ASIA_AQTOBE() {
                    return new class Timezone {
                        id = 'ASIA_AQTOBE';
                        value = 'Asia/Aqtobe';

                                            };
                }
                            static get ASIA_ASHGABAT() {
                    return new class Timezone {
                        id = 'ASIA_ASHGABAT';
                        value = 'Asia/Ashgabat';

                                            };
                }
                            static get ASIA_ATYRAU() {
                    return new class Timezone {
                        id = 'ASIA_ATYRAU';
                        value = 'Asia/Atyrau';

                                            };
                }
                            static get ASIA_BAGHDAD() {
                    return new class Timezone {
                        id = 'ASIA_BAGHDAD';
                        value = 'Asia/Baghdad';

                                            };
                }
                            static get ASIA_BAHRAIN() {
                    return new class Timezone {
                        id = 'ASIA_BAHRAIN';
                        value = 'Asia/Bahrain';

                                            };
                }
                            static get ASIA_BAKU() {
                    return new class Timezone {
                        id = 'ASIA_BAKU';
                        value = 'Asia/Baku';

                                            };
                }
                            static get ASIA_BANGKOK() {
                    return new class Timezone {
                        id = 'ASIA_BANGKOK';
                        value = 'Asia/Bangkok';

                                            };
                }
                            static get ASIA_BARNAUL() {
                    return new class Timezone {
                        id = 'ASIA_BARNAUL';
                        value = 'Asia/Barnaul';

                                            };
                }
                            static get ASIA_BEIRUT() {
                    return new class Timezone {
                        id = 'ASIA_BEIRUT';
                        value = 'Asia/Beirut';

                                            };
                }
                            static get ASIA_BISHKEK() {
                    return new class Timezone {
                        id = 'ASIA_BISHKEK';
                        value = 'Asia/Bishkek';

                                            };
                }
                            static get ASIA_BRUNEI() {
                    return new class Timezone {
                        id = 'ASIA_BRUNEI';
                        value = 'Asia/Brunei';

                                            };
                }
                            static get ASIA_CHITA() {
                    return new class Timezone {
                        id = 'ASIA_CHITA';
                        value = 'Asia/Chita';

                                            };
                }
                            static get ASIA_CHOIBALSAN() {
                    return new class Timezone {
                        id = 'ASIA_CHOIBALSAN';
                        value = 'Asia/Choibalsan';

                                            };
                }
                            static get ASIA_COLOMBO() {
                    return new class Timezone {
                        id = 'ASIA_COLOMBO';
                        value = 'Asia/Colombo';

                                            };
                }
                            static get ASIA_DAMASCUS() {
                    return new class Timezone {
                        id = 'ASIA_DAMASCUS';
                        value = 'Asia/Damascus';

                                            };
                }
                            static get ASIA_DHAKA() {
                    return new class Timezone {
                        id = 'ASIA_DHAKA';
                        value = 'Asia/Dhaka';

                                            };
                }
                            static get ASIA_DILI() {
                    return new class Timezone {
                        id = 'ASIA_DILI';
                        value = 'Asia/Dili';

                                            };
                }
                            static get ASIA_DUBAI() {
                    return new class Timezone {
                        id = 'ASIA_DUBAI';
                        value = 'Asia/Dubai';

                                            };
                }
                            static get ASIA_DUSHANBE() {
                    return new class Timezone {
                        id = 'ASIA_DUSHANBE';
                        value = 'Asia/Dushanbe';

                                            };
                }
                            static get ASIA_FAMAGUSTA() {
                    return new class Timezone {
                        id = 'ASIA_FAMAGUSTA';
                        value = 'Asia/Famagusta';

                                            };
                }
                            static get ASIA_GAZA() {
                    return new class Timezone {
                        id = 'ASIA_GAZA';
                        value = 'Asia/Gaza';

                                            };
                }
                            static get ASIA_HEBRON() {
                    return new class Timezone {
                        id = 'ASIA_HEBRON';
                        value = 'Asia/Hebron';

                                            };
                }
                            static get ASIA_HO_CHI_MINH() {
                    return new class Timezone {
                        id = 'ASIA_HO_CHI_MINH';
                        value = 'Asia/Ho_Chi_Minh';

                                            };
                }
                            static get ASIA_HONG_KONG() {
                    return new class Timezone {
                        id = 'ASIA_HONG_KONG';
                        value = 'Asia/Hong_Kong';

                                            };
                }
                            static get ASIA_HOVD() {
                    return new class Timezone {
                        id = 'ASIA_HOVD';
                        value = 'Asia/Hovd';

                                            };
                }
                            static get ASIA_IRKUTSK() {
                    return new class Timezone {
                        id = 'ASIA_IRKUTSK';
                        value = 'Asia/Irkutsk';

                                            };
                }
                            static get ASIA_JAKARTA() {
                    return new class Timezone {
                        id = 'ASIA_JAKARTA';
                        value = 'Asia/Jakarta';

                                            };
                }
                            static get ASIA_JAYAPURA() {
                    return new class Timezone {
                        id = 'ASIA_JAYAPURA';
                        value = 'Asia/Jayapura';

                                            };
                }
                            static get ASIA_JERUSALEM() {
                    return new class Timezone {
                        id = 'ASIA_JERUSALEM';
                        value = 'Asia/Jerusalem';

                                            };
                }
                            static get ASIA_KABUL() {
                    return new class Timezone {
                        id = 'ASIA_KABUL';
                        value = 'Asia/Kabul';

                                            };
                }
                            static get ASIA_KAMCHATKA() {
                    return new class Timezone {
                        id = 'ASIA_KAMCHATKA';
                        value = 'Asia/Kamchatka';

                                            };
                }
                            static get ASIA_KARACHI() {
                    return new class Timezone {
                        id = 'ASIA_KARACHI';
                        value = 'Asia/Karachi';

                                            };
                }
                            static get ASIA_KATHMANDU() {
                    return new class Timezone {
                        id = 'ASIA_KATHMANDU';
                        value = 'Asia/Kathmandu';

                                            };
                }
                            static get ASIA_KHANDYGA() {
                    return new class Timezone {
                        id = 'ASIA_KHANDYGA';
                        value = 'Asia/Khandyga';

                                            };
                }
                            static get ASIA_KOLKATA() {
                    return new class Timezone {
                        id = 'ASIA_KOLKATA';
                        value = 'Asia/Kolkata';

                                            };
                }
                            static get ASIA_KRASNOYARSK() {
                    return new class Timezone {
                        id = 'ASIA_KRASNOYARSK';
                        value = 'Asia/Krasnoyarsk';

                                            };
                }
                            static get ASIA_KUALA_LUMPUR() {
                    return new class Timezone {
                        id = 'ASIA_KUALA_LUMPUR';
                        value = 'Asia/Kuala_Lumpur';

                                            };
                }
                            static get ASIA_KUCHING() {
                    return new class Timezone {
                        id = 'ASIA_KUCHING';
                        value = 'Asia/Kuching';

                                            };
                }
                            static get ASIA_KUWAIT() {
                    return new class Timezone {
                        id = 'ASIA_KUWAIT';
                        value = 'Asia/Kuwait';

                                            };
                }
                            static get ASIA_MACAU() {
                    return new class Timezone {
                        id = 'ASIA_MACAU';
                        value = 'Asia/Macau';

                                            };
                }
                            static get ASIA_MAGADAN() {
                    return new class Timezone {
                        id = 'ASIA_MAGADAN';
                        value = 'Asia/Magadan';

                                            };
                }
                            static get ASIA_MAKASSAR() {
                    return new class Timezone {
                        id = 'ASIA_MAKASSAR';
                        value = 'Asia/Makassar';

                                            };
                }
                            static get ASIA_MANILA() {
                    return new class Timezone {
                        id = 'ASIA_MANILA';
                        value = 'Asia/Manila';

                                            };
                }
                            static get ASIA_MUSCAT() {
                    return new class Timezone {
                        id = 'ASIA_MUSCAT';
                        value = 'Asia/Muscat';

                                            };
                }
                            static get ASIA_NICOSIA() {
                    return new class Timezone {
                        id = 'ASIA_NICOSIA';
                        value = 'Asia/Nicosia';

                                            };
                }
                            static get ASIA_NOVOKUZNETSK() {
                    return new class Timezone {
                        id = 'ASIA_NOVOKUZNETSK';
                        value = 'Asia/Novokuznetsk';

                                            };
                }
                            static get ASIA_NOVOSIBIRSK() {
                    return new class Timezone {
                        id = 'ASIA_NOVOSIBIRSK';
                        value = 'Asia/Novosibirsk';

                                            };
                }
                            static get ASIA_OMSK() {
                    return new class Timezone {
                        id = 'ASIA_OMSK';
                        value = 'Asia/Omsk';

                                            };
                }
                            static get ASIA_ORAL() {
                    return new class Timezone {
                        id = 'ASIA_ORAL';
                        value = 'Asia/Oral';

                                            };
                }
                            static get ASIA_PHNOM_PENH() {
                    return new class Timezone {
                        id = 'ASIA_PHNOM_PENH';
                        value = 'Asia/Phnom_Penh';

                                            };
                }
                            static get ASIA_PONTIANAK() {
                    return new class Timezone {
                        id = 'ASIA_PONTIANAK';
                        value = 'Asia/Pontianak';

                                            };
                }
                            static get ASIA_PYONGYANG() {
                    return new class Timezone {
                        id = 'ASIA_PYONGYANG';
                        value = 'Asia/Pyongyang';

                                            };
                }
                            static get ASIA_QATAR() {
                    return new class Timezone {
                        id = 'ASIA_QATAR';
                        value = 'Asia/Qatar';

                                            };
                }
                            static get ASIA_QOSTANAY() {
                    return new class Timezone {
                        id = 'ASIA_QOSTANAY';
                        value = 'Asia/Qostanay';

                                            };
                }
                            static get ASIA_QYZYLORDA() {
                    return new class Timezone {
                        id = 'ASIA_QYZYLORDA';
                        value = 'Asia/Qyzylorda';

                                            };
                }
                            static get ASIA_RIYADH() {
                    return new class Timezone {
                        id = 'ASIA_RIYADH';
                        value = 'Asia/Riyadh';

                                            };
                }
                            static get ASIA_SAKHALIN() {
                    return new class Timezone {
                        id = 'ASIA_SAKHALIN';
                        value = 'Asia/Sakhalin';

                                            };
                }
                            static get ASIA_SAMARKAND() {
                    return new class Timezone {
                        id = 'ASIA_SAMARKAND';
                        value = 'Asia/Samarkand';

                                            };
                }
                            static get ASIA_SEOUL() {
                    return new class Timezone {
                        id = 'ASIA_SEOUL';
                        value = 'Asia/Seoul';

                                            };
                }
                            static get ASIA_SHANGHAI() {
                    return new class Timezone {
                        id = 'ASIA_SHANGHAI';
                        value = 'Asia/Shanghai';

                                            };
                }
                            static get ASIA_SINGAPORE() {
                    return new class Timezone {
                        id = 'ASIA_SINGAPORE';
                        value = 'Asia/Singapore';

                                            };
                }
                            static get ASIA_SREDNEKOLYMSK() {
                    return new class Timezone {
                        id = 'ASIA_SREDNEKOLYMSK';
                        value = 'Asia/Srednekolymsk';

                                            };
                }
                            static get ASIA_TAIPEI() {
                    return new class Timezone {
                        id = 'ASIA_TAIPEI';
                        value = 'Asia/Taipei';

                                            };
                }
                            static get ASIA_TASHKENT() {
                    return new class Timezone {
                        id = 'ASIA_TASHKENT';
                        value = 'Asia/Tashkent';

                                            };
                }
                            static get ASIA_TBILISI() {
                    return new class Timezone {
                        id = 'ASIA_TBILISI';
                        value = 'Asia/Tbilisi';

                                            };
                }
                            static get ASIA_TEHRAN() {
                    return new class Timezone {
                        id = 'ASIA_TEHRAN';
                        value = 'Asia/Tehran';

                                            };
                }
                            static get ASIA_THIMPHU() {
                    return new class Timezone {
                        id = 'ASIA_THIMPHU';
                        value = 'Asia/Thimphu';

                                            };
                }
                            static get ASIA_TOKYO() {
                    return new class Timezone {
                        id = 'ASIA_TOKYO';
                        value = 'Asia/Tokyo';

                                            };
                }
                            static get ASIA_TOMSK() {
                    return new class Timezone {
                        id = 'ASIA_TOMSK';
                        value = 'Asia/Tomsk';

                                            };
                }
                            static get ASIA_ULAANBAATAR() {
                    return new class Timezone {
                        id = 'ASIA_ULAANBAATAR';
                        value = 'Asia/Ulaanbaatar';

                                            };
                }
                            static get ASIA_URUMQI() {
                    return new class Timezone {
                        id = 'ASIA_URUMQI';
                        value = 'Asia/Urumqi';

                                            };
                }
                            static get ASIA_UST_NERA() {
                    return new class Timezone {
                        id = 'ASIA_UST_NERA';
                        value = 'Asia/Ust-Nera';

                                            };
                }
                            static get ASIA_VIENTIANE() {
                    return new class Timezone {
                        id = 'ASIA_VIENTIANE';
                        value = 'Asia/Vientiane';

                                            };
                }
                            static get ASIA_VLADIVOSTOK() {
                    return new class Timezone {
                        id = 'ASIA_VLADIVOSTOK';
                        value = 'Asia/Vladivostok';

                                            };
                }
                            static get ASIA_YAKUTSK() {
                    return new class Timezone {
                        id = 'ASIA_YAKUTSK';
                        value = 'Asia/Yakutsk';

                                            };
                }
                            static get ASIA_YANGON() {
                    return new class Timezone {
                        id = 'ASIA_YANGON';
                        value = 'Asia/Yangon';

                                            };
                }
                            static get ASIA_YEKATERINBURG() {
                    return new class Timezone {
                        id = 'ASIA_YEKATERINBURG';
                        value = 'Asia/Yekaterinburg';

                                            };
                }
                            static get ASIA_YEREVAN() {
                    return new class Timezone {
                        id = 'ASIA_YEREVAN';
                        value = 'Asia/Yerevan';

                                            };
                }
                            static get ATLANTIC_AZORES() {
                    return new class Timezone {
                        id = 'ATLANTIC_AZORES';
                        value = 'Atlantic/Azores';

                                            };
                }
                            static get ATLANTIC_BERMUDA() {
                    return new class Timezone {
                        id = 'ATLANTIC_BERMUDA';
                        value = 'Atlantic/Bermuda';

                                            };
                }
                            static get ATLANTIC_CANARY() {
                    return new class Timezone {
                        id = 'ATLANTIC_CANARY';
                        value = 'Atlantic/Canary';

                                            };
                }
                            static get ATLANTIC_CAPE_VERDE() {
                    return new class Timezone {
                        id = 'ATLANTIC_CAPE_VERDE';
                        value = 'Atlantic/Cape_Verde';

                                            };
                }
                            static get ATLANTIC_FAROE() {
                    return new class Timezone {
                        id = 'ATLANTIC_FAROE';
                        value = 'Atlantic/Faroe';

                                            };
                }
                            static get ATLANTIC_MADEIRA() {
                    return new class Timezone {
                        id = 'ATLANTIC_MADEIRA';
                        value = 'Atlantic/Madeira';

                                            };
                }
                            static get ATLANTIC_REYKJAVIK() {
                    return new class Timezone {
                        id = 'ATLANTIC_REYKJAVIK';
                        value = 'Atlantic/Reykjavik';

                                            };
                }
                            static get ATLANTIC_SOUTH_GEORGIA() {
                    return new class Timezone {
                        id = 'ATLANTIC_SOUTH_GEORGIA';
                        value = 'Atlantic/South_Georgia';

                                            };
                }
                            static get ATLANTIC_ST_HELENA() {
                    return new class Timezone {
                        id = 'ATLANTIC_ST_HELENA';
                        value = 'Atlantic/St_Helena';

                                            };
                }
                            static get ATLANTIC_STANLEY() {
                    return new class Timezone {
                        id = 'ATLANTIC_STANLEY';
                        value = 'Atlantic/Stanley';

                                            };
                }
                            static get AUSTRALIA_ADELAIDE() {
                    return new class Timezone {
                        id = 'AUSTRALIA_ADELAIDE';
                        value = 'Australia/Adelaide';

                                            };
                }
                            static get AUSTRALIA_BRISBANE() {
                    return new class Timezone {
                        id = 'AUSTRALIA_BRISBANE';
                        value = 'Australia/Brisbane';

                                            };
                }
                            static get AUSTRALIA_BROKEN_HILL() {
                    return new class Timezone {
                        id = 'AUSTRALIA_BROKEN_HILL';
                        value = 'Australia/Broken_Hill';

                                            };
                }
                            static get AUSTRALIA_DARWIN() {
                    return new class Timezone {
                        id = 'AUSTRALIA_DARWIN';
                        value = 'Australia/Darwin';

                                            };
                }
                            static get AUSTRALIA_EUCLA() {
                    return new class Timezone {
                        id = 'AUSTRALIA_EUCLA';
                        value = 'Australia/Eucla';

                                            };
                }
                            static get AUSTRALIA_HOBART() {
                    return new class Timezone {
                        id = 'AUSTRALIA_HOBART';
                        value = 'Australia/Hobart';

                                            };
                }
                            static get AUSTRALIA_LINDEMAN() {
                    return new class Timezone {
                        id = 'AUSTRALIA_LINDEMAN';
                        value = 'Australia/Lindeman';

                                            };
                }
                            static get AUSTRALIA_LORD_HOWE() {
                    return new class Timezone {
                        id = 'AUSTRALIA_LORD_HOWE';
                        value = 'Australia/Lord_Howe';

                                            };
                }
                            static get AUSTRALIA_MELBOURNE() {
                    return new class Timezone {
                        id = 'AUSTRALIA_MELBOURNE';
                        value = 'Australia/Melbourne';

                                            };
                }
                            static get AUSTRALIA_PERTH() {
                    return new class Timezone {
                        id = 'AUSTRALIA_PERTH';
                        value = 'Australia/Perth';

                                            };
                }
                            static get AUSTRALIA_SYDNEY() {
                    return new class Timezone {
                        id = 'AUSTRALIA_SYDNEY';
                        value = 'Australia/Sydney';

                                            };
                }
                            static get EUROPE_AMSTERDAM() {
                    return new class Timezone {
                        id = 'EUROPE_AMSTERDAM';
                        value = 'Europe/Amsterdam';

                                            };
                }
                            static get EUROPE_ANDORRA() {
                    return new class Timezone {
                        id = 'EUROPE_ANDORRA';
                        value = 'Europe/Andorra';

                                            };
                }
                            static get EUROPE_ASTRAKHAN() {
                    return new class Timezone {
                        id = 'EUROPE_ASTRAKHAN';
                        value = 'Europe/Astrakhan';

                                            };
                }
                            static get EUROPE_ATHENS() {
                    return new class Timezone {
                        id = 'EUROPE_ATHENS';
                        value = 'Europe/Athens';

                                            };
                }
                            static get EUROPE_BELGRADE() {
                    return new class Timezone {
                        id = 'EUROPE_BELGRADE';
                        value = 'Europe/Belgrade';

                                            };
                }
                            static get EUROPE_BERLIN() {
                    return new class Timezone {
                        id = 'EUROPE_BERLIN';
                        value = 'Europe/Berlin';

                                            };
                }
                            static get EUROPE_BRATISLAVA() {
                    return new class Timezone {
                        id = 'EUROPE_BRATISLAVA';
                        value = 'Europe/Bratislava';

                                            };
                }
                            static get EUROPE_BRUSSELS() {
                    return new class Timezone {
                        id = 'EUROPE_BRUSSELS';
                        value = 'Europe/Brussels';

                                            };
                }
                            static get EUROPE_BUCHAREST() {
                    return new class Timezone {
                        id = 'EUROPE_BUCHAREST';
                        value = 'Europe/Bucharest';

                                            };
                }
                            static get EUROPE_BUDAPEST() {
                    return new class Timezone {
                        id = 'EUROPE_BUDAPEST';
                        value = 'Europe/Budapest';

                                            };
                }
                            static get EUROPE_BUSINGEN() {
                    return new class Timezone {
                        id = 'EUROPE_BUSINGEN';
                        value = 'Europe/Busingen';

                                            };
                }
                            static get EUROPE_CHISINAU() {
                    return new class Timezone {
                        id = 'EUROPE_CHISINAU';
                        value = 'Europe/Chisinau';

                                            };
                }
                            static get EUROPE_COPENHAGEN() {
                    return new class Timezone {
                        id = 'EUROPE_COPENHAGEN';
                        value = 'Europe/Copenhagen';

                                            };
                }
                            static get EUROPE_DUBLIN() {
                    return new class Timezone {
                        id = 'EUROPE_DUBLIN';
                        value = 'Europe/Dublin';

                                            };
                }
                            static get EUROPE_GIBRALTAR() {
                    return new class Timezone {
                        id = 'EUROPE_GIBRALTAR';
                        value = 'Europe/Gibraltar';

                                            };
                }
                            static get EUROPE_GUERNSEY() {
                    return new class Timezone {
                        id = 'EUROPE_GUERNSEY';
                        value = 'Europe/Guernsey';

                                            };
                }
                            static get EUROPE_HELSINKI() {
                    return new class Timezone {
                        id = 'EUROPE_HELSINKI';
                        value = 'Europe/Helsinki';

                                            };
                }
                            static get EUROPE_ISLE_OF_MAN() {
                    return new class Timezone {
                        id = 'EUROPE_ISLE_OF_MAN';
                        value = 'Europe/Isle_of_Man';

                                            };
                }
                            static get EUROPE_ISTANBUL() {
                    return new class Timezone {
                        id = 'EUROPE_ISTANBUL';
                        value = 'Europe/Istanbul';

                                            };
                }
                            static get EUROPE_JERSEY() {
                    return new class Timezone {
                        id = 'EUROPE_JERSEY';
                        value = 'Europe/Jersey';

                                            };
                }
                            static get EUROPE_KALININGRAD() {
                    return new class Timezone {
                        id = 'EUROPE_KALININGRAD';
                        value = 'Europe/Kaliningrad';

                                            };
                }
                            static get EUROPE_KIROV() {
                    return new class Timezone {
                        id = 'EUROPE_KIROV';
                        value = 'Europe/Kirov';

                                            };
                }
                            static get EUROPE_KYIV() {
                    return new class Timezone {
                        id = 'EUROPE_KYIV';
                        value = 'Europe/Kyiv';

                                            };
                }
                            static get EUROPE_LISBON() {
                    return new class Timezone {
                        id = 'EUROPE_LISBON';
                        value = 'Europe/Lisbon';

                                            };
                }
                            static get EUROPE_LJUBLJANA() {
                    return new class Timezone {
                        id = 'EUROPE_LJUBLJANA';
                        value = 'Europe/Ljubljana';

                                            };
                }
                            static get EUROPE_LONDON() {
                    return new class Timezone {
                        id = 'EUROPE_LONDON';
                        value = 'Europe/London';

                                            };
                }
                            static get EUROPE_LUXEMBOURG() {
                    return new class Timezone {
                        id = 'EUROPE_LUXEMBOURG';
                        value = 'Europe/Luxembourg';

                                            };
                }
                            static get EUROPE_MADRID() {
                    return new class Timezone {
                        id = 'EUROPE_MADRID';
                        value = 'Europe/Madrid';

                                            };
                }
                            static get EUROPE_MALTA() {
                    return new class Timezone {
                        id = 'EUROPE_MALTA';
                        value = 'Europe/Malta';

                                            };
                }
                            static get EUROPE_MARIEHAMN() {
                    return new class Timezone {
                        id = 'EUROPE_MARIEHAMN';
                        value = 'Europe/Mariehamn';

                                            };
                }
                            static get EUROPE_MINSK() {
                    return new class Timezone {
                        id = 'EUROPE_MINSK';
                        value = 'Europe/Minsk';

                                            };
                }
                            static get EUROPE_MONACO() {
                    return new class Timezone {
                        id = 'EUROPE_MONACO';
                        value = 'Europe/Monaco';

                                            };
                }
                            static get EUROPE_MOSCOW() {
                    return new class Timezone {
                        id = 'EUROPE_MOSCOW';
                        value = 'Europe/Moscow';

                                            };
                }
                            static get EUROPE_OSLO() {
                    return new class Timezone {
                        id = 'EUROPE_OSLO';
                        value = 'Europe/Oslo';

                                            };
                }
                            static get EUROPE_PARIS() {
                    return new class Timezone {
                        id = 'EUROPE_PARIS';
                        value = 'Europe/Paris';

                                            };
                }
                            static get EUROPE_PODGORICA() {
                    return new class Timezone {
                        id = 'EUROPE_PODGORICA';
                        value = 'Europe/Podgorica';

                                            };
                }
                            static get EUROPE_PRAGUE() {
                    return new class Timezone {
                        id = 'EUROPE_PRAGUE';
                        value = 'Europe/Prague';

                                            };
                }
                            static get EUROPE_RIGA() {
                    return new class Timezone {
                        id = 'EUROPE_RIGA';
                        value = 'Europe/Riga';

                                            };
                }
                            static get EUROPE_ROME() {
                    return new class Timezone {
                        id = 'EUROPE_ROME';
                        value = 'Europe/Rome';

                                            };
                }
                            static get EUROPE_SAMARA() {
                    return new class Timezone {
                        id = 'EUROPE_SAMARA';
                        value = 'Europe/Samara';

                                            };
                }
                            static get EUROPE_SAN_MARINO() {
                    return new class Timezone {
                        id = 'EUROPE_SAN_MARINO';
                        value = 'Europe/San_Marino';

                                            };
                }
                            static get EUROPE_SARAJEVO() {
                    return new class Timezone {
                        id = 'EUROPE_SARAJEVO';
                        value = 'Europe/Sarajevo';

                                            };
                }
                            static get EUROPE_SARATOV() {
                    return new class Timezone {
                        id = 'EUROPE_SARATOV';
                        value = 'Europe/Saratov';

                                            };
                }
                            static get EUROPE_SIMFEROPOL() {
                    return new class Timezone {
                        id = 'EUROPE_SIMFEROPOL';
                        value = 'Europe/Simferopol';

                                            };
                }
                            static get EUROPE_SKOPJE() {
                    return new class Timezone {
                        id = 'EUROPE_SKOPJE';
                        value = 'Europe/Skopje';

                                            };
                }
                            static get EUROPE_SOFIA() {
                    return new class Timezone {
                        id = 'EUROPE_SOFIA';
                        value = 'Europe/Sofia';

                                            };
                }
                            static get EUROPE_STOCKHOLM() {
                    return new class Timezone {
                        id = 'EUROPE_STOCKHOLM';
                        value = 'Europe/Stockholm';

                                            };
                }
                            static get EUROPE_TALLINN() {
                    return new class Timezone {
                        id = 'EUROPE_TALLINN';
                        value = 'Europe/Tallinn';

                                            };
                }
                            static get EUROPE_TIRANE() {
                    return new class Timezone {
                        id = 'EUROPE_TIRANE';
                        value = 'Europe/Tirane';

                                            };
                }
                            static get EUROPE_ULYANOVSK() {
                    return new class Timezone {
                        id = 'EUROPE_ULYANOVSK';
                        value = 'Europe/Ulyanovsk';

                                            };
                }
                            static get EUROPE_VADUZ() {
                    return new class Timezone {
                        id = 'EUROPE_VADUZ';
                        value = 'Europe/Vaduz';

                                            };
                }
                            static get EUROPE_VATICAN() {
                    return new class Timezone {
                        id = 'EUROPE_VATICAN';
                        value = 'Europe/Vatican';

                                            };
                }
                            static get EUROPE_VIENNA() {
                    return new class Timezone {
                        id = 'EUROPE_VIENNA';
                        value = 'Europe/Vienna';

                                            };
                }
                            static get EUROPE_VILNIUS() {
                    return new class Timezone {
                        id = 'EUROPE_VILNIUS';
                        value = 'Europe/Vilnius';

                                            };
                }
                            static get EUROPE_VOLGOGRAD() {
                    return new class Timezone {
                        id = 'EUROPE_VOLGOGRAD';
                        value = 'Europe/Volgograd';

                                            };
                }
                            static get EUROPE_WARSAW() {
                    return new class Timezone {
                        id = 'EUROPE_WARSAW';
                        value = 'Europe/Warsaw';

                                            };
                }
                            static get EUROPE_ZAGREB() {
                    return new class Timezone {
                        id = 'EUROPE_ZAGREB';
                        value = 'Europe/Zagreb';

                                            };
                }
                            static get EUROPE_ZURICH() {
                    return new class Timezone {
                        id = 'EUROPE_ZURICH';
                        value = 'Europe/Zurich';

                                            };
                }
                            static get INDIAN_ANTANANARIVO() {
                    return new class Timezone {
                        id = 'INDIAN_ANTANANARIVO';
                        value = 'Indian/Antananarivo';

                                            };
                }
                            static get INDIAN_CHAGOS() {
                    return new class Timezone {
                        id = 'INDIAN_CHAGOS';
                        value = 'Indian/Chagos';

                                            };
                }
                            static get INDIAN_CHRISTMAS() {
                    return new class Timezone {
                        id = 'INDIAN_CHRISTMAS';
                        value = 'Indian/Christmas';

                                            };
                }
                            static get INDIAN_COCOS() {
                    return new class Timezone {
                        id = 'INDIAN_COCOS';
                        value = 'Indian/Cocos';

                                            };
                }
                            static get INDIAN_COMORO() {
                    return new class Timezone {
                        id = 'INDIAN_COMORO';
                        value = 'Indian/Comoro';

                                            };
                }
                            static get INDIAN_KERGUELEN() {
                    return new class Timezone {
                        id = 'INDIAN_KERGUELEN';
                        value = 'Indian/Kerguelen';

                                            };
                }
                            static get INDIAN_MAHE() {
                    return new class Timezone {
                        id = 'INDIAN_MAHE';
                        value = 'Indian/Mahe';

                                            };
                }
                            static get INDIAN_MALDIVES() {
                    return new class Timezone {
                        id = 'INDIAN_MALDIVES';
                        value = 'Indian/Maldives';

                                            };
                }
                            static get INDIAN_MAURITIUS() {
                    return new class Timezone {
                        id = 'INDIAN_MAURITIUS';
                        value = 'Indian/Mauritius';

                                            };
                }
                            static get INDIAN_MAYOTTE() {
                    return new class Timezone {
                        id = 'INDIAN_MAYOTTE';
                        value = 'Indian/Mayotte';

                                            };
                }
                            static get INDIAN_REUNION() {
                    return new class Timezone {
                        id = 'INDIAN_REUNION';
                        value = 'Indian/Reunion';

                                            };
                }
                            static get PACIFIC_APIA() {
                    return new class Timezone {
                        id = 'PACIFIC_APIA';
                        value = 'Pacific/Apia';

                                            };
                }
                            static get PACIFIC_AUCKLAND() {
                    return new class Timezone {
                        id = 'PACIFIC_AUCKLAND';
                        value = 'Pacific/Auckland';

                                            };
                }
                            static get PACIFIC_BOUGAINVILLE() {
                    return new class Timezone {
                        id = 'PACIFIC_BOUGAINVILLE';
                        value = 'Pacific/Bougainville';

                                            };
                }
                            static get PACIFIC_CHATHAM() {
                    return new class Timezone {
                        id = 'PACIFIC_CHATHAM';
                        value = 'Pacific/Chatham';

                                            };
                }
                            static get PACIFIC_CHUUK() {
                    return new class Timezone {
                        id = 'PACIFIC_CHUUK';
                        value = 'Pacific/Chuuk';

                                            };
                }
                            static get PACIFIC_EASTER() {
                    return new class Timezone {
                        id = 'PACIFIC_EASTER';
                        value = 'Pacific/Easter';

                                            };
                }
                            static get PACIFIC_EFATE() {
                    return new class Timezone {
                        id = 'PACIFIC_EFATE';
                        value = 'Pacific/Efate';

                                            };
                }
                            static get PACIFIC_FAKAOFO() {
                    return new class Timezone {
                        id = 'PACIFIC_FAKAOFO';
                        value = 'Pacific/Fakaofo';

                                            };
                }
                            static get PACIFIC_FIJI() {
                    return new class Timezone {
                        id = 'PACIFIC_FIJI';
                        value = 'Pacific/Fiji';

                                            };
                }
                            static get PACIFIC_FUNAFUTI() {
                    return new class Timezone {
                        id = 'PACIFIC_FUNAFUTI';
                        value = 'Pacific/Funafuti';

                                            };
                }
                            static get PACIFIC_GALAPAGOS() {
                    return new class Timezone {
                        id = 'PACIFIC_GALAPAGOS';
                        value = 'Pacific/Galapagos';

                                            };
                }
                            static get PACIFIC_GAMBIER() {
                    return new class Timezone {
                        id = 'PACIFIC_GAMBIER';
                        value = 'Pacific/Gambier';

                                            };
                }
                            static get PACIFIC_GUADALCANAL() {
                    return new class Timezone {
                        id = 'PACIFIC_GUADALCANAL';
                        value = 'Pacific/Guadalcanal';

                                            };
                }
                            static get PACIFIC_GUAM() {
                    return new class Timezone {
                        id = 'PACIFIC_GUAM';
                        value = 'Pacific/Guam';

                                            };
                }
                            static get PACIFIC_HONOLULU() {
                    return new class Timezone {
                        id = 'PACIFIC_HONOLULU';
                        value = 'Pacific/Honolulu';

                                            };
                }
                            static get PACIFIC_KANTON() {
                    return new class Timezone {
                        id = 'PACIFIC_KANTON';
                        value = 'Pacific/Kanton';

                                            };
                }
                            static get PACIFIC_KIRITIMATI() {
                    return new class Timezone {
                        id = 'PACIFIC_KIRITIMATI';
                        value = 'Pacific/Kiritimati';

                                            };
                }
                            static get PACIFIC_KOSRAE() {
                    return new class Timezone {
                        id = 'PACIFIC_KOSRAE';
                        value = 'Pacific/Kosrae';

                                            };
                }
                            static get PACIFIC_KWAJALEIN() {
                    return new class Timezone {
                        id = 'PACIFIC_KWAJALEIN';
                        value = 'Pacific/Kwajalein';

                                            };
                }
                            static get PACIFIC_MAJURO() {
                    return new class Timezone {
                        id = 'PACIFIC_MAJURO';
                        value = 'Pacific/Majuro';

                                            };
                }
                            static get PACIFIC_MARQUESAS() {
                    return new class Timezone {
                        id = 'PACIFIC_MARQUESAS';
                        value = 'Pacific/Marquesas';

                                            };
                }
                            static get PACIFIC_MIDWAY() {
                    return new class Timezone {
                        id = 'PACIFIC_MIDWAY';
                        value = 'Pacific/Midway';

                                            };
                }
                            static get PACIFIC_NAURU() {
                    return new class Timezone {
                        id = 'PACIFIC_NAURU';
                        value = 'Pacific/Nauru';

                                            };
                }
                            static get PACIFIC_NIUE() {
                    return new class Timezone {
                        id = 'PACIFIC_NIUE';
                        value = 'Pacific/Niue';

                                            };
                }
                            static get PACIFIC_NORFOLK() {
                    return new class Timezone {
                        id = 'PACIFIC_NORFOLK';
                        value = 'Pacific/Norfolk';

                                            };
                }
                            static get PACIFIC_NOUMEA() {
                    return new class Timezone {
                        id = 'PACIFIC_NOUMEA';
                        value = 'Pacific/Noumea';

                                            };
                }
                            static get PACIFIC_PAGO_PAGO() {
                    return new class Timezone {
                        id = 'PACIFIC_PAGO_PAGO';
                        value = 'Pacific/Pago_Pago';

                                            };
                }
                            static get PACIFIC_PALAU() {
                    return new class Timezone {
                        id = 'PACIFIC_PALAU';
                        value = 'Pacific/Palau';

                                            };
                }
                            static get PACIFIC_PITCAIRN() {
                    return new class Timezone {
                        id = 'PACIFIC_PITCAIRN';
                        value = 'Pacific/Pitcairn';

                                            };
                }
                            static get PACIFIC_POHNPEI() {
                    return new class Timezone {
                        id = 'PACIFIC_POHNPEI';
                        value = 'Pacific/Pohnpei';

                                            };
                }
                            static get PACIFIC_PORT_MORESBY() {
                    return new class Timezone {
                        id = 'PACIFIC_PORT_MORESBY';
                        value = 'Pacific/Port_Moresby';

                                            };
                }
                            static get PACIFIC_RAROTONGA() {
                    return new class Timezone {
                        id = 'PACIFIC_RAROTONGA';
                        value = 'Pacific/Rarotonga';

                                            };
                }
                            static get PACIFIC_SAIPAN() {
                    return new class Timezone {
                        id = 'PACIFIC_SAIPAN';
                        value = 'Pacific/Saipan';

                                            };
                }
                            static get PACIFIC_TAHITI() {
                    return new class Timezone {
                        id = 'PACIFIC_TAHITI';
                        value = 'Pacific/Tahiti';

                                            };
                }
                            static get PACIFIC_TARAWA() {
                    return new class Timezone {
                        id = 'PACIFIC_TARAWA';
                        value = 'Pacific/Tarawa';

                                            };
                }
                            static get PACIFIC_TONGATAPU() {
                    return new class Timezone {
                        id = 'PACIFIC_TONGATAPU';
                        value = 'Pacific/Tongatapu';

                                            };
                }
                            static get PACIFIC_WAKE() {
                    return new class Timezone {
                        id = 'PACIFIC_WAKE';
                        value = 'Pacific/Wake';

                                            };
                }
                            static get PACIFIC_WALLIS() {
                    return new class Timezone {
                        id = 'PACIFIC_WALLIS';
                        value = 'Pacific/Wallis';

                                            };
                }
                    };
    