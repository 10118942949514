<script setup>

    import { useClipboard } from '@vueuse/core';
    import { computed } from 'vue';

    const props = defineProps({
        content: String
    });

    const { copy: baseCopy, copied } = useClipboard();

    const copy = async function () {
        await baseCopy(props.content);
    }

    const activatorProps = computed(() => ({
        prependIcon: copied.value ? 'mdi-clipboard-check' : 'mdi-content-copy',
        onClick: copy,
        text: 'Copiar',
    }));

</script>

<template>
    <v-text-field :model-value="content" readonly @click:control="({ target }) => target.select && target.select()">
        <template #append-inner>
            <slot :="{ activatorProps }">
                <v-btn :="activatorProps"></v-btn>
            </slot>
        </template>
    </v-text-field>
</template>
