<script setup>
    import { useWindowScroll } from '@vueuse/core';

    defineOptions({
        inheritAttrs: false
    });

    defineProps({
        height: {
            type: Number,
            default: 200
        },
        right: {
            type: Number,
            default: 30
        },
        bottom: {
            type: Number,
            default: 50
        },
    });

    const { y } = useWindowScroll();

    const activatorProps = {
        onClick: () => scrollTo({ left: 0, top: 0, behavior: 'smooth', }),
        icon: 'mdi-chevron-up'
    };

</script>

<template>
    <v-fade-transition>
        <div v-show="y > parseInt(height)"
            :style="{ position: 'fixed', bottom: parseInt(bottom) + 'px', right: parseInt(right) + 'px', zIndex: 2000 }">
            <slot :="{ activatorProps }">
                <v-btn :="{ ...activatorProps, ...$attrs }"></v-btn>
            </slot>
        </div>
    </v-fade-transition>
</template>
