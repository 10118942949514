
import Footer from "../Vue/Components/Vibeland/Footer.vue";
import Navbar from "../Vue/Components/Vibeland/Navbar.vue";
import NavbarBottom from "../Vue/Components/Vibeland/NavbarBottom.vue";
import NavbarBtn from "../Vue/Components/Vibeland/NavbarBtn.vue";
import NavbarBtnMenu from "../Vue/Components/Vibeland/NavbarBtnMenu.vue";
import NavbarLogo from "../Vue/Components/Vibeland/NavbarLogo.vue";
import NavbarUserMenu from "../Vue/Components/Vibeland/NavbarUserMenu.vue";
import NavbarPricingSubMenu from "../Vue/Components/Vibeland/NavbarPricingSubMenu.vue";
import NavbarSearchMenu from "../Vue/Components/Vibeland/NavbarSearchMenu.vue";

const VibelandComponents = {
    install: function (vueApp) {
        vueApp.component('vibeland-navbar', Navbar);
        vueApp.component('vibeland-navbar-btn', NavbarBtn);
        vueApp.component('vibeland-navbar-btn-menu', NavbarBtnMenu);
        vueApp.component('vibeland-navbar-bottom', NavbarBottom);
        vueApp.component('vibeland-navbar-logo', NavbarLogo);
        vueApp.component('vibeland-navbar-user-menu', NavbarUserMenu);
        vueApp.component('vibeland-navbar-search', NavbarSearchMenu);

        vueApp.component('vibeland-pricing-submenu', NavbarPricingSubMenu);

        vueApp.component('vibeland-footer', Footer);
    }
};

export { VibelandComponents };