
import { createVuetify as vuetifyCreateVuetify } from "vuetify/lib/framework.mjs";

const vibelandColors = {
    'vibeland-yellow': '#ffff4e',
    'vibeland-blue': '#00cfff',
    primary: '#1867c0',
    black: '#000000',

    // surface:'#ffff01',
    // secondary: '#00cfff',
    // background: '#f6f8f9',
    // yellow: '#ffff01'
};

const darkVibelandColors = {
    ...vibelandColors,
    // primary: vibelandColors['vibeland-yellow'],
    primary: vibelandColors['vibeland-blue'],
    error: '#FF1744',
};

const vibelandDefaults = {
    global: {
        ripple: false,
    },
    VBtn: {
        rounded: 'pill',
        variant: 'flat',
    },
    VChip: {
        variant: 'flat',
        color: 'primary'
    },
    VTabs: {
        color: 'primary'
    },
    VTab: {
        rounded: 0,
    },
    VCard: {
        rounded: 'lg',
    },
    VContainer: {
        tag: 'section'
    },


    VTextField: {
        variant: 'solo-filled',
        flat: true,
        color: 'primary'
    },
    VSelect: {
        variant: 'solo-filled',
        flat: true,
        color: 'primary'
    },
    VTextarea: {
        variant: 'solo-filled',
        flat: true,
        color: 'primary'
    },
    VCheckbox: {
        color: 'primary'
    },
    VCombobox: {
        variant: 'solo-filled',
        flat: true,
        color: 'primary'
    },
    VAutocomplete: {
        variant: 'solo-filled',
        color: 'primary',
        flat: true,
    },
    VSwitch: {
        color: 'primary'
    },
    VRadioGroup: {
        color: 'primary'
    },
    VRadio: {
        color: 'primary'
    },
    VSlider: {
        color: 'primary'
    },
    VFileInput: {
        color: 'primary',
        variant: 'solo-filled',
        flat: true,
    },
    VNumberInput: {
        color: 'primary',
        variant: 'solo-filled',
        flat: true,
    },
    VDateInput: {
        color: 'primary',
        variant: 'solo-filled',
        flat: true,
        placeholder: 'Clique para selecionar',
    },
    VDatePicker: {
        color: 'primary',
        rounded: 'lg',
        border: true,
        hideHeader: true
    },
    VTimePicker: {
        color: 'primary',
    },
    VDialog: {
        maxWidth: '100%'
    }
}

const createVuetify = function ({ date, locale }) {
    return vuetifyCreateVuetify({
        defaults: vibelandDefaults,
        theme: {
            themes: {
                light: {
                    colors: {
                        ...vibelandColors
                    },
                },
                dark: {
                    colors: {
                        ...darkVibelandColors,
                    },
                },
            },
            variations: {
                colors: Object.keys(vibelandColors),
                lighten: 5,
                darken: 5
            }
        },
        date,
        locale
    });
};

export { createVuetify };