<script setup>
    import { mergeProps } from 'vue';

    defineOptions({
        inheritAttrs: false,
    });

    const props = defineProps({
        hover: Object
    });

</script>

<template>
    <v-hover #="{ isHovering, props: hoverProps }">
        <v-btn :="mergeProps(hoverProps, $attrs, (isHovering && props?.hover ? props?.hover : undefined))">

            <template v-for="(s, slotName) in $slots" #[slotName]="scope">
                <slot :name="slotName" :="scope || {}"></slot>
            </template>

        </v-btn>


    </v-hover>

</template>