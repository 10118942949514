
// Providers
import { notify } from '../Helpers/Flash';
import apiProvider from '../../js/Helpers/Api';
import routeProvider from '../../js/Helpers/Route';

import SupportComponent from '../Components/Support.vue';
import Env from '../../js/Helpers/Env';
import { isEmail } from '../../js/Helpers/Helpers';

const requiredRule = (value) => {
    if (value === '' || value === undefined || value === null) {
        return 'Campo obrigatório';
    }

    return true;
};

export {
    requiredRule
};

export default class Support {
    window = window;
    route = routeProvider;
    api = undefined;
    locale = undefined;
    notify = notify;
    imgPlaceholder = '/images/placeholder-image.png';
    imgError = '/images/error-image.png';
    // imgLoading = '/images/img-loading.png';
    requiredRule = requiredRule;
    emailRule = (value) => {
        if (!value) {
            return true;
        }

        return isEmail(value) || 'E-mail inválido';
    };

    constructor() {
        this.api = apiProvider();
    }

    install(vueApp, { vuetify, locale }) {
        this.locale = locale;

        vueApp.component('x-support', SupportComponent);

        ['window', 'route', 'notify', 'api', 'locale', 'requiredRule', 'imgPlaceholder', 'imgError', 'emailRule'].forEach((key) => {
            vueApp.config.globalProperties['$' + key] = this[key];
            vueApp.provide(key, this[key]);
        });

        if (Env.DEBUG) {
            vueApp.config.globalProperties.$support = this;
        }

        vueApp.provide('support', this);
    }
}
