
        import Enum from '../Helpers/Enum';

        export default class Language extends Enum {
                            static get PT_BR() {
                    return new class Language {
                        id = 'PT_BR';
                        value = 'pt_BR';

                                                                        name = 'enums.language.pt_BR';
                                            };
                }
                            static get EN_US() {
                    return new class Language {
                        id = 'EN_US';
                        value = 'en_US';

                                                                        name = 'enums.language.en_US';
                                            };
                }
                    };
    