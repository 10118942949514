export default class Enum {
    static ids() {
        return Object.getOwnPropertyNames(this).filter((xPropertyName) => !['length', 'name', 'prototype'].includes(xPropertyName));
    }

    static cases() {
        return this.ids().map((id) => this.tryFromId(id));
    }

    static tryFromId(id) {
        return this[id];
    }

    static valueToCase(value, ids) {
        let xCase = undefined;

        for (let xId of (ids || this.ids())) {
            xCase = this.tryFromId(xId);

            if (xCase.value === value) {
                break;
            }
            else {
                xCase = undefined;
            }
        }

        return xCase;
    }

    static getBy(attr, value) {
        return this.cases().find((xCase) => xCase[attr] === value);
    }

    static tryFrom(values) {
        const multi = Array.isArray(values);
        const ids = this.ids();

        values = (multi ? values : [values]).map((xValue) => this.valueToCase(xValue, ids));

        return multi ? values : values[0];
    }

    static toSelect(translator) {
        return this.cases().map((xCase) => ({
            title: translator(xCase?.name) || xCase.value,
            value: xCase.value
        }));
    }
}
