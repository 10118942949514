
        import Enum from '../Helpers/Enum';

        export default class ZipcodeFormat extends Enum {
                            static get BR() {
                    return new class ZipcodeFormat {
                        id = 'BR';
                        value = 'br';

                                                                        mask = '#####-###';
                                                                        length = 8;
                                            };
                }
                            static get US() {
                    return new class ZipcodeFormat {
                        id = 'US';
                        value = 'us';

                                                                        mask = '#####';
                                                                        length = 5;
                                            };
                }
                    };
    