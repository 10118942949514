<script setup>
import { useForm } from '@inertiajs/vue3';
import { useUrlSearchParams } from '@vueuse/core';

const props = defineProps(['token', 'errors']);

const passwordUpdateForm = useForm({
    token: props.token,
    email: useUrlSearchParams().email,
    password: '',
    password_confirmation: ''
});

</script>

<template>
    <v-container class="pb-15">
        <h2 class="my-10 text-center">Criar nova senha</h2>

        <v-row justify="center">
            <v-col cols="12" lg="4">
                <v-card border flat class="py-5">
                    <v-card-title class="mb-5 text-center">
                        Crie uma nova senha para acessar sua conta
                    </v-card-title>

                    <v-card-text class="px-8">
                        <x-form :action="$route('app-site.user.new-password-submit')" :form="passwordUpdateForm"
                            #="{ activatorProps }">

                            <x-input-password label="Nova senha" class="mb-3" v-model="passwordUpdateForm.password"
                                :rules="[$requiredRule]" :error-messages="errors?.password"
                                autocomplete="new-password"></x-input-password>

                            <x-input-password label="Confirme sua nova senha" class="mb-3"
                                v-model="passwordUpdateForm.password_confirmation"
                                :rules="[$requiredRule].concat((v) => v === passwordUpdateForm.password || 'As senhas devem ser iguais')"
                                :error-messages="errors?.password_confirmation"
                                autocomplete="new-password"></x-input-password>

                            <v-btn :="activatorProps" text="Atualizar senha e ir para o login" block size="large"
                                color="primary" class="mb-3"></v-btn>

                        </x-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
