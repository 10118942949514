
        import Enum from '../Helpers/Enum';

        export default class CountrySubdivision extends Enum {
                            static get BR_AC() {
                    return new class CountrySubdivision {
                        id = 'BR_AC';
                        value = 'BR-AC';

                                                                        name = 'enums.countryUf.br_ac';
                                            };
                }
                            static get BR_AL() {
                    return new class CountrySubdivision {
                        id = 'BR_AL';
                        value = 'BR-AL';

                                                                        name = 'enums.countryUf.br_al';
                                            };
                }
                            static get BR_AP() {
                    return new class CountrySubdivision {
                        id = 'BR_AP';
                        value = 'BR-AP';

                                                                        name = 'enums.countryUf.br_ap';
                                            };
                }
                            static get BR_AM() {
                    return new class CountrySubdivision {
                        id = 'BR_AM';
                        value = 'BR-AM';

                                                                        name = 'enums.countryUf.br_am';
                                            };
                }
                            static get BR_BA() {
                    return new class CountrySubdivision {
                        id = 'BR_BA';
                        value = 'BR-BA';

                                                                        name = 'enums.countryUf.br_ba';
                                            };
                }
                            static get BR_CE() {
                    return new class CountrySubdivision {
                        id = 'BR_CE';
                        value = 'BR-CE';

                                                                        name = 'enums.countryUf.br_ce';
                                            };
                }
                            static get BR_DF() {
                    return new class CountrySubdivision {
                        id = 'BR_DF';
                        value = 'BR-DF';

                                                                        name = 'enums.countryUf.br_df';
                                            };
                }
                            static get BR_ES() {
                    return new class CountrySubdivision {
                        id = 'BR_ES';
                        value = 'BR-ES';

                                                                        name = 'enums.countryUf.br_es';
                                            };
                }
                            static get BR_GO() {
                    return new class CountrySubdivision {
                        id = 'BR_GO';
                        value = 'BR-GO';

                                                                        name = 'enums.countryUf.br_go';
                                            };
                }
                            static get BR_MA() {
                    return new class CountrySubdivision {
                        id = 'BR_MA';
                        value = 'BR-MA';

                                                                        name = 'enums.countryUf.br_ma';
                                            };
                }
                            static get BR_MT() {
                    return new class CountrySubdivision {
                        id = 'BR_MT';
                        value = 'BR-MT';

                                                                        name = 'enums.countryUf.br_mt';
                                            };
                }
                            static get BR_MS() {
                    return new class CountrySubdivision {
                        id = 'BR_MS';
                        value = 'BR-MS';

                                                                        name = 'enums.countryUf.br_ms';
                                            };
                }
                            static get BR_MG() {
                    return new class CountrySubdivision {
                        id = 'BR_MG';
                        value = 'BR-MG';

                                                                        name = 'enums.countryUf.br_mg';
                                            };
                }
                            static get BR_PR() {
                    return new class CountrySubdivision {
                        id = 'BR_PR';
                        value = 'BR-PR';

                                                                        name = 'enums.countryUf.br_pr';
                                            };
                }
                            static get BR_PB() {
                    return new class CountrySubdivision {
                        id = 'BR_PB';
                        value = 'BR-PB';

                                                                        name = 'enums.countryUf.br_pb';
                                            };
                }
                            static get BR_PA() {
                    return new class CountrySubdivision {
                        id = 'BR_PA';
                        value = 'BR-PA';

                                                                        name = 'enums.countryUf.br_pa';
                                            };
                }
                            static get BR_PE() {
                    return new class CountrySubdivision {
                        id = 'BR_PE';
                        value = 'BR-PE';

                                                                        name = 'enums.countryUf.br_pe';
                                            };
                }
                            static get BR_PI() {
                    return new class CountrySubdivision {
                        id = 'BR_PI';
                        value = 'BR-PI';

                                                                        name = 'enums.countryUf.br_pi';
                                            };
                }
                            static get BR_RN() {
                    return new class CountrySubdivision {
                        id = 'BR_RN';
                        value = 'BR-RN';

                                                                        name = 'enums.countryUf.br_rn';
                                            };
                }
                            static get BR_RS() {
                    return new class CountrySubdivision {
                        id = 'BR_RS';
                        value = 'BR-RS';

                                                                        name = 'enums.countryUf.br_rs';
                                            };
                }
                            static get BR_RJ() {
                    return new class CountrySubdivision {
                        id = 'BR_RJ';
                        value = 'BR-RJ';

                                                                        name = 'enums.countryUf.br_rj';
                                            };
                }
                            static get BR_RO() {
                    return new class CountrySubdivision {
                        id = 'BR_RO';
                        value = 'BR-RO';

                                                                        name = 'enums.countryUf.br_ro';
                                            };
                }
                            static get BR_RR() {
                    return new class CountrySubdivision {
                        id = 'BR_RR';
                        value = 'BR-RR';

                                                                        name = 'enums.countryUf.br_rr';
                                            };
                }
                            static get BR_SC() {
                    return new class CountrySubdivision {
                        id = 'BR_SC';
                        value = 'BR-SC';

                                                                        name = 'enums.countryUf.br_sc';
                                            };
                }
                            static get BR_SE() {
                    return new class CountrySubdivision {
                        id = 'BR_SE';
                        value = 'BR-SE';

                                                                        name = 'enums.countryUf.br_se';
                                            };
                }
                            static get BR_SP() {
                    return new class CountrySubdivision {
                        id = 'BR_SP';
                        value = 'BR-SP';

                                                                        name = 'enums.countryUf.br_sp';
                                            };
                }
                            static get BR_TO() {
                    return new class CountrySubdivision {
                        id = 'BR_TO';
                        value = 'BR-TO';

                                                                        name = 'enums.countryUf.br_to';
                                            };
                }
                    };
    