<script setup>

    import { useForm } from '@inertiajs/vue3';
    import { useUrlSearchParams } from '@vueuse/core';
    import SocialLoginButtons from '../../Components/SocialLoginButtons.vue';
    import { VCardText } from 'vuetify/lib/components/index.mjs';
    import { inject, onMounted } from 'vue';

    const props = defineProps({
        errors: Object,
        thirdpartyLogins: Object,
        _global: Object,
        requireLogin: Boolean,
    });

    const notify = inject('notify');

    const loginForm = useForm({
        email: useUrlSearchParams()?.email || '',
        password: null,
        remember: true,
    });

    onMounted(() => {
        setTimeout(() => {
            if (props.requireLogin) {
                notify().info('Faça login para continuar');
            }
        }, 1000);
    });

</script>

<template>
    <v-container class="pb-15">
        <h2 class="my-10 text-center">Login</h2>

        <v-row justify="center">
            <v-col cols="12" lg="4" xl="3">
                <v-card flat>

                    <SocialLoginButtons :drivers="thirdpartyLogins" class="mb-5">
                    </SocialLoginButtons>

                    <v-card-title class="mb-5 text-center">
                        Informe o seu e-mail e senha para continuar
                    </v-card-title>

                    <v-card-text class="px-8">
                        <x-form :action="$route('app-site.user.auth')" :form="loginForm" #="{ activatorProps }">

                            <v-text-field v-model="loginForm.email" type="email" label="E-mail" name="email"
                                prepend-inner-icon="mdi-email" :rules="[$requiredRule]" class="mb-3"
                                :error-messages="errors?.email" autocomplete="email">
                            </v-text-field>


                            <x-input-password v-model="loginForm.password" label="Senha" name="password"
                                :rules="[$requiredRule]" :error-messages="errors?.password"
                                autocomplete="current-password"></x-input-password>
                            <div class="text-medium-emphasis text-right mb-3">
                                <inertia-link class=" text-decoration-none text-link"
                                    :href="$route('app-site.user.forget-password-form')">
                                    Esqueceu ou não sabe sua senha? clique aqui</inertia-link>
                            </div>

                            <div class="d-flex align-space-between align-center mb-5">
                                <v-checkbox v-model="loginForm.remember" label="Manter login" hide-details
                                    density="compact"></v-checkbox>

                            </div>

                            <v-btn :="activatorProps" text="Login" block size="large" color="primary"
                                class="mb-3"></v-btn>

                            <v-btn :to="$route('app-site.user.create')" color="primary" block
                                text="Primeira vez? Cadastre-se" variant="text"></v-btn>

                        </x-form>
                    </v-card-text>

                    <div class="text-center text-medium-emphasis text-subtitle-2 mt-5">
                        <p>
                            Ao fazer login, você concorda com os
                            <inertia-link :href="$route('news.service-terms')">Termos</inertia-link> e a <inertia-link
                                :href="$route('news.privacy-policy')">Política de privacidade</inertia-link>
                            da {{
                                _global.appName }}.
                        </p>
                    </div>

                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
