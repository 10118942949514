<script setup>
    import { usePage } from '@inertiajs/vue3';
    import { computed } from 'vue';

    const props = defineProps({
        user_key: {
            type: String,
            default: '_user'
        }
    });

    const user = computed(() => usePage().props[props.user_key]);

</script>

<template>
    <v-bottom-navigation grow color="primary" bg-color="white" height="70">
        <slot name="default">
            <v-btn text="Início" prepend-icon="mdi-home" :to="$route('app-site.home')"></v-btn>
            <v-btn text="Preços" :to="$route('app-site.pricing')" prepend-icon="mdi-information-variant-circle"></v-btn>
            <v-spacer></v-spacer>
            <v-btn prepend-icon="mdi-magnify" :active="false">
                <vibeland-navbar-search>
                    Pesquisar
                </vibeland-navbar-search>
            </v-btn>
            <v-btn v-if="user" prepend-icon="mdi-chevron-up" color="black">
                Minha conta
                <v-menu activator="parent" scrim>
                    <vibeland-navbar-user-menu></vibeland-navbar-user-menu>
                </v-menu>
            </v-btn>
            <v-btn v-else prepend-icon="mdi-account" text="Login" :to="$route('app-site.user.login')">
            </v-btn>

        </slot>
    </v-bottom-navigation>
</template>