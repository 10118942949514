
        import Enum from '../Helpers/Enum';

        export default class EventStatus extends Enum {
                            static get Draft() {
                    return new class EventStatus {
                        id = 'Draft';
                        value = 'draft';

                                                                        name = 'enums.eventStatus.draft';
                                                                        mdIcon = 'mdi-file-edit';
                                                                        mdColor = 'blue-darken-3';
                                            };
                }
                            static get Published() {
                    return new class EventStatus {
                        id = 'Published';
                        value = 'published';

                                                                        name = 'enums.eventStatus.published';
                                                                        mdIcon = 'mdi-publish';
                                                                        mdColor = 'success';
                                            };
                }
                            static get Started() {
                    return new class EventStatus {
                        id = 'Started';
                        value = 'started';

                                                                        name = 'enums.eventStatus.started';
                                                                        mdIcon = 'mdi-play';
                                                                        mdColor = 'purple';
                                            };
                }
                            static get Ended() {
                    return new class EventStatus {
                        id = 'Ended';
                        value = 'ended';

                                                                        name = 'enums.eventStatus.ended';
                                                                        mdIcon = 'mdi-calendar-end';
                                                                        mdColor = 'blue-grey';
                                            };
                }
                            static get Archived() {
                    return new class EventStatus {
                        id = 'Archived';
                        value = 'archived';

                                                                        name = 'enums.eventStatus.archived';
                                                                        mdIcon = 'mdi-archive-arrow-down';
                                                                        mdColor = 'grey';
                                            };
                }
                    };
    