<script setup>
    import { usePage } from '@inertiajs/vue3';
    import { useLocalStorage } from '@vueuse/core';
    import { omit } from 'lodash';
    import { computed, shallowRef, toValue } from 'vue';
    import Env from '../../js/Helpers/Env';
    import PageHead from './PageHead.vue';

    const debugPanel = shallowRef(false);

    const showDebugPanel = computed(() => Env.DEBUG);

    const exceptProps = [
        // '_currentLocale',
        // '_fallbackLocale',
        // '_global',
        // 'pageTitle',
        // '_user',
        // '_flash',
        // 'errors',
    ];

</script>

<template>

    <PageHead :="$page.props"></PageHead>

    <v-btn v-if="showDebugPanel" @click="() => (debugPanel = !debugPanel)" icon="mdi-bug" color="error" style="
			position: fixed;
            top: calc(100vh - 60px);
			left: 10px;
			z-index: 9999;
		">
    </v-btn>

    <v-navigation-drawer v-if="showDebugPanel" v-model="debugPanel" width="800" temporary>
        <x-debug v-if="debugPanel" :data="omit($page.props, exceptProps)" :deep="1"></x-debug>
    </v-navigation-drawer>
</template>
