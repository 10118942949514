<script setup>

    import { usePage } from '@inertiajs/vue3';
    import { sentryFeedbackIntegration } from '../../../../../../resources/vue/Plugins/SentryVue';
    import { computed, inject } from 'vue';
    import Env from '@/Helpers/Env';

    const props = defineProps({
        global_key: {
            type: String,
            default: '_global'
        },
    });

    const route = inject('route');

    const global = computed(() => usePage().props[props.global_key]);

    const collaboratorsPageUrl = computed(() => (usePage().props?.collaboratorsPage || false));

    const mainLinks = computed(() => {
        return [
            { title: 'Início', to: route('app-site.home') },
            { title: 'Login', to: route('app-site.user.login') },
            { title: 'Cadastro', to: route('app-site.user.create') },
            { title: 'Produtor', href: route('producer.home') },
            { title: 'Preços', to: route('app-site.pricing') },
            { title: 'Calculadora', to: route('app-site.pricing') + '#calculadora' },
            collaboratorsPageUrl.value ? { title: 'Colaborador', href: collaboratorsPageUrl.value } : false,
        ].filter(Boolean)
    });

    const resourceLinks = computed(() => [
        { title: 'Blog & novidades', href: route('news.home') },
        { title: 'Termos de serviço', href: route('news.service-terms') },
        { title: 'Política de privacidade', href: route('news.privacy-policy') },
        { title: 'Ajuda', href: route('news.help'), },
        Env.SENTRY_DSN ? {
            title: 'Fornecer feedback',
            role: 'button',
            ref: function (xComponentInstance) {
                if (xComponentInstance && xComponentInstance.$el) {
                    sentryFeedbackIntegration.attachTo(xComponentInstance.$el, {});
                }

            }
        } : false,
    ].filter(Boolean));

</script>

<template>

    <v-footer color="black" class="pa-lg-10 text-center text-lg-start ">

        <v-defaults-provider :defaults="{ VListItemTitle: { class: ['text-uppercase'] } }">

            <v-row justify="center" class="h-100">

                <v-col cols="12" lg="3" align-self="start">
                    <v-img src="/images/brand/logo-white-512.png" :height="$vuetify.display.mobile ? 60 : 100"
                        class="mb-10 mb-lg-0"></v-img>
                </v-col>

                <v-col cols="6" lg="3">

                    <h5>Principal</h5>

                    <v-list bg-color="transparent" variant="plain">
                        <v-list-item v-for="(xLink, xLinkIndex) in mainLinks" :key="xLinkIndex" :="xLink"></v-list-item>
                    </v-list>

                </v-col>
                <v-col cols="6" lg="3">

                    <h5>Recursos</h5>

                    <v-list bg-color="transparent" variant="plain">
                        <v-list-item v-for="(xLink, xLinkIndex) in resourceLinks" :key="xLinkIndex"
                            :="xLink"></v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="12" lg="3">

                    <h5>Contato</h5>

                    <v-list bg-color="transparent" variant="plain" class="text-start">
                        <v-list-item v-if="global?.email" prepend-icon="mdi-email">
                            <x-mail :mail="global?.email"></x-mail>
                        </v-list-item>
                        <v-list-item v-if="global?.phone" prepend-icon="mdi-phone" :href="`tel:` + global?.phone">
                            <span v-x-mask-format="$locale.specs.phoneFormat.mask">{{ global?.phone
                                }}</span>
                        </v-list-item>
                        <v-list-item v-if="global?.whatsapp" prepend-icon="mdi-phone"
                            :href="`https://wa.me/` + global?.whatsapp">
                            {{ global?.whatsapp }}
                        </v-list-item>
                        <v-list-item v-if="global?.instagram" prepend-icon="mdi-instagram"
                            :href="`https://instagram.com/` + global?.instagram" target="_blank">
                            @{{ global?.instagram }}
                        </v-list-item>
                    </v-list>

                </v-col>


                <v-col cols="12">
                    <p class="text-center mt-10">
                        &copy; Copyright {{ (new Date).getFullYear() }} {{ global.appName }}.
                        Todos os direitos reservados.

                        {{ global?.company_description }}
                    </p>
                </v-col>
            </v-row>

        </v-defaults-provider>

    </v-footer>

</template>