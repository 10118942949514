<script setup>
import { h, inject, toValue, useAttrs } from 'vue';
import { VAvatar, VChip, VIcon } from 'vuetify/lib/components/index.mjs';

const props = defineProps({
	enum: Object,
	upper: Boolean,
});

const support = inject('support');

const render = h(
	VChip,
	{
		color: props.enum?.mdColor || props.enum?.color,
		prependIcon: props.enum?.mdIcon || props.enum?.icon,
		text: toValue(() => {
			const temp = String(
				support.locale.has(props.enum?.name)
					? support.locale.get(props.enum?.name)
					: props.enum?.name || props.enum?.value || props.enum?.id
			);

			return props.upper ? temp.toUpperCase() : temp;
		}),
		prependAvatar: props.enum?.avatar,
	},
	{
		prepend: () => {
			if (props.enum.mdIcon) {
				return h(VIcon, { icon: props.enum.mdIcon, size: 21 });
			} else if (props.enum.avatar) {
				return h(VAvatar, { image: props.enum.avatar, size: 21 });
			} else if (props.enum.image) {
				return h(VAvatar, { image: props.enum.image, size: 21 });
			} else if (props.enum.imageUrl) {
				return h(VAvatar, { image: props.enum.imageUrl, size: 21 });
			}

			return undefined;
		},
	}
);
</script>

<template>
	<render></render>
</template>
