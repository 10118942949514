<script setup>
    import { usePage } from '@inertiajs/vue3';
    import { computed } from 'vue';

    const props = defineProps({
        global_key: {
            type: String,
            default: '_global'
        }
    });

    const global = computed(() => usePage().props?.[props.global_key]);

</script>

<template>
    <v-sheet elevation="0" class="px-5 pt-10 pb-5">
        <div class="text-h6 font-weight-bold py-3">Crie sua conta no {{ global.appName }}, é grátis
        </div>

        <v-list bg-color="transparent" variant="plain" density="compact">
            <v-list-item title="Preços" :to="$route('app-site.pricing')"> </v-list-item>
            <v-list-item title="Calculadora" :to="$route('app-site.pricing') + '#calculadora'"></v-list-item>
        </v-list>
    </v-sheet>
</template>