
import { RRule, Frequency } from "rrule";

const maps = {
    frequency: {
        'year': Frequency.YEARLY,
        'month': Frequency.MONTHLY,
        'week': Frequency.WEEKLY,
        'day': Frequency.DAILY,
        'hour': Frequency.HOURLY,
    },
    weekday: {}
};

const eventScheduleItemToRRule = function (eventScheduleItem, dayjs, dateProperty = 'startAt') {

    const date = dayjs(eventScheduleItem[dateProperty || 'startAt']).second(0);

    return new RRule({
        freq: maps.frequency[eventScheduleItem.repeatFrequencyUnit],
        dtstart: (eventScheduleItem.allDay ? date.hour(0).minute(0) : date).toDate(),
        interval: eventScheduleItem.repeatFrequencyInterval,
        wkst: RRule.SU,
        count: eventScheduleItem?.repeatUntilCount || undefined,
        until: eventScheduleItem?.repeatUntilDatetime ? dayjs(eventScheduleItem?.repeatUntilDatetime).toDate() : undefined,
        tzid: undefined,
        byweekday: eventScheduleItem?.repeatFilters?.weekdays,
        bysetpos: undefined,
        bymonth: eventScheduleItem?.repeatFilters?.months,
        bymonthday: undefined,
        byyearday: undefined,
        byweekno: undefined,
        byweekday: undefined,
        byhour: eventScheduleItem?.repeatFilters?.hours,
        byminute: undefined,
        bysecond: undefined,
    });
};

const applyExceptions = function (dates, exceptions) {

    exceptions = exceptions || {};

    if (Object.keys(exceptions).length === 0) {
        return dates;
    }

    return dates
        .filter(function (date) {
            return exceptions[date.valueOf()] !== false;
        })
        .concat(
            Object.entries(exceptions)
                .filter(([timestamp, include]) => include)
                .map(([timestamp, include]) => new Date(timestamp))
        );
};


const all = function (eventScheduleItem, dayjs) {
    const x = eventScheduleItemToRRule(eventScheduleItem, dayjs);

    return applyExceptions(x.all(), eventScheduleItem?.repeatExceptions);
};

const after = function (eventScheduleItem, dayjs, date, include) {
    const x = eventScheduleItemToRRule(eventScheduleItem, dayjs);

    return applyExceptions(x.after(date, include), eventScheduleItem?.repeatExceptions);
};
export {
    applyExceptions,
    eventScheduleItemToRRule,
    all,
    after,
};