<script setup>

import { watch } from 'vue';
import { inject } from 'vue';
import { useCurrencyInput } from 'vue-currency-input';

const props = defineProps({
    modelValue: Number,
    prefix: String,
    range: Object
});

const support = inject('support');

const { formattedValue, inputRef, setValue } = useCurrencyInput({
    locale: support.locale.specs.mainLanguage.value.replace('_', '-'),
    currency: support.locale.specs.mainCurrency.id,
    currencyDisplay: 'symbol',
    valueRange: props?.range,

    autoDecimalDigits: true,
    precision: 2,
    useGrouping: true,

    hideCurrencySymbolOnFocus: false,
    hideGroupingSeparatorOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
});

watch(() => props.modelValue, (value) => {
    setValue(value);
});

</script>

<template>
    <v-text-field v-model="formattedValue" ref="inputRef">
        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
            <slot :name="slot" :="scope"></slot>
        </template>
    </v-text-field>
</template>
