<script setup>

    import { mapWithKeys } from '@/Helpers/Helpers';
    import { snakeCase } from 'lodash';
    import { computed } from 'vue';

    const props = defineProps({
        clientId: {
            type: String,
            required: true,
        },
        redirectUrl: {
            type: String,
            required: true,
        },

        // https://developers.google.com/identity/gsi/web/reference/html-reference#element_with_id_g_id_onload
        onloadAttributes: {
            type: Object,
            default: {}
        },

        // https://developers.google.com/identity/gsi/web/reference/html-reference#element_with_class_g_id_signin
        signinAttributes: {
            type: Object,
            default: {}
        }
    });

    const defaults = {
        onload: {
            cancelOnTapOutside: false,
            context: 'use',
            uxMode: 'redirect'
        },
        signin: {
            text: 'continue_with',
            logoAlignment: 'center',
            shape: 'pill',
            size: 'large'
        }
    }

    const onload = computed(function () {
        return mapWithKeys(Object.assign({ clientId: props.clientId, loginUri: props.redirectUrl }, props.onloadAttributes, defaults.onload), function (value, key) {
            return {
                key: `data-${snakeCase(key)}`,
                value,
            }
        });
    });

    const signin = computed(function () {
        return mapWithKeys(Object.assign(props.signinAttributes, defaults.signin), function (value, key) {
            return {
                key: `data-${snakeCase(key)}`,
                value,
            }
        });
    });

</script>

<template>

    <div class="button-login button-login-google">
        
        <component is="script" src="https://accounts.google.com/gsi/client" async></component>
        <div id="g_id_onload" :="onload">
        </div>
        <div class="g_id_signin" :="signin">
        </div>
    </div>

</template>