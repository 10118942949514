
import Env from "@/Helpers/Env";
import * as Sentry from "@sentry/vue";

const sentryFeedbackIntegration = Sentry.feedbackAsyncIntegration({
    autoInject: false,

    colorScheme: 'system',
    showBranding: false,


    isEmailRequired: true,

    triggerLabel: null,
    formTitle: 'Enviar feedback',
    submitButtonLabel: 'Enviar',
    cancelButtonLabel: 'Cancelar',
    successMessageText: 'Feito!'
});

export default {
    install: function (vueApp, options) {

        if (!Env.SENTRY_DSN) {
            return;
        }

        Sentry.init({

            enabled: Boolean(Env.SENTRY_DSN),

            app: vueApp,
            dsn: Env.SENTRY_DSN,

            environment: Env.APP_ENV,
            release: Env.VITE_APP_ALT_VERSION,

            // debug: Env.DEBUG,
            debug: false,
            logErrors: true,

            sampleRate: 1.0,
            enableTracing: false,
            autoSessionTracking: false,
            tracesSampleRate: 0,
            profilesSampleRate: 0,
            replaysOnErrorSampleRate: 0,
            replaysSessionSampleRate: 0,

        });

        if (options?.moduleContext) {
            Sentry.withScope(function (scope) {
                scope.setContext('VIBELAND', {
                    frontend: true,
                    ...(options?.moduleContext || {})
                });
            });
        }

        // vueApp.provide('sentry', Sentry);
    }
};

export { sentryFeedbackIntegration };