<script setup>

    import { useVModel } from '@vueuse/core';
    import { computed, inject } from 'vue';

    const support = inject('support');

    const props = defineProps({
        modelValue: {},
        rules: Array,
        preview: {
            type: Boolean,
            default: true
        },
        max: Number,
        min: Number
    });

    const emits = defineEmits(['update:modelValue']);

    const model = useVModel(props, 'modelValue', emits);

    const integerRule = (value) => {
        if ([null, undefined].includes(value)) {
            return true;
        }

        if (!['number', 'string'].includes(typeof value)) {
            return 'Valor inválido';
        }

        if (String(value).match(/[^0-9]/g)?.length) {
            return 'Valor inválido';
        }

        const asNumber = parseInt(value);

        if (props.max && asNumber > props.max) {
            return 'O valor deve não deve ser maior que ' + props.max;
        }

        if (props.min && asNumber < props.min) {
            return 'O valor deve não deve ser menor que ' + props.min;
        }

        return true;
    };

    const modelPreview = computed(() => {
        if (props.preview !== false && [null, undefined].includes(model.value)) {
            return null;
        }

        return support.locale.formatNumber(parseInt(model.value));
    });

    const cast = (value) => {
        if (typeof value !== 'number') {
            return;
        }

        model.value = parseInt(value);
    }

</script>

<template>
    <v-text-field v-model.number="model" @update:model-value="cast"
        :rules="$props.rules ? $props.rules.concat(integerRule) : [integerRule]" placeholder="Ex.: 1234">

        <template #append-inner>
            <span style="width: max-content;">{{ modelPreview }}</span>
        </template>

        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
            <slot :name="slot" :="scope"></slot>
        </template>
    </v-text-field>
</template>
