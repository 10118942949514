
        import Enum from '../Helpers/Enum';

        export default class BuyableItemType extends Enum {
                            static get Ticket() {
                    return new class BuyableItemType {
                        id = 'Ticket';
                        value = 'ticket';

                                                                        product = true;
                                                                                                                                                                                debit = true;
                                                                        requireReserve = true;
                                                                        name = 'enums.buyable_item_type.ticket';
                                            };
                }
                            static get Fee() {
                    return new class BuyableItemType {
                        id = 'Fee';
                        value = 'fee';

                                                                        product = false;
                                                                        debit = true;
                                                                        requireReserve = false;
                                                                        name = 'enums.buyable_item_type.fee';
                                            };
                }
                            static get FinancingFee() {
                    return new class BuyableItemType {
                        id = 'FinancingFee';
                        value = 'financing_fee';

                                                                        product = false;
                                                                        debit = true;
                                                                        requireReserve = false;
                                                                        name = 'enums.buyable_item_type.financing_fee';
                                            };
                }
                            static get PaymentMethodDiscount() {
                    return new class BuyableItemType {
                        id = 'PaymentMethodDiscount';
                        value = 'payment_method_discount';

                                                                        product = false;
                                                                        debit = false;
                                                                        requireReserve = false;
                                                                        name = 'enums.buyable_item_type.payment_method_discount';
                                            };
                }
                    };
    