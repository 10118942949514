<script setup>

    import { useVModel } from '@vueuse/core';
    import { computed, inject } from 'vue';

    const support = inject('support');

    const props = defineProps({
        modelValue: {},
        preview: {
            type: Boolean,
            default: true
        },
        rules: Array
    });

    const emits = defineEmits(['update:modelValue']);

    const model = useVModel(props, 'modelValue', emits);

    const percentageRule = (value) => {

        if ([null, undefined].includes(value)) {
            return true;
        }

        if (!['number', 'string'].includes(typeof value)) {
            return 'Valor inválido';
        }

        if (String(value).match(/[^0-9\.]/g)?.length) {
            return 'Valor inválido';
        }

        if (parseFloat(value) < 0 || parseFloat(value) > 100) {
            return 'O valor deve ser entre 0 e 100';
        }

        return true;
    };

    const modelPreview = computed(() => {
        if (props.preview !== false && [null, undefined].includes(model.value)) {
            return null;
        }

        return '% ' + support.locale.formatNumber(parseFloat(model.value));
    });

    const cast = (value) => {
        if (typeof value !== 'number') {
            return;
        }

        model.value = Number(value.toFixed(2));
    }

</script>

<template>
    <v-text-field v-model.number="model" @update:model-value="cast"
        :rules="$props.rules ? $props.rules.concat(percentageRule) : [percentageRule]" placeholder="Ex.: 12.34">
        <template #prepend-inner>%</template>

        <template #append-inner>
            <span style="width: max-content;">{{ modelPreview }}</span>
        </template>

        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
            <slot :name="slot" :="scope"></slot>
        </template>
    </v-text-field>
</template>
