<script setup>

import { router, usePage } from '@inertiajs/vue3';
import { omit } from 'lodash';
import { onMounted } from 'vue';
import { inject, shallowRef, toValue } from 'vue';

const props = defineProps({
    source: {
        type: String,
        required: true
    },
    mode: String
});

const support = inject('support');

const getCurrentPaginator = () => usePage().props?.[props.source];

const items = shallowRef(toValue(getCurrentPaginator()?.data || []));

const loadMore = function ({ done }) {

    const paginator = getCurrentPaginator();

    if (!paginator.links.next) {
        done('empty');
        return;
    }

    router.reload({
        only: [props.source],
        data: {
            page: paginator.meta.current_page + 1,
        },
        onStart: () => done('loading'),
        onSuccess: function (inertiaResponse) {
            items.value.push(...(inertiaResponse.props?.[props.source]?.data || []));

            support.window.history.replaceState({}, undefined, paginator.meta.path);

            done('ok');
        },
        onError: () => done('error')
    });
}

</script>

<template>
    <v-infinite-scroll @load="loadMore" :mode="items.length === 0 ? 'manual' : mode">

        <slot :="{ items }">
        </slot>

        <template v-for="(_, slot) of omit($slots, ['default'])" v-slot:[slot]="scope">
            <slot :name="slot" :="scope"></slot>
        </template>


    </v-infinite-scroll>
</template>
