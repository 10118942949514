<script setup>

    import { take } from 'lodash';
    import { EventCategory } from '../../../../../../../../resources/js/Enums';
    import { useInfinitePaginator } from '../../../../../../../../resources/vue/Composables';

    const paginator = useInfinitePaginator({ propName: 'tickets' });

</script>

<template>
    <v-container style="max-width: 1100px;">
        <v-breadcrumbs :items="[{ title: 'Início', to: $route('app-site.home') }, 'Ingressos']"
            class="mb-5"></v-breadcrumbs>

        <h1 class="text-h4">Seus ingressos</h1>

        <div class="my-5">
            <v-chip-group color="primary" model-value="all" mandatory variant="flat">
                <v-chip value="all" text="Todos"></v-chip>
            </v-chip-group>
        </div>

        <v-infinite-scroll @load="paginator.loadMore">
            <v-row v-if="paginator.items.length">
                <v-col v-for="(xItem, xItemIndex) in paginator.items" :key="xItemIndex" cols="12" lg="4">
                    <v-card border class="h-100" :to="$route('app-site.user.menu.tickets.show', [xItem.uuid])">

                        <v-hover #="{ isHovering, props }">
                            <v-img :src="xItem.ticketType.event?.image?.large_land"
                                :lazy-src="xItem.ticketType.event?.image?.small_land" :="props">
                                <v-overlay :model-value="isHovering || $vuetify.display.mobile" contained class="align-center justify-center"
                                    scrim="black">
                                    <v-btn prepend-icon="mdi-open-in-new" text="Detalhes" color="primary" size="small"
                                        :to="$route('app-site.user.menu.tickets.show', [xItem.uuid])"></v-btn>
                                </v-overlay>
                            </v-img>
                        </v-hover>

                        <v-card-text class="text-center">
                            <div class="mb-3">
                                <b>
                                    {{ xItem.ticketType.name.toUpperCase() }}
                                </b>
                            </div>
                            <div>
                                <span>
                                    {{ xItem.ticketType.event.title }}
                                </span>
                            </div>
                        </v-card-text>

                    </v-card>
                </v-col>
            </v-row>
        </v-infinite-scroll>
    </v-container>
</template>
