<script setup>
    import { useForm } from '@inertiajs/vue3';
    import SocialLoginButtons from '../../Components/SocialLoginButtons.vue';
    import { VCardText } from 'vuetify/lib/components/index.mjs';

    defineProps({
        errors: Object,
        pageTitle: String,
        thirdpartyLogins: Object,
        _global: Object,
    });

    const registerForm = useForm({
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
    });

</script>

<template>
    <v-container class="pb-15">
        <h2 class="my-10 text-center">{{ pageTitle }}</h2>

        <v-row justify="center">
            <v-col cols="12" lg="4" xl="3">
                <v-card flat>

                    <SocialLoginButtons :drivers="thirdpartyLogins" class="mb-5">
                    </SocialLoginButtons>

                    <v-card-title class="mb-5 text-center">
                        Preencha o formulário para se cadastrar
                    </v-card-title>

                    <v-card-text class="px-8">
                        <x-form :form="registerForm" :action="$route('app-site.user.store')" #="{ activatorProps }">
                            <v-text-field v-model="registerForm.name" label="Seu nome" prepend-inner-icon="mdi-account"
                                :rules="[$requiredRule]" class="mb-3" :error-messages="errors?.name" autocomplete="name"
                                name="name"></v-text-field>

                            <v-text-field v-model="registerForm.email" type="email" label="Seu e-mail"
                                prepend-inner-icon="mdi-email" :rules="[$requiredRule, $emailRule]" class="mb-3"
                                :error-messages="errors?.email" autocomplete="email"></v-text-field>

                            <x-input-password v-model="registerForm.password" label="Crie uma nova senha"
                                prepend-inner-icon="mdi-key" :rules="[$requiredRule]" :error-messages="errors?.password"
                                class="mb-3" autocomplete="new-password"></x-input-password>

                            <x-input-password v-model="registerForm.password_confirmation" label="Repita sua nova senha"
                                prepend-inner-icon="mdi-key" autocomplete="new-password" :rules="[$requiredRule].concat(
                                    (v) =>
                                        v === registerForm.password ||
                                        'As senhas devem ser iguais'
                                )" :error-messages="errors?.password_confirmation" class="mb-5"></x-input-password>


                            <v-btn :="activatorProps" text="Fazer cadastro" block size="large" color="primary"
                                class="mb-3"></v-btn>

                            <v-btn :to="$route('app-site.user.login')" color="primary" block
                                text="Já fez cadastro? faça login" variant="text"></v-btn>
                        </x-form>
                    </v-card-text>


                    <div class="text-center text-medium-emphasis text-subtitle-2 mt-5">
                        <p>
                            Ao se cadastrar, você concorda com os
                            <inertia-link :href="$route('news.service-terms')">Termos</inertia-link> e a <inertia-link
                                :href="$route('news.privacy-policy')">Política de privacidade</inertia-link>
                            da {{
                                _global.appName }}.
                        </p>
                    </div>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
