<script setup>

    import { computed } from 'vue';

    const props = defineProps({
        items: {
            type: Array,
            required: false,
            default: []
        },
        columns: {
            type: Array,
            required: false,
            default: []
        },
        columnDef: {
            type: Object,
            required: false,
            default: {}
        },
    });

    const defaultColDef = {
        // colspan: 1,
        // rowspan: 1,
        // fixed: true,
        // align: 'start',
        // width: undefined,
        // minWidth: undefined,
        // maxWidth: undefined,
        // sortable: false,
        // sort: undefined,
    };

    const datatableProps = computed(() => ({
        items: props.items,
        itemsPerPage: -1,
        disableSort: true,
        headers: props.columns.map(function (xCol, index) {
            const temp = {
                ...defaultColDef,
                ...props.columnDef,
                ...(typeof xCol === 'string' ? { title: xCol } : xCol)
            }

            if (temp.key === undefined) {
                temp.key = index + 1;
            }

            return temp;
        })
    }));

</script>

<template>
    <v-data-table :="datatableProps" fixed-header>

        <template #bottom></template>


        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
            <slot :name="slot" :="scope"></slot>
        </template>


    </v-data-table>
</template>
