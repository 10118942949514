
import ImageInputComponent from '../Components/Forms/ImageInput.vue';
import IntegerNumberInputComponent from '../Components/Forms/IntegerInput.vue';
import PercentageNumberInputComponent from '../Components/Forms/PercentageInput.vue';
import MoneyInputComponent from '../Components/Forms/MoneyInput.vue';
import MaskInputComponent from '../Components/Forms/MaskInput.vue';
import PasswordInputComponent from '../Components/Forms/PasswordInput.vue';
import PhoneInputComponent from '../Components/Forms/PhoneInput.vue';
import FormComponent from '../Components/Forms/Form.vue';

export default class Form {
    install(vueApp) {

        vueApp.component('x-form', FormComponent);

        vueApp.component('x-input-image', ImageInputComponent);
        vueApp.component('x-input-integer', IntegerNumberInputComponent);
        vueApp.component('x-input-percentage', PercentageNumberInputComponent);
        vueApp.component('x-input-money', MoneyInputComponent);
        vueApp.component('x-input-mask', MaskInputComponent);
        vueApp.component('x-input-password', PasswordInputComponent);
        vueApp.component('x-input-phone', PhoneInputComponent);
    }
}
