<script setup>

import { computed } from 'vue';
import * as Enums from '../../../js/Enums';

const props = defineProps({
    only: Array
});

const onlyFilter = computed(() => {
    if (props.only === undefined || props.only?.length === 0) {
        return [];
    }

    return props.only.map((x) => typeof x === 'string' ? x : x.name);
});


const filteredEnums = computed(() => {
    if (onlyFilter.value.length === 0) {
        return Enums;
    }

    const results = [];

    Object.keys(Enums).forEach((xCaseName) => {
        if (onlyFilter.value.includes(xCaseName)) {
            results.push(xCaseName);
        }
    });

    return results.map((xCaseName) => Enums[xCaseName]);
});

</script>

<template>
    <v-container>


        <template v-for="xEnum in filteredEnums">
            <p><b>{{ xEnum.name }}:</b></p>

            <x-chip-enum v-for="xCase in xEnum.cases()" :enum="xCase" class="ma-1"></x-chip-enum>

            <v-divider class="my-2"></v-divider>
        </template>

    </v-container>
</template>

