
export default {
    MERCADOPAGO_PUBLIC_KEY: String(import.meta.env.VITE_MERCADOPAGO_PUBLIC_KEY),

    GOOGLE_MAPS_API_KEY: String(import.meta.env.VITE_GOOGLE_MAPS_API_KEY),

    DEBUG: ['1', 'true', true, 1].includes(import.meta.env.VITE_DEBUG),

    SENTRY_DSN: String(import.meta.env.VITE_SENTRY_DSN),

    APP_ENV: String(import.meta.env.VITE_APP_ENV),

    VITE_APP_ALT_VERSION: String(import.meta.env.VITE_APP_ALT_VERSION),
};
