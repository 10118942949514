
import { router } from "@inertiajs/vue3";
import Env from "../../js/Helpers/Env";
import DebugEnumsComponent from '../../vue/Components/Debug/Enums.vue';
import { h } from "vue";
import ObjectComponent from '../../vue/Components/Debug/Object.vue';
import * as Helpers from "@/Helpers/Helpers";

export default class Debug {
    install(vueApp, options) {
        if (Env.DEBUG) {
            vueApp.component('x-debug-enums', DebugEnumsComponent);

            ['navigate', 'before', 'start', 'progress', 'success', 'error', 'invalid', 'exception', 'finish'].forEach((xInertiaEventName) => {
                router.on(xInertiaEventName, function ({ detail }) {
                    console.log(`inertia:${xInertiaEventName}`, detail);
                });
            });

            window.$vue = vueApp;

            window.$helpers = Helpers;

            window.$support = vueApp.config.globalProperties.$support;

            // vueApp.config.warnHandler = function (msg, instance, trace) {
            //     console.warn(...arguments);
            // };

        }

        vueApp.component('x-debug', function (props, { slots, emit, attrs }) {
            if (!Env.DEBUG) {
                return '';
            }

            return h(ObjectComponent, props);
        });

    }
}
