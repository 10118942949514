
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import { createInertiaModule } from '../../../../resources/vue/Helpers/InertiaModule';

import FormPlugin from '../../../../resources/vue/Plugins/Form';
import SupportPlugin from '../../../../resources/vue/Plugins/Support';
import LocalePlugin from '../../../../resources/vue/Plugins/Locale';
import GlobalComponentsPlugin from '../../../../resources/vue/Plugins/GlobalComponents';

import UserLayoutComponent from '../Vue/Layouts/UserLayout.vue';
import { createVuetify } from '../../../../resources/vue/VibelandVuetify';
import { VibelandComponents } from './VibelandComponent';
import SentryVue from '../../../../resources/vue/Plugins/SentryVue';

createInertiaModule({
    pageComponentResolver: function (pageName) {
        return resolvePageComponent(`../Vue/Pages/${pageName}.vue`, import.meta.glob('../Vue/Pages/**/*.vue', { eager: true })).then(function (vuePageComponent) {


            if (pageName.startsWith('User/')) {
                vuePageComponent.default.layout = UserLayoutComponent;
            }
            else if (
                ['Debug', 'Error', 'Home', 'Pricing'].includes(pageName) ||
                pageName.startsWith('Events/')
            ) {
                vuePageComponent.default.layout = null;
            }

            return vuePageComponent;
        })
    },
}, function ({ vueApp, inertiaProps }) {

    const locale = new LocalePlugin({ pageProps: inertiaProps.initialPage.props });
    const vuetify = createVuetify({
        date: locale.vuetifyDate,
        locale: locale.vuetifyLocale,
    });
    vueApp.use(vuetify);
    vueApp.use(new SupportPlugin(), { vuetify, locale });
    vueApp.use(new FormPlugin());
    vueApp.use(locale);
    vueApp.use(new GlobalComponentsPlugin);
    vueApp.use(VibelandComponents);
    vueApp.use(SentryVue, {
        moduleContext: {
            module: 'site'
        }
    });


    // MODULE SCOPE

});
