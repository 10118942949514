
        import Enum from '../Helpers/Enum';

        export default class PersonalDocumentWithPhotoType extends Enum {
                            static get BR_RG() {
                    return new class PersonalDocumentWithPhotoType {
                        id = 'BR_RG';
                        value = 'br_rg';

                                                                        name = 'enums.personalDocumentWithPhotoType.br_rg';
                                            };
                }
                            static get BR_CNH() {
                    return new class PersonalDocumentWithPhotoType {
                        id = 'BR_CNH';
                        value = 'br_cnh';

                                                                        name = 'enums.personalDocumentWithPhotoType.br_cnh';
                                            };
                }
                    };
    