
        import Enum from '../Helpers/Enum';

        export default class PhoneFormat extends Enum {
                            static get BR() {
                    return new class PhoneFormat {
                        id = 'BR';
                        value = 'br';

                                                                        code = '+55';
                                                                        name = 'enums.country.br';
                                                                        mask = '+## (##) # ####-####';
                                                                        length = 13;
                                            };
                }
                            static get US() {
                    return new class PhoneFormat {
                        id = 'US';
                        value = 'us';

                                                                        code = '+1';
                                                                        name = 'enums.country.us';
                                                                        mask = '+# (###) ###-####';
                                                                        length = 11;
                                            };
                }
                    };
    