<script setup>
    import { useFileDialog, useVModel } from '@vueuse/core';
    import { computed } from 'vue';
    import { ref } from 'vue';

    defineOptions({
        inheritAttrs: false
    });

    const props = defineProps({
        modelValue: {
            required: true
        },
        placeholder: {
            type: String,
            required: false,
            default: '/images/placeholder-image.png'
        }
    });

    const emits = defineEmits(['update:modelValue']);

    const model = useVModel(props, 'modelValue', emits);

    // 

    const selectedImageFile = ref();

    const confirmDialogOpened = ref(false);

    const fileDialog = useFileDialog({
        accept: 'image/*',
        multiple: false,
        reset: true,
    });

    fileDialog.onChange(function (fileList) {
        if (fileList === null || (fileList && fileList.length === 0)) {
            return;
        }

        selectedImageFile.value = fileList.item(0);
        confirmDialogOpened.value = true;
    });

    const selectedFilePreview = computed(() => selectedImageFile.value ? URL.createObjectURL(selectedImageFile.value) : props.placeholder);

    const modelPreview = computed(() => model.value ? URL.createObjectURL(model.value) : props.placeholder);


    const save = function () {
        confirmDialogOpened.value = false;
        model.value = selectedImageFile.value;
    };

    const cancel = function () {
        confirmDialogOpened.value = false;
        selectedImageFile.value = undefined;
        fileDialog.reset();
    };

</script>

<template>
    <v-hover #="{ isHovering, props: hoverProps }">


        <slot name="dialog"
            :="{ opened: confirmDialogOpened, imageProps: { src: selectedFilePreview, ...$attrs }, save, cancel }">
            <v-dialog v-model="confirmDialogOpened" persistent width="500">
                <v-card flat border>
                    <v-card-text>
                        <v-img :src="selectedFilePreview" :="$attrs"></v-img>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text="Cancelar" @click="cancel"></v-btn>
                        <v-btn text="Selecionar" @click="save"></v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </slot>


        <slot>
            <v-img :src="modelPreview" :="{ ...hoverProps, ...$attrs }">
                <v-overlay persistent :model-value="isHovering" contained
                    class="align-center justify-center text-center">
                    <slot name="overlay" :="{ fileDialog, open: fileDialog.open }">
                        <v-btn icon="mdi-image-edit" @click="fileDialog.open"></v-btn>
                    </slot>
                </v-overlay>
            </v-img>
        </slot>



    </v-hover>
</template>
