<script setup>
    import { useWindowScroll } from '@vueuse/core';
    import { computed, ref } from 'vue';

    const props = defineProps({
        _global: Object,
        _user: Object,
        calculator: Object
    });

    const { y } = useWindowScroll();

    const firstSpacer = ref();

    const firstSpacerWidth = computed(() => {
        if (!firstSpacer.value) {
            return 0;
        }

        return firstSpacer.value?.$el?.scrollWidth || 0;
    });

</script>

<template>

    <v-app>
        <x-support></x-support>

        <x-scroll-to-top color="white" elevation="5" :size="$vuetify.display.mobile ? undefined : 'large'"
            :bottom="$vuetify.display.mobile ? 70 : undefined"
            :right="$vuetify.display.mobile ? 10 : undefined"></x-scroll-to-top>

        <vibeland-navbar :height="$vuetify.display.mobile ? 80 : 100" color="white" :flat="!y">

            <template #mobile>
                <vibeland-navbar-logo :current="y > 1 ? 1 : 0" style="width: 200px;"
                    :images="['/images/brand/logo-black-512.png', '/images/brand/icon-black-512.png']"></vibeland-navbar-logo>
            </template>

            <template #default>
                <v-spacer ref="firstSpacer"></v-spacer>

                <vibeland-navbar-btn class="mx-1" size="large" color="black" variant="text" text="Início"
                    :to="$route('app-site.home')" :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>


                <vibeland-navbar-btn-menu open-delay="100" :content-class="['border-b']">

                    <template #activator="{ props }">
                        <vibeland-navbar-btn class="mx-1" size="large" color="black" variant="text" text="Preços"
                            :to="$route('app-site.pricing')" :="props"
                            :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>
                    </template>

                    <template #menu>
                        <vibeland-pricing-submenu
                            :style="`padding-left: ${firstSpacerWidth}px !important;`"></vibeland-pricing-submenu>
                    </template>
                </vibeland-navbar-btn-menu>

                <vibeland-navbar-btn class="mx-1" size="large" color="black" variant="text" text="News"
                    :href="$route('news.home')" :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>

                <v-spacer></v-spacer>

                <vibeland-navbar-logo :current="y > 1 ? 1 : 0" style="width: 250px;"
                    :images="['/images/brand/logo-black-512.png', '/images/brand/icon-black-512.png']"></vibeland-navbar-logo>

                <v-spacer></v-spacer>

                <vibeland-navbar-btn class="mx-3" variant="text" color="black" icon :hover="{ variant: 'elevated' }">

                    <vibeland-navbar-search></vibeland-navbar-search>
                </vibeland-navbar-btn>

                <template v-if="!_user">

                    <vibeland-navbar-btn class="mx-1" size="large" color="black" text="Cadastre-se" variant="text"
                        :to="$route('app-site.user.create')" :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>

                    <vibeland-navbar-btn class="mx-1" size="large" color="black" text="Faça Login" variant="outlined"
                        :to="$route('app-site.user.login')" :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>

                </template>
                <template v-else>

                    <v-menu :open-on-hover="!$vuetify.display.mobile">
                        <template #activator="{ props }">
                            <vibeland-navbar-btn class="mx-1" size="large" color="black" append-icon="mdi-chevron-down"
                                text="Minha conta" border variant="outlined" :hover="{ variant: 'elevated' }" :="props">
                            </vibeland-navbar-btn>
                        </template>

                        <template #default>
                            <vibeland-navbar-user-menu></vibeland-navbar-user-menu>
                        </template>
                    </v-menu>
                </template>

                <v-spacer></v-spacer>

            </template>

        </vibeland-navbar>

        <vibeland-navbar-bottom v-if="$vuetify.display.mobile" grow color="primary" bg-color="white">

        </vibeland-navbar-bottom>


        <v-main class="h-100" name="main">

            <slot></slot>

            <vibeland-footer></vibeland-footer>

        </v-main>
    </v-app>





</template>

<style lang="scss" scoped></style>
