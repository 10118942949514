<script setup>

    import { mergeProps } from 'vue';

    defineOptions({
        inheritAttrs: false,
    });

    const props = defineProps({
        hover: Object
    });

    const defaultMenuProps = {
        openOnHover: true,
        contentProps: {
            style: {
                left: 0,
                maxWidth: '100%',
                width: '100%'
            },
        },
        transition: 'slide-y-transition',
        offset: 20,
    };

</script>

<template>
    <v-menu :="mergeProps(defaultMenuProps, $attrs)">

        <template #activator="activatorSlotProps">
            <slot name="activator" :="activatorSlotProps"></slot>
        </template>

        <template #default="defaultSlotProps">
            <slot name="menu" :="defaultSlotProps"></slot>
        </template>
    </v-menu>

</template>