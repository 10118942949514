
        import Enum from '../Helpers/Enum';

        export default class PersonalDocumentType extends Enum {
                            static get BR_CPF() {
                    return new class PersonalDocumentType {
                        id = 'BR_CPF';
                        value = 'br_cpf';

                                                                        country = 'br';
                                                                        name = 'enums.personalDocumentType.br_cpf';
                                                                        mask = '###.###.###-##';
                                                                        length = 11;
                                            };
                }
                            static get BR_CNPJ() {
                    return new class PersonalDocumentType {
                        id = 'BR_CNPJ';
                        value = 'br_cnpj';

                                                                        country = 'br';
                                                                        name = 'enums.personalDocumentType.br_cnpj';
                                                                        mask = '##.###.###\/####-##';
                                                                        length = 14;
                                            };
                }
                    };
    