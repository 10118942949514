<script setup>
    import { useWindowScroll } from '@vueuse/core';
    import { computed, ref, shallowRef } from 'vue';

    const props = defineProps({
        _global: Object,
        _user: Object,
        calculator: Object
    });

    const { y } = useWindowScroll();

    const navbarBgColorOpacity = shallowRef();

    const navbarBgColor = computed(() => {

        if (navbarBgColorOpacity.value === true) {
            return 'white';
        }

        let opacity = (y.value / 1000) * 3;
        opacity = opacity > 1 ? 1 : opacity;

        return `rgba(255,255,255, ${opacity})`;
    });

    const slideOne = [
        { imageSrc: '/images/event04.webp', },
        { imageSrc: '/images/event05.webp', },
        { imageSrc: '/images/event07.webp', },
    ];

    const firstSpacer = ref();

    const firstSpacerWidth = computed(() => {
        if (!firstSpacer.value) {
            return 0;
        }

        return firstSpacer.value?.$el?.scrollWidth || 0;
    });

</script>

<template>

    <v-app>
        <x-support></x-support>

        <vibeland-navbar :height="$vuetify.display.mobile ? 80 : 100" :flat="!y" :color="navbarBgColor">

            <template #mobile>
                <vibeland-navbar-logo :current="y > 1 ? 1 : 0" style="width: 200px;"
                    :images="['/images/brand/logo-black-512.png', '/images/brand/icon-black-512.png']"></vibeland-navbar-logo>
            </template>

            <template #default>
                <v-spacer ref="firstSpacer"></v-spacer>

                <vibeland-navbar-btn class="mx-1" size="large" color="black" variant="text" text="Início"
                    :to="$route('app-site.home')" :hover="{ variant: 'elevated' }">
                </vibeland-navbar-btn>


                <vibeland-navbar-btn-menu open-delay="100" @update:modelValue="(v) => navbarBgColorOpacity = v">

                    <template #activator="{ props }">
                        <vibeland-navbar-btn class="mx-1" size="large" color="black" variant="text" text="Preços"
                            :to="$route('app-site.pricing')" :="props"
                            :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>
                    </template>

                    <template #menu>
                        <vibeland-pricing-submenu
                            :style="`padding-left: ${firstSpacerWidth}px !important;`"></vibeland-pricing-submenu>
                    </template>
                </vibeland-navbar-btn-menu>

                <vibeland-navbar-btn class="mx-1" size="large" color="black" variant="text" text="News"
                    :href="$route('news.home')" :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>

                <v-spacer></v-spacer>

                <vibeland-navbar-logo :current="y > 1 ? 1 : 0" style="width: 250px;"
                    :images="['/images/brand/logo-black-512.png', '/images/brand/icon-black-512.png']"></vibeland-navbar-logo>

                <v-spacer></v-spacer>

                <vibeland-navbar-btn class="mx-1" variant="text" color="black" icon :hover="{ variant: 'elevated' }">
                    <vibeland-navbar-search></vibeland-navbar-search>
                </vibeland-navbar-btn>

                <template v-if="!_user">

                    <vibeland-navbar-btn class="mx-1" size="large" color="black" text="Cadastre-se" variant="text"
                        :to="$route('app-site.user.create')" :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>

                    <vibeland-navbar-btn class="mx-1" size="large" color="black" text="Faça Login" variant="outlined"
                        :to="$route('app-site.user.login')" :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>

                </template>
                <template v-else>

                    <v-menu :open-on-hover="!$vuetify.display.mobile">
                        <template #activator="{ props }">
                            <vibeland-navbar-btn class="mx-1" size="large" color="black" append-icon="mdi-chevron-down"
                                text="Minha conta" variant="outlined" :hover="{ variant: 'elevated' }" :="props">
                            </vibeland-navbar-btn>
                        </template>

                        <template #default>
                            <vibeland-navbar-user-menu></vibeland-navbar-user-menu>
                        </template>
                    </v-menu>
                </template>

                <v-spacer></v-spacer>

            </template>

        </vibeland-navbar>

        <vibeland-navbar-bottom v-if="$vuetify.display.mobile" grow color="primary" bg-color="white">

        </vibeland-navbar-bottom>


        <v-parallax src="/images/brand/bg-yellow-landscape.png" style="height: 100vh;">
            <v-row justify="center" align="center" class="h-100 mt-15 mt-lg-0">
                <v-col cols="12" lg="4" offset="1" offset-lg="0">
                    <h1 class="text-h2 font-weight-bold text-lg-h1 position-lg-relative"
                        :style="{ zIndex: 1000, ...($vuetify.display.mobile ? {} : { position: 'relative', right: '-200px' }) }">
                        Crie, venda, comunique, organize eventos e cursos
                    </h1>
                </v-col>

                <v-col cols="12" lg="8">
                    <v-carousel cycle :show-arrows="false" continuous hide-delimiters style="width: 100%;"
                        :height="$vuetify.display.height * 0.75">
                        <v-carousel-item v-for="(xSlide, xSlideIndex) in slideOne" :key="xSlideIndex"
                            :src="xSlide.imageSrc" cover scrim
                            gradient="to right, rgba(255,255,255,0.4), rgba(255,255,255,0)"></v-carousel-item>
                    </v-carousel>
                </v-col>

            </v-row>
        </v-parallax>

        <v-main style="--v-layout-top: 0px !important;">

            <v-container fluid class="pa-10 bg-vibeland-blue">

                <v-row justify="center" class="h-100">
                    <v-col cols="12" lg="8">
                        <v-row>
                            <v-col cols="11" lg="8">
                                <h2 class="text-h2 text-lg-h1 pa-3 font-weight-bold mb-5">Participe e se inspire!!</h2>
                            </v-col>

                            <v-col cols="12">
                                <div class="py-10">
                                    <v-row align="center" justify="space-around">
                                        <v-col cols="12" lg="4">
                                            <v-img src="/images/event08.webp" class="mx-auto border w-100">
                                            </v-img>
                                            Beyound Summit
                                        </v-col>
                                        <v-col cols="12" lg="3">
                                            <v-img src="/images/event04.webp" class="mx-auto border w-100">
                                            </v-img>
                                            DJ's for light party
                                        </v-col>
                                        <v-col cols="12" lg="4">
                                            <v-img src="/images/event07-portrait.webp" class="mx-auto border w-100"
                                                :style="{ marginTop: $vuetify.display.mobile ? undefined : '-100px' }">
                                            </v-img>
                                            Viagem de meditação na Chapada 2023
                                        </v-col>
                                    </v-row>
                                </div>

                            </v-col>

                            <v-col cols="12" lg="4">
                                <h4 class="text-h5 pa-3">
                                    A {{ _global.appName }} é o sistema mais criativo
                                    para produtores e criativos venderem
                                    seus eventos, projetos, conferências,
                                    cursos e compartilhar tudo de forma
                                    inovadora para seus maiores fãs.
                                </h4>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>

            </v-container>

            <vibeland-footer></vibeland-footer>

        </v-main>

        <v-fab v-if="_global?.whatsapp_link" icon="mdi-whatsapp" :href="_global?.whatsapp_link"
            :style="$vuetify.display.mobile ? undefined : { left: '-30px', bottom: '30px' }" app appear
            :size="$vuetify.display.mobile ? 'large' : 'x-large'" color="green" location="bottom end"></v-fab>
    </v-app>



</template>

<style lang="scss" scoped></style>
