<script setup>

    import { computed, shallowRef, reactive, inject, onMounted, toRaw } from 'vue';

    const props = defineProps({
        event: Object,
        // selected: {
        //     type: String,
        // }
    });

    const emits = defineEmits(['cancel']);

    const route = inject('route');

    const ticketForm = reactive({
        item_type: 'ticket',
        item_id: null,
    });

    const formProcessing = shallowRef(false);

    const formAction = computed(() => {
        return route('checkoutv2.start', ticketForm);
    });

    const total = computed(() => {
        if (!ticketForm.item_id) {
            return 0;
        }

        const selectedTicketBatch = (props.event?.ticketTypes || []).find((xTicketType) => xTicketType?.activeBatch?.uuid === ticketForm.item_id);

        return selectedTicketBatch?.activeBatch?.unitPrice || 0;
    });

    const ticketOptions = toRaw((props.event?.ticketTypes || [])
        .filter(({ activeBatch }) => Boolean(activeBatch))
        .map(({ uuid, name, activeBatch }) => ({
            ticketTypeUuid: uuid,
            ticketTypeName: name,
            ticketBatchUuid: activeBatch?.uuid,
            ticketBatchUnitPrice: activeBatch.unitPrice,
            ticketBatchSoldOff: activeBatch?.soldOff,
        })));

    onMounted(() => {

        // if (props?.selected) {
        //     ticketForm.item_id = ticketOptions.find(({ ticketTypeUuid }) => ticketTypeUuid === props.selected)?.ticketBatchUuid || null;
        // }
    });

</script>

<template>

    <v-card>

        <v-dialog :model-value="formProcessing" width="auto" persistent scrim="black" opacity="0.7">

            <v-sheet color="white" class="pa-10">
                <v-progress-circular indeterminate color="primary" class="d-block mx-auto mb-5"></v-progress-circular>

                <div class="text-center">
                    Um momento, estamos preparando seu checkout e reservando seu ingresso...
                </div>
            </v-sheet>
        </v-dialog>

        <template #title>
            <v-card-title class="text-primary">
                Entradas:
                <div class="float-right">
                    {{ $locale.formatCurrency(total) }}
                </div>
            </v-card-title>
        </template>

        <template #append>
            <v-btn v-if="$vuetify.display.mobile" icon="mdi-close" @click="() => emits('cancel')"></v-btn>
        </template>

        <v-radio-group :model-value="ticketForm.item_id" hide-details class="px-lg-2 pb-lg-2 px-3">

            <v-list :density="$vuetify.display.mobile ? 'compact' : 'default'" variant="plain" max-height="50vh">

                <template v-if="ticketOptions.length">
                    <v-list-item v-for="xOption in ticketOptions" :key="xOption.ticketBatchUuid"
                        :value="xOption.ticketBatchUuid" :disabled="xOption.ticketBatchSoldOff"
                        @click="(v) => ticketForm.item_id = xOption.ticketBatchUuid" class="ps-0">

                        <template #title>
                            <v-list-item-title class="text-uppercase px-lg-2" style="text-wrap: wrap;">
                                <b v-if="xOption.ticketBatchSoldOff">
                                    (esgotado)
                                </b>
                                {{ xOption.ticketTypeName }}
                            </v-list-item-title>
                        </template>

                        <template #append>
                            {{ $locale.formatCurrency(xOption.ticketBatchUnitPrice, 'Gratuito') }}
                        </template>

                        <template #prepend>

                            <v-radio :value="xOption.ticketBatchUuid" class="me-1 mb-1"></v-radio>

                        </template>
                    </v-list-item>

                </template>

                <div v-else class="font-weight-light w-100 text-center my-5">
                    Sem ingressos disponíveis.
                </div>
            </v-list>

        </v-radio-group>

        <v-card-actions class="mb-3 pt-5 pt-lg-0">

            <v-btn text="Ir para o checkout" prepend-icon="mdi-cart" size="large" block variant="flat" color="primary"
                :disabled="!ticketForm.item_id" :loading="formProcessing" :href="formAction"
                @click.prevent="($e) => { formProcessing = true; $window.location.href = formAction; }"></v-btn>

        </v-card-actions>
    </v-card>


</template>