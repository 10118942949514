<script setup>

    import { useClipboard } from '@vueuse/core';

    defineOptions({
        inheritAttrs: false
    });

    const props = defineProps({
        content: String,
        copiedText: String,
        tag: String
    });

    const { copy, copied } = useClipboard();

    const handler = async () => {
        await copy(props.content);
    }

</script>

<template>
    <v-tooltip :text="copied ? (props.copiedText ? props.copiedText : 'Copiado!') : 'Clique para copiar'"
        open-delay="150">
        <template #activator="{ props: tooltipActivatorProps }">
            <slot :="{ props: { onClick: handler, role: 'button', ...tooltipActivatorProps }, copied }">
                <component :is="$props.tag ? $props.tag : 'span'" @click="handler" role="button"
                    :="tooltipActivatorProps" v-text="$props.content"></component>
            </slot>
        </template>
    </v-tooltip>
</template>
