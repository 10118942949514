
        import Enum from '../Helpers/Enum';

        export default class CollaboratorRole extends Enum {
                            static get EventAccreditor() {
                    return new class CollaboratorRole {
                        id = 'EventAccreditor';
                        value = 'event-accreditor';

                                            };
                }
                    };
    