
import {
    createInertiaApp,
    router,
    Head as InertiaHeadComponent,
    Link as InertiaLinkComponent,
    usePage
} from '@inertiajs/vue3';
import { computed, createApp as createVueApp, h } from 'vue';
import Env from '../../js/Helpers/Env';
import DebugPlugin from '../Plugins/Debug';
import { notify, Flash } from './Flash';

// styles
import '../../scss/module.scss';
import InertiaRouterLink from './InertiaRouterLink';

const defaultProgress = {
    delay: 0,
    showSpinner: false,
    color: 'rgba(var(--v-theme-primary))',
};

export function createInertiaModule({ pageComponentResolver, progress }, touchCallback) {
    return createInertiaApp({
        progress: progress || defaultProgress,
        resolve: pageComponentResolver,

        setup: function ({
            el: rootElement,
            App: inertiaApp,
            props: inertiaProps,
            plugin: inertiaPlugin,
        }) {
            const vueApp = createVueApp({
                render: () => h(inertiaApp, inertiaProps),
            });

            // Flash
            vueApp.use(new Flash);

            // INERTIA
            vueApp.use(inertiaPlugin);
            vueApp.component('inertia-link', InertiaLinkComponent);
            vueApp.component('RouterLink', InertiaRouterLink);

            router.on('start', function () {
                notify().toast.clear();
            });

            router.on('navigate', function (event) {
                if (event.detail.page.props._flash) {
                    notify().open(event.detail.page.props._flash);
                }
            });
            router.on('success', function (event) {
                if (event.detail.page.props._flash) {
                    notify().open(event.detail.page.props._flash);
                }
            });
            router.on('error', function (event) {
                notify().error(Object.values(event.detail.errors)[0]);
            });
            router.on('invalid', function (event,) {
                if (!Env.DEBUG) {
                    event.preventDefault();
                }

                if (vueApp.config.globalProperties.$t && event?.detail?.response?.status >= 400) {
                    notify().error(`Oops! erro ${event.detail.response.status}(${vueApp.config.globalProperties.$t('http-status.' + event.detail.response.status)})`);
                    return;
                }
                else {
                    notify().error('Não foi possível fazer a requisição, tente novamente (invalid response)');
                }

            });
            router.on('exception', function (event) {
                notify().error('Não foi possível fazer a requisição, tente novamente (exception)');
            });







            touchCallback({ vueApp, inertiaProps });




            // DEBUG
            vueApp.use(new DebugPlugin);

            vueApp.mount(rootElement);

            delete rootElement.dataset.page;

            if (Env.DEBUG) {
                console.log('app:mounted', inertiaProps.initialPage.props, { vueApp });
            }

        },
    });

}
