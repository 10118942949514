<script setup>
import { router } from '@inertiajs/vue3';
import { inject } from 'vue';

const route = inject('route');

</script>

<template>
    <div class="d-flex" style="justify-content: center; flex-direction: column; align-items: center; height: 90vh; ">
        <v-card width="100%" max-width="500" class="mx-auto mt-10 pa-10" elevation="2" border>
            <v-card-title class="mb-5">Confirme seu e-mail para continuar</v-card-title>

            <v-card-text>
                <p>
                    Um e-mail foi enviado para
                    <x-mail :mail="$page.props._user.email"></x-mail>.
                    Clique no link do e-mail para confirmar seu cadastro.
                </p>
            </v-card-text>

            <div class="text-center w-75 mx-auto my-5">
                <v-btn color="primary" prepend-icon="mdi-reload" class="mb-3" @click="$window.location.reload()">
                    Recarregar página
                </v-btn>

                <v-btn color="primary" prepend-icon="mdi-send" class="mb-3"
                    :to="{ action: $route('verification.send'), method: 'post' }">
                    Reenviar e-mail
                </v-btn>

                <v-btn color="primary" prepend-icon="mdi-logout"
                    :to="{ action: $route('app-site.user.logout'), method: 'post' }">
                    Desconectar
                </v-btn>


            </div>
        </v-card>
    </div>
</template>
