
        import Enum from '../Helpers/Enum';

        export default class Currency extends Enum {
                            static get USD() {
                    return new class Currency {
                        id = 'USD';
                        value = 'usd';

                                                                        symbol = 'US$';
                                                                        country = 'us';
                                                                        name = 'enums.currency.usd';
                                            };
                }
                            static get BRL() {
                    return new class Currency {
                        id = 'BRL';
                        value = 'brl';

                                                                        symbol = 'R$';
                                                                        country = 'br';
                                                                        name = 'enums.currency.brl';
                                            };
                }
                    };
    