
<script setup>
import { useVModel } from '@vueuse/core';
import { vMaska } from 'maska';
import { computed, watch } from 'vue';

const props = defineProps({
    modelValue: String,
    options: {
        type: Object,
        required: true
    },
    masked: Boolean
});

const emits = defineEmits(['update:modelValue', 'completed']);

const model = useVModel(props, 'modelValue', emits);

const maskOptions = computed(() => ({
    onMaska: function ({ masked, unmasked, completed }) {
        model.value = props.masked ? masked : unmasked;

        if (completed) {
            emits('completed', { masked, unmasked });
        }
    },
    ...props.options
}));

</script>

<template>
    <v-text-field :model-value="model" v-maska:[maskOptions]>
        <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
            <slot :name="slot" :="scope"></slot>
        </template>
    </v-text-field>
</template>
