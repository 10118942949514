<script setup>
import { useForm } from '@inertiajs/vue3';
import { useUrlSearchParams } from '@vueuse/core';

defineProps(['errors']);

const recoveryPassword = useForm({
    email: useUrlSearchParams()?.email || '',
});

</script>

<template>
    <v-container class="pb-15">
        <h2 class="my-10 text-center">Recuperar senha</h2>

        <v-row justify="center">
            <v-col cols="12" lg="4">
                <v-card border flat class="pa-5">
                    <v-card-title class="mb-5 text-center">
                        Informe o seu e-mail para continuar
                    </v-card-title>

                    <v-card-text>
                        Nós te enviaremos uma notificação com um link. Clique no link e crie uma nova senha.
                    </v-card-text>

                    <v-card-text>
                        <x-form :action="$route('app-site.user.forget-password-submit')" :form="recoveryPassword"
                            #="{ activatorProps }">

                            <v-text-field v-model="recoveryPassword.email" type="email" label="E-mail" name="email"
                                prepend-inner-icon="mdi-email" :rules="[$requiredRule]" class="mb-3"
                                :error-messages="errors?.email" autocomplete="email">
                            </v-text-field>

                            <v-btn :="activatorProps" text="Continuar" block size="large" color="primary"
                                class="mb-3"></v-btn>
                        </x-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
