<script setup>
    import { useForm } from '@inertiajs/vue3';
    import { useWindowScroll } from '@vueuse/core';
    import { computed, ref, shallowRef, toValue } from 'vue';
    import { useGoTo } from 'vuetify/lib/framework.mjs';

    const props = defineProps({
        _global: Object,
        _user: Object,
        calculator: Object
    });

    const { y } = useWindowScroll();

    const navbarBgColorOpacity = shallowRef();

    const navbarBgColor = computed(() => {

        if (navbarBgColorOpacity.value === true) {
            return 'black';
        }

        let opacity = (y.value / 1000) * 3;
        opacity = opacity > 1 ? 1 : opacity;

        return `rgba(0,0,0, ${opacity})`;
    });


    // calculator

    const calculatorPresets = computed(() => [
        {
            name: props._global.appName + ' Basic',
            fee: 8,
        },
        {
            name: props._global.appName + ' Black',
            fee: 10,
        },
        {
            name: 'Eventbrite Plano Essentials',
            fee: 6.99,
        },
        {
            name: 'Eventbrite Plano Professional',
            fee: 9.99,
        },
        {
            name: 'Sympla',
            fee: 12,
        },
    ]);

    const calculatorForm = useForm(toValue(() => props.calculator?.data || {}));


    // plan cards


    const defaultFeatures = [
        'Criação de ingressos ou inscrições',
        'Criação de Lotes com ou sem data',
        'Página de venda',
        'Controle e gestão das vendas',
        'Estatísticas',
    ];

    const futureFeatures = [
        'Página de doação',
        'Venda de produtos',
        'Agenda do evento',
    ];

    const mapFeatures = (features) => {
        return features.map((xFeatureName) => ({
            title: xFeatureName,
            props: {
                prependIcon: xFeatureName ? 'mdi-check' : undefined
            }
        })).concat(
            { title: 'EM BREVE', type: 'subheader' },
            futureFeatures.map((xFeatureName) => ({
                title: xFeatureName,
                props: {
                    prependIcon: 'mdi-clock'
                }
            }))
        );
    };

    const priceCards = [
        {
            planName: 'Gratuito',
            planDescription: 'Para eventos e projetos gratuitos',
            feePercentage: null,
            small: '',
            features: mapFeatures(defaultFeatures.concat('')),
        },
        {
            planName: 'Basic',
            planDescription: 'do valor das vendas que você efetua na Vibeland',
            feePercentage: 8,
            small: '* incluso as texas de processamento e antifraude dos pagamentos',
            features: mapFeatures(defaultFeatures.concat('')),
        }, {
            planName: 'Black',
            planDescription: 'do valor das vendas que você efetua na Vibeland',
            feePercentage: 10,
            small: '* incluso as texas de processamento e antifraude dos pagamentos',
            features: mapFeatures(defaultFeatures.concat('Domínio personalizado')),
        }
    ];

    const goTo = useGoTo({
        duration: 500,
    });



    const gatewayCards = [
        {
            title: 'Gateway de pagamentos confiável',
            description: 'A Vibeland fechou uma parceria com a Barte, um dos melhores Gateways do mercado, garantindo segurança nos pagamentos e privacidade nos dados além de uma taxa de 99,9% de sucesso'
        },
        {
            title: 'Aceitação global',
            description: 'Nosso sistema vem preparado para aceitar cartões de crédito do mundo inteiro, então se você organiza um evento onde espera receber pessoas de diversos países nossa plataforma garante a venda para elas.'
        },
        {
            title: 'Anti Fraudes 24/7',
            description: 'Com nosso gateway você não precisa se preocupar com fraudes, nosso sistema se preocupa com isso e não prejudica seu saldo'
        },
        {
            title: 'Diversos métodos de pagamento',
            description: 'A Vibeland aceita uma grande variedade de pagamentos: os principais cartões de crédito, Pix e boleto.',
        },
        {
            title: 'Compra simplificada e rápida',
            description: 'Uma experiência de compra fácil e rápida com um processamento ágil.'
        },
        {
            title: 'Recuperação de compras ou carrinho abandonado',
            description: 'A cada pedido que falhar ou que fo abandonado, nosso gateway vai entrar em contato com sou cliente para tentar resgatar e fechar a compra, diminuindo assim as vendas não concretizadas.'
        }
    ];

    const firstSpacer = ref();

    const firstSpacerWidth = computed(() => {
        if (!firstSpacer.value) {
            return 0;
        }

        return firstSpacer.value?.$el?.scrollWidth || 0;
    });

</script>

<template>

    <v-app>

        <x-support></x-support>


        <vibeland-navbar :height="$vuetify.display.mobile ? 80 : 100" :flat="!y" :color="navbarBgColor">

            <template #mobile>
                <vibeland-navbar-logo :current="y > 1 ? 1 : 0" style="width: 200px;"
                    :images="['/images/brand/logo-white-512.png', '/images/brand/icon-white-512.png']"></vibeland-navbar-logo>
            </template>

            <template #default>
                <v-spacer ref="firstSpacer"></v-spacer>

                <vibeland-navbar-btn class="mx-1" size="large" color="white" variant="text" text="Início"
                    :to="$route('app-site.home')" :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>



                <vibeland-navbar-btn-menu open-delay="100" @update:modelValue="(v) => navbarBgColorOpacity = v">

                    <template #activator="{ props }">
                        <vibeland-navbar-btn class="mx-1" size="large" color="white" variant="text" text="Preços"
                            :to="$route('app-site.pricing')" :="props"
                            :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>
                    </template>

                    <template #menu>
                        <vibeland-pricing-submenu color="black"
                            :style="`padding-left: ${firstSpacerWidth}px !important;`"></vibeland-pricing-submenu>
                    </template>
                </vibeland-navbar-btn-menu>

                <vibeland-navbar-btn class="mx-1" size="large" color="white" variant="text" text="News"
                    :href="$route('news.home')" :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>

                <v-spacer></v-spacer>

                <vibeland-navbar-logo :current="y > 1 ? 1 : 0" style="width: 250px;"
                    :images="['/images/brand/logo-white-512.png', '/images/brand/icon-white-512.png']"></vibeland-navbar-logo>

                <v-spacer></v-spacer>

                <vibeland-navbar-btn class="mx-3" variant="text" color="white" icon :hover="{ variant: 'elevated' }">
                    <vibeland-navbar-search></vibeland-navbar-search>
                </vibeland-navbar-btn>

                <template v-if="!_user">

                    <vibeland-navbar-btn class="mx-1" size="large" color="white" text="Cadastre-se" variant="text"
                        :to="$route('app-site.user.create')" :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>

                    <vibeland-navbar-btn class="mx-1" size="large" color="white" text="Faça Login" variant="outlined"
                        :to="$route('app-site.user.login')" :hover="{ variant: 'elevated' }"></vibeland-navbar-btn>

                </template>
                <template v-else>

                    <v-menu :open-on-hover="!$vuetify.display.mobile">
                        <template #activator="{ props }">
                            <vibeland-navbar-btn class="mx-1" size="large" color="white" append-icon="mdi-chevron-down"
                                text="Minha conta" variant="outlined" :hover="{ variant: 'elevated' }" :="props">
                            </vibeland-navbar-btn>
                        </template>

                        <template #default>
                            <vibeland-navbar-user-menu></vibeland-navbar-user-menu>
                        </template>
                    </v-menu>
                </template>

                <v-spacer></v-spacer>

            </template>

        </vibeland-navbar>


        <vibeland-navbar-bottom v-if="$vuetify.display.mobile" grow color="primary"></vibeland-navbar-bottom>


        <template v-if="$vuetify.display.mobile">
            <v-fab app appear size="80" variant="text" color="white"
                @click="() => y > 100 ? goTo(0) : goTo($vuetify.display.height)">
                <v-icon icon="mdi-chevron-down" class="rounded-circle "
                    :style="{ transition: 'transform 0.25s', ...(y > 100 ? { transform: 'rotate(180deg)' } : undefined) }"
                    size="80" :color="y > 100 ? 'black' : 'white'"></v-icon>
            </v-fab>
        </template>
        <template v-else>
            <v-fab app appear size="120" variant="text" color="white" style="bottom: 100px;left: -100px"
                @click="() => y > 100 ? goTo(0) : goTo($vuetify.display.height)">
                <v-icon icon="mdi-chevron-down" class="rounded-circle"
                    :style="{ transition: 'transform 0.25s', ...(y > 100 ? { transform: 'rotate(180deg)' } : { animation: 'animate-pulse 1s infinite' }) }"
                    size="120" :color="y > 100 ? 'black' : 'white'"></v-icon>
            </v-fab>
        </template>


        <v-parallax src="/images/event04-large.jpg" style="height: 100vh;" class="header-parallax text-white bg-black">
            <v-row justify="start" align="center" class="h-100 mt-16 mt-lg-0">

                <v-col cols="11" lg="6" offset="1">
                    <h1 class="text-h2 font-weight-bold text-lg-h1 mb-10">
                        Crie sua conta {{ _global.appName }}. É grátis
                    </h1>

                    <h3 class="text-h5 text-lg-h3">
                        A {{ _global.appName }} é o sistema mais criativo
                        para produtores e criativos venderem
                        seus eventos, projetos, conferências,
                        cursos e compartilhar tudo de forma
                        inovadora para seus maiores fãs.
                    </h3>
                </v-col>
            </v-row>
        </v-parallax>

        <v-main style="--v-layout-top: 100px !important;">


            <v-container fluid class="bg-vibeland-blue pa-lg-10"
                :style="{ height: $vuetify.display.mobile ? undefined : '100vh' }">

                <v-row justify="center" class="h-100" align="center">

                    <v-col cols="12" lg="10">
                        <v-row class="py-10" justify="center">
                            <v-col cols="12" lg="5">
                                <h2 class="text-h3 text-lg-h1 font-weight-bold">Conheça a {{ _global.appName }}</h2>
                            </v-col>
                            <v-col cols="12" lg="5" align-self="end">
                                <h4 class="text-h4">
                                    Ao se inscrever na {{ _global.appName }}, você obtém acesso instantâneo a um
                                    poderoso
                                    conjunto de
                                    recursos
                                    gratuitamente:
                                </h4>
                            </v-col>
                        </v-row>

                        <v-row v-if="false" class="mt-15">
                            <v-col v-for="x in 6" cols="12" lg="4">
                                <v-card title="Feature card" hover class="pa-5 ma-5">
                                    <v-card-text>
                                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi quaerat
                                        vel
                                        inventore?
                                        Omnis odio dolor ea, nobis assumenda impedit harum? Dignissimos placeat
                                        esse
                                        veniam sint
                                        aperiam maiores similique a rerum?
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row>
                    </v-col>

                </v-row>
            </v-container>

            <v-container fluid class="bg-vibeland-yellow pa-lg-10" style="padding-bottom: 150px !important;">
                <v-row justify="center" class="h-100" align="center">

                    <v-col cols="12" lg="10">
                        <v-row class="py-10" justify="center">
                            <v-col cols="12" lg="5">
                                <h2 class="text-h1 font-weight-bold">Nossos preços</h2>
                            </v-col>
                            <v-col cols="12" lg="5" align-self="end">
                                <h4 class="text-h4">
                                    Ao se inscrever na {{ _global.appName }}, você obtém acesso instantâneo a um
                                    poderoso
                                    conjunto de
                                    recursos
                                    gratuitamente:
                                </h4>
                            </v-col>
                        </v-row>

                        <v-row class="mt-15">

                            <v-col v-for="(xPriceCard, xPriceCardIndex) in priceCards" :key="xPriceCardIndex" cols="12"
                                lg="4">

                                <v-card hover class="pt-10 px-lg-10 pb-3 ma-lg-5 h-100" color="white">
                                    <v-card-text>
                                        <div class="text-h2 text-center py-3 mb-lg-10 font-weight-bold">
                                            {{ xPriceCard.planName }}
                                        </div>

                                        <v-row v-if="xPriceCard.feePercentage" justify="center" class="mb-lg-5"
                                            align="center">
                                            <v-col cols="12" lg="4">
                                                <div class="font-weight-bold text-h2 text-center">
                                                    {{ xPriceCard.feePercentage }}%
                                                </div>
                                            </v-col>
                                            <v-col cols="12" lg="5" class="text-center">
                                                <span>
                                                    <p class="text-body-1 font-weight-bold mb-1">
                                                        {{ xPriceCard.planDescription }}
                                                    </p>

                                                    <small>{{ xPriceCard.small }}</small>
                                                </span>
                                            </v-col>
                                        </v-row>

                                        <v-row v-else justify="center" class="mb-lg-5" align="center">
                                            <v-col cols="12" lg="8" class="text-h5 text-center">
                                                <span class="font-weight-bold">
                                                    {{ xPriceCard.planDescription }}

                                                    <small>{{ xPriceCard.small }}</small>
                                                </span>
                                            </v-col>
                                        </v-row>

                                        <v-list bg-color="transparent" class="" lines="one" variant="plain">
                                            <v-list-item v-for="(xFeature, xFeatureIndex) in xPriceCard.features"
                                                :key="xFeatureIndex"
                                                :title="$vuetify.display.mobile ? undefined : xFeature.title"
                                                :subtitle="$vuetify.display.mobile ? xFeature.title : undefined"
                                                :="xFeature.props">
                                            </v-list-item>
                                        </v-list>

                                    </v-card-text>

                                </v-card>

                            </v-col>

                        </v-row>
                    </v-col>

                </v-row>
            </v-container>

            <v-container fluid class="bg-vibeland-yellow pa-10" style="padding-bottom: 150px !important;"
                id="calculadora">

                <v-row justify="center">
                    <v-col cols="12" lg="10">
                        <v-row class="py-lg-10" justify="center">
                            <v-col cols="12" lg="5">
                                <h2 class="text-h3 text-lg-h1 font-weight-bold">Nossa calculadora</h2>
                            </v-col>
                            <v-col cols="12" lg="5" align-self="end">
                                <h4 class="text-h4">
                                    Com a calculadora você pode comparar o resultado de vendas com os principais
                                    sistemas do
                                    mercado
                                </h4>
                            </v-col>
                        </v-row>

                        <v-row justify="space-evenly" align="center">
                            <v-col cols="12" lg="5">
                                <v-card hover class="pa-3 pa-lg-10">
                                    <v-card-text>
                                        <x-form :form="calculatorForm" #="{ activatorProps }"
                                            :options="{ preserveState: true, preserveScroll: true }">
                                            <v-select label="Sua escolha" v-model="calculatorForm.fee"
                                                :items="calculatorPresets" :item-title="({ fee, name }) => `${name} - ${fee}%`
                                                    " item-value="fee"></v-select>

                                            <x-input-integer v-model="calculatorForm.items_sold"
                                                label="N° de unidades vendidas"></x-input-integer>

                                            <x-input-money v-model="calculatorForm.item_unit_price"
                                                label="Preço da unidade"></x-input-money>

                                            <v-btn :="activatorProps" :disabled="!calculatorForm.isDirty"
                                                text="Calcular" color="primary" size="large" block
                                                prepend-icon="mdi-check"></v-btn>
                                        </x-form>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" lg="5">
                                <v-card hover class="pa-3 pa-lg-10">
                                    <v-card-text>
                                        <p class="text-h4 text-center my-10">
                                            Com
                                            {{ $locale.formatNumber(calculator?.data?.items_sold || 0) }}
                                            venda(s), você recebe:
                                        </p>

                                        <p class="text-h4 text-center">
                                            Você recebe:
                                            {{ $locale.formatCurrency(calculator?.totalAmount || 0) }}
                                        </p>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                        </v-row>
                    </v-col>
                </v-row>

            </v-container>


            <v-container fluid class="bg-grey-lighten-2 pa-lg-10" style="padding-bottom: 10rem !important;">

                <v-row justify="center" class="h-100" align="center">

                    <v-col cols="12" lg="10">
                        <v-row class="py-10" justify="center">
                            <v-col cols="12" lg="5">
                                <h2 class="text-h3 text-lg-h1 font-weight-bold">Gateway de pagamento</h2>
                            </v-col>
                            <v-col cols="12" lg="5" align-self="end">
                                <h4 class="text-h4">
                                    Nosso sistema de pagamento processa centena de milhões de Reais por mês. Veja abaixo
                                    como
                                    garantimos que você receba seus pagamentos com segurança
                                </h4>
                            </v-col>
                        </v-row>

                        <v-row class="mt-15">
                            <v-col v-for="x in gatewayCards" cols="12" lg="4">
                                <v-card hover class="pa-10 ma-5 h-100">
                                    <v-row align-content="center" class="h-100">
                                        <v-col cols="12" class="text-h4">
                                            <span>
                                                {{ x.title }}
                                            </span>
                                        </v-col>
                                        <v-col cols="12" class="text-h6">
                                            {{ x.description }}
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>

                        </v-row>

                        <v-row justify="center" class="mt-10">
                            <v-col cols="10" lg="8">
                                <v-img src="/images/payments/cards.png" class="w-100"></v-img>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>
            </v-container>


            <vibeland-footer></vibeland-footer>

        </v-main>

        <v-fab v-if="_global?.whatsapp_link" icon="mdi-whatsapp" :href="_global?.whatsapp_link"
            :style="$vuetify.display.mobile ? undefined : { left: '-30px', bottom: '30px' }" app appear
            :size="$vuetify.display.mobile ? 'large' : 'x-large'" color="green" location="bottom end"></v-fab>


    </v-app>



</template>

<style lang="css" scoped>
    .header-parallax :deep(img) {
        opacity: 0.4;
    }
</style>
