
        import Enum from '../Helpers/Enum';

        export default class EventCategory extends Enum {
                            static get MuseumsExhibitions() {
                    return new class EventCategory {
                        id = 'MuseumsExhibitions';
                        value = 'museums-exhibitions';

                                                                        name = 'Museus e exposi\u00e7\u00f5es';
                                                                        mdColor = 'deep-orange';
                                            };
                }
                            static get CoursesWorkshops() {
                    return new class EventCategory {
                        id = 'CoursesWorkshops';
                        value = 'courses-workshops';

                                                                        name = 'Cursos e Workshops';
                                                                        mdColor = 'pink-darken-4';
                                            };
                }
                            static get PartiesShows() {
                    return new class EventCategory {
                        id = 'PartiesShows';
                        value = 'parties-shows';

                                                                        name = 'Festas e Shows';
                                                                        mdColor = 'red';
                                            };
                }
                            static get TheatersSpectacle() {
                    return new class EventCategory {
                        id = 'TheatersSpectacle';
                        value = 'theaters-spectacle';

                                                                        name = 'Teatros e Espet\u00e1culos';
                                                                        mdColor = 'deep-purple-accent-2';
                                            };
                }
                            static get Sport() {
                    return new class EventCategory {
                        id = 'Sport';
                        value = 'sport';

                                                                        name = 'Esporte';
                                                                        mdColor = 'blue-darken-2';
                                            };
                }
                            static get ConferencesLectures() {
                    return new class EventCategory {
                        id = 'ConferencesLectures';
                        value = 'conferences-lectures';

                                                                        name = 'Congressos e Palestras';
                                                                        mdColor = 'blue-grey';
                                            };
                }
                            static get Tours() {
                    return new class EventCategory {
                        id = 'Tours';
                        value = 'tours';

                                                                        name = 'Passeios e Tours';
                                                                        mdColor = 'green';
                                            };
                }
                            static get Gastronomy() {
                    return new class EventCategory {
                        id = 'Gastronomy';
                        value = 'gastronomy';

                                                                        name = 'Gastronomia';
                                                                        mdColor = 'cyan';
                                            };
                }
                            static get GamesGeek() {
                    return new class EventCategory {
                        id = 'GamesGeek';
                        value = 'games-geek';

                                                                        name = 'Games e Geek';
                                                                        mdColor = 'deep-purple';
                                            };
                }
                            static get HealthWellness() {
                    return new class EventCategory {
                        id = 'HealthWellness';
                        value = 'health-wellness';

                                                                        name = 'Sa\u00fade e Bem Estar';
                                                                        mdColor = 'green-accent-3';
                                            };
                }
                            static get ArtCulture() {
                    return new class EventCategory {
                        id = 'ArtCulture';
                        value = 'art-culture';

                                                                        name = 'Arte, Cultura';
                                                                        mdColor = 'teal';
                                            };
                }
                            static get Kids() {
                    return new class EventCategory {
                        id = 'Kids';
                        value = 'kids';

                                                                        name = 'Infantil';
                                                                        mdColor = 'light-blue';
                                            };
                }
                            static get ReligionSpirituality() {
                    return new class EventCategory {
                        id = 'ReligionSpirituality';
                        value = 'religion-spirituality';

                                                                        name = 'Religi\u00e3o e Espiritualidade';
                                                                        mdColor = 'brown';
                                            };
                }
                            static get FashionBeauty() {
                    return new class EventCategory {
                        id = 'FashionBeauty';
                        value = 'fashion-beauty';

                                                                        name = 'Moda e Beleza';
                                                                        mdColor = 'purple-accent-2';
                                            };
                }
                            static get ArtCultureLeisure() {
                    return new class EventCategory {
                        id = 'ArtCultureLeisure';
                        value = 'art-culture-leisure';

                                                                        name = 'Arte, Cultura e Lazer';
                                                                        mdColor = 'teal';
                                            };
                }
                    };
    