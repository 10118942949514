
// review
import ConfirmDialogComponent from '../Components/ConfirmDialog.vue';
import ScrollToTopComponent from '../Components/ScrollToTop.vue';

// ok
import MailComponent from '../Components/Mail.vue';
import BooleanChipComponent from '../Components/BooleanChip.vue';
import EnumChipComponent from '../Components/EnumChip.vue';
import CopyableComponent from '../Components/Copyable.vue';
import CopyableInputComponent from '../Components/CopyableInput.vue';
import StepProgressComponent from '../Components/StepProgressComponent.vue';
import PaginationComponent from '../Components/Pagination.vue';
import DatatableComponent from '../Components/Datatable.vue';
import TimelineEventsComponent from '../Components/TimelineEvents.vue';
import ObjectComponent from '../Components/Debug/Object.vue';

import { clipboard, maskFormat, truncate } from '../Directives';

export default class GlobalUtils {
    constructor() { }


    install(vueApp, options) {

        // OK
        vueApp.component('x-scroll-to-top', ScrollToTopComponent);
        vueApp.component('x-dialog-confirmation', ConfirmDialogComponent);
        vueApp.component('x-step-progress', StepProgressComponent);
        vueApp.component('x-copyable', CopyableComponent);
        vueApp.component('x-input-copyable', CopyableInputComponent);
        vueApp.component('x-mail', MailComponent);
        vueApp.component('x-chip-enum', EnumChipComponent);
        vueApp.component('x-chip-boolean', BooleanChipComponent);
        vueApp.component('x-pagination', PaginationComponent);
        vueApp.component('x-datatable', DatatableComponent);
        vueApp.component('x-timeline-events', TimelineEventsComponent);
        vueApp.component('x-object', ObjectComponent);

        // directives
        vueApp.directive('x-clipboard', clipboard);
        vueApp.directive('x-mask-format', maskFormat);
        vueApp.directive('x-truncate', truncate);



        // <template v-for="(_, slotName) in $slots" #[slotName]="scope">
        // <slot :name="slotName" :="scope || {}"></slot>
        // </template>
    }
}
