<script setup>

    import { computed } from 'vue';
    import GoogleLoginButton from './GoogleLoginButton.vue';
    import FacebookLoginButton from './FacebookLoginButton.vue';

    const props = defineProps({
        drivers: Object,
    });

    const google = computed(function () {
        return props.drivers?.google ? props.drivers.google : false;
    });

    const facebook = computed(function () {
        return props.drivers?.facebook ? props.drivers.facebook : false;
    });

</script>

<template>
    <v-container>
        <v-row v-if="google" justify="center">
            <v-col cols="auto">
                <GoogleLoginButton :="google">
                </GoogleLoginButton>
            </v-col>
        </v-row>

        <v-row v-if="facebook" justify="center">
            <v-col cols="auto">
                <FacebookLoginButton :="typeof facebook === 'object' ? facebook : {}">
                </FacebookLoginButton>
            </v-col>
        </v-row>

        <v-row v-if="Object.keys(drivers).length" justify="center">
            <v-col cols="auto">
                <span class="font-weight-bold">
                    ou
                </span>
            </v-col>
        </v-row>
    </v-container>
</template>
