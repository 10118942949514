<script setup>

    import { useForm } from '@inertiajs/vue3';
    import { inject, computed, shallowRef } from 'vue';
    import { PersonalDocumentType } from '../../../../../../resources/js/Enums';

    const props = defineProps({
        errors: Object,
        _user: Object,
        tab: String,
    });

    const support = inject('support');
    const tab = shallowRef(props.tab || 'default');


    // Avatar

    const avatarForm = useForm({
        image: undefined,
    });

    const avatarChanged = () => {
        avatarForm.post(support.route('app-site.user.update', ['avatar']));
    };


    // Name
    const nameForm = useForm({
        name: props._user.name,
    });


    // Password
    const passwordForm = useForm({
        current_password: null,
        password: null,
        password_confirmation: null,
    });


    // Document
    const documentForm = useForm({
        type: props._user.documentType,
        number: props._user.documentNumber,
    });


    // Phone
    const phoneForm = useForm({
        country: props._user.country,
        phone: props._user.phone,
    });


    // Email
    const emailForm = useForm({
        email: props._user.email
    });

    const documentMask = computed(() => {

        if (!documentForm.type) {
            return
        }

        return PersonalDocumentType.tryFrom(documentForm.type).mask;
    });

</script>

<template>
    <v-container style="max-width: 1100px;" class="mx-auto pb-10">

        <v-breadcrumbs :items="[{ title: 'Início', to: $route('app-site.home') }, 'Editar meus dados']"
            class="mb-10"></v-breadcrumbs>


        <v-row justify="center">
            <v-col cols="12" lg="3">

                <x-input-image v-model="avatarForm.image" :width="$vuetify.display.mobile ? 100 : undefined"
                    :placeholder="$page.props._user?.avatar?.large_square || '/images/profile150.png'"
                    @update:modelValue="avatarChanged" class="rounded-circle border mx-auto" :aspect-ratio="1 / 1"
                    cover>
                    <template #overlay="{ fileDialog }">
                        <v-btn color="primary" icon="mdi-image-edit" @click="fileDialog.open"></v-btn>
                    </template>

                    <template #dialog="{ opened, imageProps, cancel, save }">
                        <v-dialog :model-value="opened" persistent :fullscreen="$vuetify.display.mobile"
                            :width="$vuetify.display.mobile ? undefined : 600">
                            <v-card>

                                <template #append>
                                    <v-btn icon="mdi-close" @click="cancel"></v-btn>
                                </template>

                                <v-card-text>
                                    <v-img :="imageProps" max-width="200"></v-img>

                                    <v-btn color="primary" prepend-icon="mdi-image-check" text="Salvar" class="my-2"
                                        block @click="save"></v-btn>
                                    <v-btn color="primary" prepend-icon="mdi-image-remove" variant="outlined"
                                        text="Cancelar" @click="cancel" block></v-btn>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </template>
                </x-input-image>

                <v-progress-linear v-if="avatarForm.processing" color="primary" height="10"
                    :model-value="avatarForm.progress?.percentage" class="mt-1" rounded>
                </v-progress-linear>
            </v-col>

            <v-col lg="9">

                <v-tabs v-model="tab" fixed-tabs>
                    <v-tab value="default" prepend-icon="mdi-account">Meus dados</v-tab>

                    <v-tab value="password" prepend-icon="mdi-key"> Senha </v-tab>
                </v-tabs>

                <v-window :model-value="tab" class="pt-10">
                    <v-window-item value="default">

                        <x-form :form="nameForm" :action="$route('app-site.user.update', 'name')" #="{ activatorProps }"
                            class="mb-5">
                            <v-text-field v-model="nameForm.name" label="Nome completo" :error-messages="errors?.name">
                                <template v-if="$vuetify.display.mobile" #append>
                                    <v-btn :="activatorProps" icon="mdi-check" color="primary" size="small"></v-btn>
                                </template>
                                <template v-else #append>
                                    <v-btn :="activatorProps" prepend-icon="mdi-check" text="Atualizar nome"
                                        color="primary"></v-btn>
                                </template>
                            </v-text-field>

                        </x-form>

                        <x-form :form="emailForm" :action="$route('verification.send')" #="{ activatorProps }">
                            <v-text-field v-model="emailForm.email" label="Seu e-mail (somente leitura)" readonly>
                                <template v-if="$vuetify.display.mobile" #append>
                                    <v-icon v-if="_user.hasVerifiedEmail" color="success"
                                        icon="mdi-email-check"></v-icon>
                                    <v-btn v-else :="activatorProps" color="error" icon="mdi-email-fast"
                                        size="small"></v-btn>
                                </template>
                                <template v-else #append>
                                    <v-chip v-if="_user.hasVerifiedEmail" color="success"
                                        text="Seu e-mail já foi verificado" prepend-icon="mdi-email-check"></v-chip>
                                    <v-btn v-else :="activatorProps" color="error" prepend-icon="mdi-email-fast"
                                        text="Verificar e-mail"></v-btn>
                                </template>
                            </v-text-field>

                        </x-form>

                        <v-divider class="mt-5 mb-10"></v-divider>

                        <x-form :form="documentForm" :action="$route('app-site.user.update', 'document')"
                            #="{ activatorProps }">

                            <v-row>
                                <v-col cols="12" lg="3">
                                    <v-select v-model="documentForm.type" label="Tipo de documento"
                                        :items="$locale.specs.documentTypes" :item-title="(x) => $t(x.name)"
                                        item-value="value" :error-messages="errors?.type"></v-select>
                                </v-col>
                                <v-col cols="12" lg="9">
                                    <x-input-mask :options="{ mask: documentMask }" v-model="documentForm.number"
                                        label="Número de documento" :error-messages="errors?.number">
                                        <template v-if="$vuetify.display.mobile" #append>
                                            <v-btn :="activatorProps" icon="mdi-check" color="primary"></v-btn>
                                        </template>
                                        <template v-else #append>
                                            <v-btn :="activatorProps" prepend-icon="mdi-check" color="primary"
                                                text="Atualizar documento"></v-btn>
                                        </template>
                                    </x-input-mask>
                                </v-col>
                            </v-row>
                        </x-form>

                        <v-divider class="mt-5 mb-10"></v-divider>

                        <x-form :form="phoneForm" :action="$route('app-site.user.update', 'phone')"
                            #="{ activatorProps }">

                            <x-input-phone v-model="phoneForm.phone" :error-messages="errors?.phone">
                                <template v-if="$vuetify.display.mobile" #append>
                                    <v-btn :="activatorProps" icon="mdi-check" color="primary"></v-btn>
                                </template>

                                <template #append>
                                    <v-btn :="activatorProps" prepend-icon="mdi-check" color="primary"
                                        text="Atualizar telefone"></v-btn>
                                </template>
                            </x-input-phone>

                        </x-form>

                    </v-window-item>

                    <v-window-item value="password">

                        <x-form :form="passwordForm" :action="$route('app-site.user.update', 'password')"
                            #="{ activatorProps }">

                            <x-input-password class="mb-3" v-model="passwordForm.current_password"
                                label="Sua senha atual:" :error-messages="errors?.current_password"></x-input-password>
                            <x-input-password class="mb-3" v-model="passwordForm.password" label="Crie uma nova senha:"
                                :error-messages="errors?.password"></x-input-password>
                            <x-input-password class="mb-3" v-model="passwordForm.password_confirmation"
                                :rules="[(v) => v === passwordForm.password || 'As senhas não coincidem']"
                                label="Confirme sua nova senha:"
                                :error-messages="errors?.password_confirmation"></x-input-password>

                            <v-btn :="activatorProps" prepend-icon="mdi-check" color="primary"
                                :block="$vuetify.display.mobile" text="Atualizar senha"></v-btn>
                        </x-form>
                    </v-window-item>

                </v-window>
            </v-col>
        </v-row>
    </v-container>
</template>
